
<div class="container body-content">



  <hr />
  <!-- BANNER
  ================================================== -->


  <div id="rev_slider_1174_1_wrapper" class="rev_slider_wrapper fullscreen-container" data-alias="inspiration-header"
       data-source="gallery" style="background-color:transparent;padding:0px;">

    <!-- START REVOLUTION SLIDER 5.4.1 fullscreen mode -->
    <div id="rev_slider_1174_1" class="rev_slider fullscreenbanner" style="display:none;" data-version="5.4.1">
      <ul>
        <!-- SLIDE  -->
        <li data-index="rs-3238" data-transition="fade" data-slotamount="default" data-hideafterloop="0"
            data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="500"
            data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3=""
            data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10=""
            data-description="">
          <!-- MAIN IMAGE -->
          <!--<img src="assets/img/slider/elements/movie_overlay 01.jpg"
          -->
          <img src="../../../../Scripts/assets/img/slider/elements/movie_overlay 01.jpg" data-bgcolor="#f9f9f9" alt=""
               data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off"
               class="rev-slidebg" data-no-retina>
          <!-- LAYERS -->
          <!-- <div class="tp-caption   tp-resizeme rs-parallaxlevel-8" id="slide-3238-layer-4" data-x="['left','left','left','left']" data-hoffset="['-264','-264','-371','-416']" data-y="['top','top','top','top']" data-voffset="['-135','-135','-180','-203']" data-width="none"
               data-height="none" data-whitespace="nowrap" data-type="image" data-basealign="slide" data-responsive_offset="on" data-frames='[{"from":"x:left;rZ:-90deg;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
               data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 5;border-width:0px;">
               <img src="assets/img/slider/elements/header_penpot.png" alt="" data-ww="['650px','650px','650px','650px']" data-hh="['500px','500px','500px','500px']" width="650" height="500" data-no-retina></div>

          <div class="tp-caption   tp-resizeme rs-parallaxlevel-9" id="slide-3238-layer-5" data-x="['right','right','right','right']" data-hoffset="['30','30','-70','-144']" data-y="['top','top','top','top']" data-voffset="['-204','-204','-233','-261']" data-width="none"
               data-height="none" data-whitespace="nowrap" data-type="image" data-basealign="slide" data-responsive_offset="on" data-frames='[{"from":"x:right;y:-500px;rZ:90deg;","speed":2500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
               data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 6;border-width:0px;">
               <img src="assets/img/slider/elements/header_flowerpot.png" alt="" data-ww="['700px','700px','700px','700px']" data-hh="['600px','600px','600px','600px']" width="700" height="600" data-no-retina></div>

          <div class="tp-caption   tp-resizeme rs-parallaxlevel-10" id="slide-3238-layer-6" data-x="['left','left','left','left']" data-hoffset="['-372','-372','-540','-638']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['-484','-484','-520','-522']"
               data-width="none" data-height="none" data-whitespace="nowrap" data-type="image" data-basealign="slide" data-responsive_offset="on" data-frames='[{"from":"x:left;rZ:45deg;","speed":2500,"to":"o:1;","delay":650,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
               data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 7;border-width:0px;">
               <img src="assets/img/slider/elements/header_papers.png" alt="" data-ww="['900px','900px','900px','900px']" data-hh="['1000px','1000px','1000px','1000px']" width="900" height="1000" data-no-retina></div>

          <div class="tp-caption   tp-resizeme rs-parallaxlevel-12" id="slide-3238-layer-9" data-x="['left','left','left','left']" data-hoffset="['134','134','-2','-57']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['67','67','50','6']" data-width="none"
               data-height="none" data-whitespace="nowrap" data-type="image" data-basealign="slide" data-responsive_offset="on" data-frames='[{"from":"y:bottom;rZ:90deg;","speed":1500,"to":"o:1;","delay":1050,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
               data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 8;border-width:0px;">
               <img src="assets/img/slider/elements/header_marker.png" alt="" data-ww="['200px','200px','200px','200px']" data-hh="['300px','300px','300px','300px']" width="200" height="300" data-no-retina></div>

          <div class="tp-caption   tp-resizeme rs-parallaxlevel-11" id="slide-3238-layer-10" data-x="['left','left','left','left']" data-hoffset="['181','181','30','-39']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['-170','-170','-190','-212']"
               data-width="none" data-height="none" data-whitespace="nowrap" data-type="image" data-basealign="slide" data-responsive_offset="on" data-frames='[{"from":"y:bottom;rZ:-90deg;","speed":1500,"to":"o:1;","delay":1250,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
               data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 9;border-width:0px;">
               <img src="assets/img/slider/elements/header_edding.png" alt="" data-ww="['300px','300px','300px','300px']" data-hh="['400px','400px','400px','400px']" width="300" height="400" data-no-retina></div>

          <div class="tp-caption   tp-resizeme rs-parallaxlevel-12" id="slide-3238-layer-11" data-x="['left','left','left','left']" data-hoffset="['393','393','201','81']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['218','218','183','191']" data-width="none"
               data-height="none" data-whitespace="nowrap" data-type="image" data-basealign="slide" data-responsive_offset="on" data-frames='[{"from":"y:bottom;rZ:-90deg;","speed":1500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
               data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 10;border-width:0px;">
               <img src="assets/img/slider/elements/header_paperclip.png" alt="" data-ww="['50px','50px','50px','50px']" data-hh="['100px','100px','100px','100px']" width="50" height="100" data-no-retina></div>

          <div class="tp-caption   tp-resizeme rs-parallaxlevel-11" id="slide-3238-layer-12" data-x="['left','left','left','left']" data-hoffset="['346','346','165','36']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['255','255','237','231']" data-width="none"
               data-height="none" data-whitespace="nowrap" data-type="image" data-basealign="slide" data-responsive_offset="on" data-frames='[{"from":"y:bottom;rZ:90deg;","speed":2000,"to":"o:1;rZ:310;","delay":550,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
               data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 11;border-width:0px;">
               <img src="assets/img/slider/elements/header_paperclip.png" alt="" data-ww="['50px','50px','50px','50px']" data-hh="['100px','100px','100px','100px']" width="50" height="100" data-no-retina></div>

          <div class="tp-caption   tp-resizeme rs-parallaxlevel-9" id="slide-3238-layer-7" data-x="['right','right','right','right']" data-hoffset="['-364','-364','-479','-553']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['-155','-155','-180','-202']"
               data-width="none" data-height="none" data-whitespace="nowrap" data-type="image" data-basealign="slide" data-responsive_offset="on" data-frames='[{"from":"x:right;rZ:-65deg;","speed":1500,"to":"o:1;","delay":700,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
               data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 12;border-width:0px;">
               <img src="assets/img/slider/elements/header_tablet.png" alt="" data-ww="['730px','730px','730px','730px']" data-hh="['520px','520px','520px','520px']" width="730" height="520" data-no-retina></div>

          <div class="tp-caption   tp-resizeme rs-parallaxlevel-11" id="slide-3238-layer-8" data-x="['right','right','right','right']" data-hoffset="['222','222','105','8']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['8','8','-17','-10']" data-width="none"
               data-height="none" data-whitespace="nowrap" data-type="image" data-basealign="slide" data-responsive_offset="on" data-frames='[{"from":"x:right;rZ:90deg;","speed":1500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
               data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 13;border-width:0px;">
               <img src="assets/img/slider/elements/header_pen.png" alt="" data-ww="['300px','300px','300px','300px']" data-hh="['500px','500px','500px','500px']" width="300" height="500" data-no-retina></div> -->

          <!-- LAYER NR. 10 -->
          <div class="tp-caption alt-font tp-resizeme rs-parallaxlevel-2" id="slide-3238-layer-1"
               data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
               data-y="['middle','middle','middle','middle']" data-voffset="['-70','-70','-70','-90']"
               data-fontsize="['80','80','80','48']" data-lineheight="['80','80','80','60']"
               data-width="['none','none','none','360']" data-height="none"
               data-whitespace="['nowrap','nowrap','nowrap','normal']" data-type="text" data-responsive_offset="on"
               data-frames='[{"from":"y:-50px;rX:-45deg;sX:2;sY:2;opacity:0;","speed":1500,"to":"o:1;","delay":510,"ease":"Power4.easeOut"},{"delay":640,"speed":600,"to":"y:30px;rX:45deg;sX:0.8;sY:0.8;opacity:0;","ease":"Power2.easeInOut"}]'
               data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
               data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
               style="z-index: 14; white-space: nowrap; font-size: 40px; line-height: 40px; font-weight: 500; color: #faf6f6;border-width:0px;">
            Be Creative
          </div>

          <!-- LAYER NR. 11 -->
          <div class="tp-caption alt-font tp-resizeme rs-parallaxlevel-2" id="slide-3238-layer-15"
               data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
               data-y="['middle','middle','middle','middle']" data-voffset="['-70','-70','-70','-90']"
               data-fontsize="['80','80','80','48']" data-lineheight="['80','80','80','60']"
               data-width="['none','none','none','360']" data-height="none"
               data-whitespace="['nowrap','nowrap','nowrap','normal']" data-type="text" data-responsive_offset="on"
               data-frames='[{"from":"y:-50px;rX:-45deg;sX:2;sY:2;opacity:0;","speed":1500,"to":"o:1;","delay":2940,"ease":"Power4.easeOut"},{"delay":660,"speed":600,"to":"y:30px;rX:45deg;sX:0.8;sY:0.8;opacity:0;","ease":"Power2.easeInOut"}]'
               data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
               data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
               style="z-index: 15; white-space: nowrap; font-size: 40px; line-height: 40px; font-weight: 500; color: #faf6f6;border-width:0px;">
            Be Data Driven</div>

          <!-- LAYER NR. 12 -->
          <div class="tp-caption alt-font tp-resizeme rs-parallaxlevel-2" id="slide-3238-layer-16"
               data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
               data-y="['middle','middle','middle','middle']" data-voffset="['-70','-70','-70','-90']"
               data-fontsize="['60','60','60','48']" data-lineheight="['60','60','60','60']"
               data-width="['none','none','none','400']" data-height="none"
               data-whitespace="['nowrap','nowrap','nowrap','normal']" data-type="text" data-responsive_offset="on"
               data-frames='[{"from":"y:-50px;rX:-45deg;sX:2;sY:2;opacity:0;","speed":1500,"to":"o:1;","delay":5390,"ease":"Power4.easeOut"},{"delay":"wait","speed":600,"to":"y:30px;rX:45deg;sX:0.8;sY:0.8;opacity:0;","ease":"Power2.easeInOut"}]'
               data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
               data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
               style="z-index: 16; white-space: nowrap; font-size: 40px; line-height: 40px; font-weight: 500; color: #faf6f6;border-width:0px;">
            <h5 style="color: rgb(250, 246, 246); font-size: 60px;">Cinema Advertisement Redefined </h5>
            <h5 style="color: rgb(250, 246, 246);font-size: 30px;font-weight: 200;">
              Derive maximum ROI for your advertisement spend
            </h5>
            <!-- Cinema Advertisement Redefined -->
          </div>

          <!-- LAYER NR. 13 --> -->
          <!-- <div class="tp-caption   tp-resizeme rs-parallaxlevel-2" id="slide-3238-layer-2" data-x="['center','center','center','center']" data-hoffset="['-8','-8','-8','-8']" data-y="['middle','middle','middle','middle']" data-voffset="['10','10','10','-10']" data-fontsize="['24','24','24','24']"
               data-lineheight="['24','24','24','36']" data-width="['none','none','none','360']" data-height="none" data-whitespace="['nowrap','nowrap','nowrap','normal']" data-type="text" data-responsive_offset="on" data-frames='[{"from":"y:50px;rX:45deg;sX:2;sY:2;opacity:0;","speed":1500,"to":"o:1;","delay":600,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
               data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 17; white-space: nowrap; font-size: 20px; line-height: 20px; font-weight: 400; color: #faf6f6;border-width:0px;">Derive maximum ROI for your advertisement spend </div> -->
          <!-- LAYER NR. 14 -->
          <div class="tp-caption butn primary rs-parallaxlevel-3 position-relative z-index-99 xs-wide"
               id="slide-3238-layer-3" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
               data-y="['middle','middle','middle','middle']" data-voffset="['92','92','92','92']" data-width="none"
               data-height="none" data-whitespace="nowrap" data-type="button"
               data-actions='[{"event":"click","action":"scrollbelow","offset":"px","delay":""}]'
               data-responsive_offset="on" data-responsive="off"
               data-frames='[{"from":"y:100px;rX:90deg;opacity:0;","speed":1500,"to":"o:1;","delay":700,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"150","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;"}]'
               data-textAlign="['left','left','left','left']"><span>CONTINUE</span></div>

          <!-- LAYER NR. 15 -->
          <div class="tp-caption rev-scroll-btn revs-dark  rs-parallaxlevel-5" id="slide-3238-layer-13"
               data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
               data-y="['bottom','bottom','bottom','bottom']" data-voffset="['50','50','50','50']" data-width="35"
               data-height="55" data-whitespace="nowrap" data-type="button"
               data-actions='[{"event":"click","action":"scrollbelow","offset":"px","delay":""}]' data-basealign="slide"
               data-responsive_offset="on" data-responsive="off"
               data-frames='[{"from":"y:-50px;opacity:0;","speed":1500,"to":"o:1;","delay":800,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
               data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]"
               data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
               style="z-index: 19; min-width: 35px; max-width: 35px; max-width: 55px; max-width: 55px; white-space: nowrap; font-weight: 400;border-color:rgba(51, 51, 51, 1.00);border-style:solid;border-width:1px;border-radius:23px 23px 23px 23px;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;cursor:pointer;">
            <span>
            </span>
          </div>
        </li>
      </ul>
      <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>
    </div>

  </div>


</div>
<!-- SERVICES
================================================== -->
<section id="ApproachClick">
  <div class="container">
    <div class="row mt-n1-9">
      <div class="col-md-6 col-lg-3 mt-1-9">
        <div class="service-block10">

          <div class="position-relative z-index-1">

            <span class="mb-4 d-block"><i class="icon-lightbulb color-h-one"></i></span>
            <h4>Data<br /> Driven</h4>
            <p class="mb-0">Complete Data based approach for Campaign planning.</p>

          </div>

        </div>
      </div>
      <div class="col-md-6 col-lg-3 mt-1-9">
        <div class="service-block10">

          <div class="position-relative z-index-1">

            <span class="mb-4 d-block"><i class="icon-tools-2 color-h-two"></i></span>
            <h4>What if Scenarios</h4>
            <p class="mb-0">Try options to reduce the CPMs.</p>
          </div>

        </div>
      </div>
      <div class="col-md-6 col-lg-3 mt-1-9">
        <div class="service-block10">

          <div class="position-relative z-index-1">

            <span class="mb-4 d-block"><i class="icon-mobile color-h-three"></i></span>
            <h4>Post Eval</h4>
            <p class="mb-0">Evaluate the effectiveness for your running campaigns.</p>
          </div>

        </div>
      </div>
      <div class="col-md-6 col-lg-3 mt-1-9">
        <div class="service-block10">

          <div class="position-relative z-index-1">

            <span class="mb-4 d-block"><i class="icon-layers color-h-four"></i></span>
            <h4>Near Live Data</h4>
            <p class="mb-0">Analysis performed on near live data.</p>

          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<!-- HIGHLIGHTS
================================================== -->
<section class="bg-img background-position-left-top background-no-repeat position-relative p-0" data-overlay-dark="0" data-background="../../../../Scripts/assets/img/content/content-bg.png">

  <!-- ABOUT US
  ================================================== -->
  <section class="position-relative bg-transparent p-0">
    <div class="container">

      <div class="row align-items-center">
        <div class="col-lg-6 service-design">

          <span>Highlights of services</span>
          <h3>Plan your highly effective Cinema Campaigns using CAMBioscope!</h3>
          <p style="font-size: 17px; text-align:justify">A proprietary AI based cinema advertisement planning tool such as CAMBioscope is designed to elicit maximum return on investment for every rupee spent. This tool intelligently proposes the screens and rates at which any campaign should be conducted to bring down Cost per Contact (CPC) and maximize Return on investment (ROI). </p>






        </div>
        <div class="col-lg-6 service-design">
          <img src="../../../../Scripts/assets/img/content/thearerContent2.png" style="width: 94%;" />
        </div>

      </div>

    </div>

  </section>

  <section class="position-relative bg-transparent p-0">
    <div class="container" style="padding-top: 30px;">

      <div class="row align-items-center">

        <div class="col-lg-6 service-design">
          <img src="../../../../Scripts/assets/img/content/thearerContent1 (1).png" style="width: 94%;" />
        </div>
        <div class="col-lg-6 service-design">

          <!-- <span>Highlights of services</span> -->
          <h3>Measure ROI for your running campaigns!</h3>
          <p style="font-size: 17px;  text-align:justify">CAMBioscope helps advertisers plan their cinema campaigns by maximizing ROI. The advertisers can also measure the ROI for ongoing campaigns or older ones. The Occupancy data arrived at by the AI/ML algorithm can help with the performance measurement by deriving a common currency of CPC (Cost per Contact).</p>






        </div>

      </div>

    </div>

  </section>

</section>

