<style>

  p {
    text-align: justify;
  }

  img {
    border: 5px solid #f1e0ac;
  }
</style>
<div class="container" style="padding-top:50px">
  <div _ngcontent-oou-c51="" class="row" style="padding-top: 50px;padding-bottom: 71px;">

    <p style="    text-align: left;
    font-size: 23px;
    padding-top: -11px;
    font-family: fangsong;
    color: #532ABA;
    font-weight: 500;padding-left: 23px;">Contact Us</p>


    <section>
      <div class="container" style="    margin-top: -124px;">
        <div class="row">

          <div class="col-lg-6 mb-1-9 mb-lg-0">

            <div class="pe-lg-1-9">
              <div class="row">
                <div class="section-heading left half">
                  <h3 class="font-weight-600">Get advice any time</h3>
                </div>

                <div class="d-flex border-bottom pb-4 mb-4">
                  <div class="flex-shrink-0">
                    <i class="fas fa-phone display-20 text-primary"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h4 class="mb-1 text-uppercase display-30">Phone Number</h4>
                    <p class="mb-0">(+91) 22 41618300</p>
                  </div>
                </div>
                <div class="d-flex border-bottom pb-4 mb-4">
                  <div class="flex-shrink-0">
                    <i class="fas fa-map-marker-alt display-20 text-primary"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h4 class="mb-1 text-uppercase display-30">Locations</h4>
                    <p class="mb-0">4th Floor, Sambhav I.T Park (TJSB House), Behind APLAB, Wagle Estate,Thane (West), Mumbai – 400 604</p>
                  </div>
                </div>
                <div class="d-flex mb-1-9">
                  <div class="flex-shrink-0">
                    <i class="far fa-envelope display-20 text-primary"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h4 class="mb-1 text-uppercase display-30">Email</h4>
                    <p class="mb-0">cambioscope@dusaneinfotech.com</p>
                  </div>
                </div>



              </div>
            </div>

          </div>

          <div class="col-lg-6">

            <img src="../../../../Scripts/assets/img/content/DusaneContactUs.png" />
          </div>

        </div>
      </div>
    </section>

  </div>
</div>
