<style>

  p, li {
    text-align: justify;
  }
</style>
<!-- <p style="text-align: center;

font-size: 46px;
padding-top: 60px;
font-family: fangsong;
color: #532ABA;">Coming Soon...</p> -->

<div class="container" style="padding-top:50px">
  <div _ngcontent-oou-c51="" class="row" style="padding-top: 43px;padding-bottom: 71px;">
    <p style="    text-align: left;
            font-size: 23px;
            padding-top: -11px;
            font-family: fangsong;
            color: #532ABA;
            font-weight: 500;">About Us</p>
    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <p>
        Dusane Infotech, an ISO 9001:2015 certified technology consulting and development company with focus on
        middleware engineering services in the gaming & media space. Dusane Infotech is headquartered in Mumbai,
        India.
      </p>
      <p></p>
      <p>
        We offer technology platforms for the gaming solutions as well as media and transactions solutions which
        helps our clients to:
      </p>
      <ul>
        <li>Reduce their time-to-market</li>

        <li>Reduce costs and drive business value</li>
      </ul>
      <p></p>
      <p>Our Product Offerings:</p>
      <p></p>
      <p>
        Combining a fresh perspective to high volume business transactions with our experience in advanced
        technologies, we deliver consistent results for projects rarely achieved with conventional methods. Our
        business transactions framework handles high volume centralized transaction over multi-location points
        of
        transaction.
      </p>

    </div>
  </div>

</div>

