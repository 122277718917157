import { Component, OnInit } from '@angular/core';
import { Global } from '../global/global'

@Component({
  selector: 'app-cinemastats',
  templateUrl: './cinemastats.component.html',
  styleUrls: ['./cinemastats.component.css']
})
export class CinemastatsComponent implements OnInit {
  CineStartURL: string;
  Login: string;
  constructor(public global: Global) { }

  ngOnInit(): void {
    debugger
    this.global.IsLoggedIn = sessionStorage.getItem('IsLoggedIn');
   
    console.log(this.global.IsLoggedIn)
    if (this.global.IsLoggedIn == null) {
      this.global.IsLoggedIn = 'false';
    }
    this.Login = this.global.IsLoggedIn;
    this.global.IsOnMenu = false
    this.global.IsOnApproachMenu = false
    this.global.IsOnPlannerMenu = false
    this.global.IsOnPostEvalMenu = false
    this.global.IsOnContactUsMenu = false
    this.global.IsOnStartedMenu = false
    this.global.IsCinemaStats = true

    if (this.global.IsLoggedIn == 'true') {
      this.CineStartURL = this.global.EmbededURL + "/CineStartReport";
      this.CineStartURL = this.CineStartURL.trim();
    }
    
    
  }

  GoToLoginFromCineStart() {
    debugger
    localStorage.setItem('IsPlannerLogin', "false");
    localStorage.setItem('IsPostEvalLogin', "false");
    localStorage.setItem('IsCineStatsLogin', "true");
    window.location.href = "./Login"
  }

}
