import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../global/global'
import { RegistrationPageService } from './RegistrationPageService'
import { FormGroup, FormControl, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import {  Router } from '@angular/router';
import { retry } from 'rxjs/operators';




@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  //MenuH
  RegistrationForm!: FormGroup
 
  submitted = false

  isShowLoader: boolean
  firstname: string
  lastname: string
  email: string
  phone: string
  companyname: string
  password: string
  cnfpassword: string
  IsMessage: boolean = false;
  Profile_view_mode: string;
  myInnerHeight: any = 880;
  disabled: boolean = false;

  RegistrationAraay: Array<{ Rfirstname: string, Rlastname: string, Remail: string, Rphone: string, Rcompanyname: string,Rpassword:string }> = [];

  constructor(private http: HttpClient,
    public global: Global, private registrationpageService: RegistrationPageService, private fb: FormBuilder, private router: Router) {
    debugger;
        
    
  }


  FormValidation() {
    //this.RegistrationForm = this.fb.group({

    //  firstname: ['', Validators.required],
    //  // email: ['', Validators.required, , Validators.email],
    //  email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    //  phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    //  lastname: [],
    //  companyname: [],
    //  password: ['', Validators.required],
    //  cnfpassword: ['', Validators.required],
   

    //});
    var EditProfileDetails = JSON.parse(localStorage.getItem('EditProfileDetails'));
    if (EditProfileDetails!=null) {
      this.RegistrationForm = this.fb.group({

        firstname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        lastname: [],
        companyname: [],
        
      })
    }
    else {
      this.RegistrationForm = this.fb.group({

        firstname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        lastname: [],
        companyname: [],
        password: ['', Validators.required],
        cnfpassword: ['', Validators.required],
      }, {
          validator: ConfirmedValidator('password', 'cnfpassword')
        })
    }

    
  }
  



  InitializeRegistrationDetailsArray() {
    debugger
    this.RegistrationAraay = [];
    this.RegistrationAraay.push({ Rfirstname: this.firstname, Rlastname: this.lastname, Remail: this.email, Rphone: this.phone, Rcompanyname: this.companyname,Rpassword:this.password });


  }
  GoToLoginFromRegistration() {
    debugger
    window.location.href = "./Login"
  }


  getRegistrationData() {
    debugger

    if (this.password.trim() == this.cnfpassword.trim()) {
      this.InitializeRegistrationDetailsArray();



      this.registrationpageService.getRegistrationAPIData(this.RegistrationAraay[0]).subscribe(
        data => {
          debugger
          if (data == 'success') {
           
            alert("You have been registered successfully..!")
            this.IsMessage = true;
            window.location.href = "./Login"
          }
          else {
            alert(data)            
          }

        });
      }
      else
      {
        alert("Password and Confirm Password should be same.");
      }
    




    }
    //UserMgt
    ngOnInit(): void {
      debugger
    this.FormValidation();
      this.global.IsLoggedIn = sessionStorage.getItem('IsLoggedIn');

      // if(this.global.IsLoggedIn == null)
      // {
      //   this.global.IsLoggedIn = 'false';
      //   window.location.href = "./Login"
      // }
      // if(this.global.IsLoggedIn = 'false')
      // {
      //   window.location.href = "./Login"
      // }

      this.global.IsLoggedIn = sessionStorage.getItem('IsLoggedIn');
      console.log(this.global.IsLoggedIn)
    if(this.global.IsLoggedIn == null) {
      this.global.IsLoggedIn = 'false';
    }

      var EditProfileDetails = JSON.parse(localStorage.getItem('EditProfileDetails'));
      if (EditProfileDetails.length > 0) {
        this.firstname = EditProfileDetails[0].FirstName;
        this.lastname = EditProfileDetails[0].LastName;
        this.email = EditProfileDetails[0].EmailID;
        this.phone = EditProfileDetails[0].Phone;
        this.companyname = EditProfileDetails[0].CompanyName;
        this.Profile_view_mode = 'E';
        this.myInnerHeight = 640;
        this.disabled = true;
        this.RegistrationForm.controls['email'].disable();
      }
      else if (EditProfileDetails != null) {
        this.firstname = EditProfileDetails.FirstName;
        this.lastname = EditProfileDetails.LastName;
        this.email = EditProfileDetails.EmailID;
        this.phone = EditProfileDetails.Phone;
        this.companyname = EditProfileDetails.CompanyName;
        this.Profile_view_mode = 'E';
        this.myInnerHeight = 640;
        this.disabled = true;
        this.RegistrationForm.controls['email'].disable();
      }
      else {
        this.Profile_view_mode = "";
        this.disabled = false;
      }
      
              

  }

  //UserMgt
  LogoutApp() {
    this.global.IsLoggedIn = 'false';
    sessionStorage.setItem('IsLoggedIn', String(this.global.IsLoggedIn));
    window.location.href = "./Login"


  }

  onSubmit() {
    debugger
    this.submitted = true
    if (this.Profile_view_mode == 'E') {

      if (this.RegistrationForm.invalid) {
        return;
      }
      else {

        this.UpdateProfile();
        return;
      }            
    }
    
    if (this.RegistrationForm.invalid) {
      return;
    }
    else
    {
     
        this.getRegistrationData();
   
      
    }



  }
  GoToPostEval() {
    window.location.href = "./PostEval"
  }

  GotoPlanner() {
    window.location.href = "./Planner"
  }

  //GetEditProfileDetails() {
  //  debugger;
  //  this.global.IsUserId = sessionStorage.getItem('IsUserId');       
  //  this.registrationpageService.GetEditProfileDetails(this.global.IsUserId).subscribe(
  //    data => {
  //      debugger;
        
  //      if (data.Table) {

  //        //this.EditRoDetails.splice(index, 1);
  //        this.firstname = data.Table[0].FirstName;
  //        this.lastname = data.Table[0].LastName;
  //        this.email = data.Table[0].EmailID;
  //        this.phone = data.Table[0].Phone;
  //        this.companyname = data.Table[0].CompanyName;

  //      }
  //      else {
  //        alert("Some error occurred")  
  //      }

  //    });
  //}

  UpdateProfile() {
    debugger;
    this.submitted = true
    const headers = { 'content-type': 'application/json' }
    
      const EditProfile: any = {
        "UserId": sessionStorage.getItem('IsUserId'),
        "FirstName": this.firstname,
        "LastName": this.lastname,
        "Phone": this.phone,       
        "CompanyName": this.companyname,
        "EmailID": this.email
        
      };
      const body = JSON.stringify(EditProfile)
      var url = this.global.baseUrl + 'api/Registration/UserRegistration/'
      ///var url = this.global.baseUrl + 'api/Dashboard/GetPostEvalDetails/?StartDate=' + '01/01/2023' + '&EndData=' + '10/01/2023' + '&Duration=' + 30
      this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
        debugger        
        alert("Details updated successfully..!")
        localStorage.removeItem('EditProfileDetails');
        localStorage.setItem("EditProfileDetails", JSON.stringify(EditProfile))
        window.location.href = "./"

      }, (error) => {
        this.isShowLoader = false;
        alert("Error Occured")

      });
    
  }

  DisableAccount() {
    this.IsMessage = true;
  }

  Delete() {
    debugger
    this.registrationpageService.GetDisableProfileDetails(sessionStorage.getItem('IsUserId')).subscribe(
      data => {
        debugger;

        if (data.Table[0].Msg) {
          this.IsMessage = false;
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = "./"

        }
        else {
          alert("Some error occurred")
          this.IsMessage = false;
        }

      });
  }

  DeleteNo() {
    debugger;
    this.IsMessage = false;
  }
  Back() {
    this.IsMessage = false;
    window.location.href = "./"
  }



}
export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
