import { Component, OnInit } from '@angular/core';
import { Global } from '../global/global'
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { PostEvlDataModel } from '../Model/PostEvalData'
import { DatePipe } from '@angular/common'
import { fail } from 'assert';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { transcode } from 'buffer';




@Component({
  selector: 'app-posteval',
  templateUrl: './posteval.component.html',
  styleUrls: ['./posteval.component.css']
})
export class PostevalComponent implements OnInit {
  PostEvlArrayList: any = [];
  regionList: any = [];
  cinemaChainsList: any = [];
  StateList: any = [];

  selectedregionListItems: any = [];
  selectedcinemaChainsListItems: any = [];
  selectedStateListItems: any = [];


  dropdownregionSettings: IDropdownSettings
  dropdowncinemachainSettings: IDropdownSettings
  dropdownStateSettings: IDropdownSettings

  //Form
  CampaignStartDate: any
  CampaignEndDate: any
  Region: any
  CinemaChains: any
  CampaignBudget: any
  State: any
  totalspot: any
  Duration: any;
  ClientName: any
  CampaignTitle: any
 

  IsChainAdd: boolean = false

  // Spot and Budget
  CampSpots: any
  CampBudget: any

  SpotBudgtData: any = []
  FinalArray: any = []

  region_zoneNew: any = []
  arrayNew: PostEvlDataModel


  region_zone: any = []
  states: any = []
  chains: any = []

  FinalPostEvalData: any = []

  IsSubmitPostEval: boolean = false

  StateData: any = []

  StartDate: any
  EndDate: any
  todayDate = new Date();
  todayEndDate = new Date();


  FromDate = new Date();
  isShowLoader: boolean;
  IsCreate: boolean;
  IsCampainPresent: boolean;
  ViewPostEvalCamapinList: any = [];
  isSavePrefernceChecked: boolean;
  IsView: boolean;
  isRegionCheck: boolean;


  // formValidation
  registerPostForm: FormGroup;
  submitted = false;
  msg: string
  SavePref: boolean = true
  FinalSelectedArray: any = []

  GolblePostEvalId: any;
  IsMessage: boolean = false;

  DeleteArray: any = []
  cinemaChainFormArrayNew: any = []

  

  //msgs: Message[] = [];
  //successmsgs: Message[] = [];





  constructor(public global: Global, private http: HttpClient, public datepipe: DatePipe, private router: Router, private formBuilder: FormBuilder) {
    this.isShowLoader = false;
    this.IsCreate = false;
    this.IsView = false;
    this.IsCampainPresent = false;
    this.isRegionCheck = false;
    this.CampaignEndDate = new Date();

  }

  //UserMgt
  ngOnInit(): void {
    debugger


    this.registerPostForm = this.formBuilder.group({
      CampaignTitle: ['', [Validators.required]],
      ClientName: ['', [Validators.required]],
      Duration: ['', [Validators.required, Validators.pattern("^([1-9][0-9]*)|([0]+)$")]],
      CampaignStartDate: ['', [Validators.required]],
      CampaignEndDate: ['', [Validators.required]],
      //regionCheckbox: ['', [Validators.required]],
      selectStateList: ['', [Validators.required]],
      ChainList: ['', [Validators.required]],
      'cinemachain': new FormArray([])

    });

    this.dropdownregionSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'regionName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownStateSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'State',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdowncinemachainSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'chains',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    
    // this.CampaignStartDate = this.datepipe.transform(this.CampaignStartDate,'dd-MMM-yyyy')

   
    

    this.global.IsLoggedIn = sessionStorage.getItem('IsLoggedIn');
    this.global.IsUserId = sessionStorage.getItem('IsUserId');    
    console.log(this.global.IsLoggedIn)
    if (this.global.IsLoggedIn == null) {
      this.global.IsLoggedIn = 'false';
    }
    this.global.IsOnMenu = false
    this.global.IsOnApproachMenu = false
    this.global.IsOnPlannerMenu = false
    this.global.IsOnPostEvalMenu = true
    this.global.IsOnContactUsMenu = false
    this.global.IsOnStartedMenu = false
    this.global.IsCinemaStats = false

    this.SaveSpotsBudgetByCinemaChain();
    this.GetPostEvalCampainList();
    //this.GoToPostEvalForm();
   
  }

  get cinemaChainFormArray(): FormArray {
    return this.registerPostForm.get('cinemachain') as FormArray
  }

  //GetStartDate(data) {
  //  debugger
 
  //    this.todayDate = new Date();
 
  //}

  //GetEndDate(data) {
  //  debugger
  
  //    // this.maxDate.setDate(this.maxDate.getDate() + 7);
  //    this.todayDate = new Date(this.CampaignEndDate);
 
  //}


  GetPostEvalDataList() {
    debugger
    //this.ViewPostEvalCamapinList = [];
    this.cinemaChainsList = [];
    this.selectedcinemaChainsListItems = [];    
    this.regionList = [];
    this.FinalArray = [];
    this.StateList = [];
    this.selectedStateListItems = [];
    this.cinemaChainFormArrayNew = [];
   
    //let frmArray = this.registerPostForm.get('cinemachain') as FormArray;
    //frmArray.clear();
    //const index2 = this.cinemaChainFormArray.value.findIndex(element => element.id == item.id);
   // if (index2 > -1) {
    //for (let i = 0; i < this.cinemaChainFormArray.length; i++) {
    //  this.cinemaChainFormArray.removeAt(i)
    //}
      

    //}


    const headers = { 'content-type': 'application/json' }


    const PostEvlData: any = {
      region_zone: [],
      states: [],
      chains: []
    };
    const body = JSON.stringify(PostEvlData)


    var url = this.global.baseUrl + 'api/Dashboard/GetDashboard/'
    this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      debugger
      this.regionList = [];
      this.PostEvlArrayList = response;
      // this.StateList = this.PostEvlArrayList.states;

      this.regionList = this.PostEvlArrayList.region_zone;
 


    }, (error) => {

    });
    // http://test.cambioscope.movie/api/api/ReportFilter/GetReportFilter
  }


  // OnSelect Events of Cinema Chain

  onItemSelect(item: any) {
    debugger
    if (this.selectedcinemaChainsListItems.length > 0) {
      this.IsChainAdd = true
    }
    else {
      this.IsChainAdd = false
    }


    this.FinalArray.push(item)
    this.cinemaChainFormArrayNew = []
    this.cinemaChainFormArrayNew.push(item)

    for (let i = 0; i < this.cinemaChainFormArrayNew.length; i++) {

      this.cinemaChainFormArray.push(this.formBuilder.group({
        'id': item.id,
        'chains': item.chains,
        'spots': this.cinemaChainFormArrayNew[i] && this.cinemaChainFormArrayNew[i].spots ? this.cinemaChainFormArrayNew[i].spots : '',
        'Budget': this.cinemaChainFormArrayNew[i] && this.cinemaChainFormArrayNew[i].Budget ? this.cinemaChainFormArrayNew[i].Budget : ''

      }))
    }
  }



  onSelectAll(items: any) {
    debugger
    this.IsChainAdd = true

    for (let i = 0; i < items.length; i++) {
      for (let j = 0; j < this.FinalArray.length; j++) {
        if (items[i].id == this.FinalArray[j].id) {

          items.splice(items.findIndex(element => element.id == items[i].id), 1);

        }

      }

    }


    for (let i = 0; i < items.length; i++) {
      this.FinalArray.push(items[i])
    }

    this.FinalArray = this.FinalArray

    this.cinemaChainFormArrayNew = []
    this.cinemaChainFormArrayNew.push(items)
    this.cinemaChainFormArrayNew = this.cinemaChainFormArrayNew[0];

    for (let i = 0; i < this.cinemaChainFormArrayNew.length; i++) {

      this.cinemaChainFormArray.push(this.formBuilder.group({
        'id': this.cinemaChainFormArrayNew[i].id,
        'chains': this.cinemaChainFormArrayNew[i].chains,
        'spots': this.cinemaChainFormArrayNew[i] && this.cinemaChainFormArrayNew[i].spots ? this.cinemaChainFormArrayNew[i].spots : '',
        'Budget': this.cinemaChainFormArrayNew[i] && this.cinemaChainFormArrayNew[i].Budget ? this.cinemaChainFormArrayNew[i].Budget : ''

      }))
    }

    console.log(items);
    // }
    // else {
    //   this.FinalArray.push(items)
    //   this.FinalArray = this.FinalArray[0];
    // }
  }


  onUnSelectAll() {
    debugger

    this.IsChainAdd = false
    this.FinalArray = []
    let frmArray = this.registerPostForm.get('cinemachain') as FormArray;
    frmArray.clear();

  }




  onItemDeSelect(item: any) {
    debugger
    if (this.selectedcinemaChainsListItems.length > 0) {
      this.IsChainAdd = true
    }
    else {
      this.IsChainAdd = false
    }

    const index = this.FinalArray.findIndex(element => element.id == item.id);
    if (index > -1) {
      this.FinalArray.splice(index, 1);
    }

    const index1 = this.cinemaChainFormArray.value.findIndex(element => element.id == item.id);
    if (index > -1) {
      this.cinemaChainFormArray.removeAt(this.cinemaChainFormArray.value.findIndex(y => y.id === item.id))
      // this.cinemaChainFormArray.value.splice(index, 1);
    }



  }


  // OnSelect Events of State
  onItemStateSelect(item: any) {
    debugger
    
    const headers = { 'content-type': 'application/json' }
    this.StateData.push(item);


    const PostEvlData: any = {
      region_zone: [],
      states: this.StateData,
      chains: []
    };
    const body = JSON.stringify(PostEvlData)


    var url = this.global.baseUrl + 'api/Dashboard/GetDashboard/'
    this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      debugger
      this.cinemaChainsList = [];
      this.PostEvlArrayList = response;
      this.cinemaChainsList = this.PostEvlArrayList.chains;




    }, (error) => {

    });
  }

  onItemStateDeSelect(item: any) {
    debugger
    const headers = { 'content-type': 'application/json' }

    const index = this.StateData.findIndex(element => element.id == item.id);
    if (index > -1) {
      this.StateData.splice(index, 1);
    }

    const PostEvlData: any = {
      region_zone: [],
      states: this.StateData,
      chains: []
    };
    const body = JSON.stringify(PostEvlData)




    var url = this.global.baseUrl + 'api/Dashboard/GetDashboard/'
    this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      debugger
      this.cinemaChainsList = [];
      this.PostEvlArrayList = response;
      this.cinemaChainsList = this.PostEvlArrayList.chains;




    }, (error) => {

    });



  }

  onStateSelectAll(items: any) {
    debugger
    this.isShowLoader = true;
    this.StateData=items;
    const headers = { 'content-type': 'application/json' }


    const PostEvlData: any = {
      region_zone: [],
      states: items,
      chains: []
    };
    const body = JSON.stringify(PostEvlData)




    var url = this.global.baseUrl + 'api/Dashboard/GetDashboard/'
    this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      debugger
      this.isShowLoader = false;
      this.cinemaChainsList = [];
      this.PostEvlArrayList = response;
      this.cinemaChainsList = this.PostEvlArrayList.chains;




    }, (error) => {
        this.isShowLoader = false;
        alert("Error Occured")

    });


  }

  onUnStateSelectAll() {
    debugger
    this.StateData = []
    this.cinemaChainsList = []
    this.FinalArray = []
    this.selectedcinemaChainsListItems = []

  }

  GoToLoginFromPlanner() {
    debugger
    localStorage.setItem('IsPlannerLogin', "false");
    localStorage.setItem('IsPostEvalLogin', "true");
    window.location.href = "./Login"
  }


  GotoPlanner() {
    window.location.href = "./Planner"
  }

  //UserMgt
  LogoutApp() {
    this.global.IsLoggedIn = 'false';
    sessionStorage.setItem('IsLoggedIn', String(this.global.IsLoggedIn));
    window.location.href = "./Login"


  }

  AddChainData() {
    this.IsChainAdd = true
  }

  SaveChainData() {
    debugger
    this.IsChainAdd = false

  }

  // BudgetSpot Save methd
  SaveSpotsBudgetByCinemaChain() {
    debugger


  }

  ShowPostEvlData(Create) {
    debugger
    this.FinalArray = [];
    this.FinalArray = this.registerPostForm.value.cinemachain;
    for (let i = 0; i < this.FinalArray.length; i++) {
      if (this.FinalArray[i].spots == '' || this.FinalArray[i].spots == undefined) {
        alert("Please enter the spots and budget")
        return
      }
      else if (this.FinalArray[i].Budget == '' || this.FinalArray[i].Budget == undefined) {
        alert("Please enter the spots and budget")
        return
      }




    }
    //if (Create=='C') {
      for (let i = 0; i < this.regionList.length; i++) {
        if (this.regionList[i].Status == true) {
          var flag = true;
          this.isRegionCheck = false;
          break
        }
        else { var flag = false; }
        this.isRegionCheck = true;
      }
    //}
    

    if (flag == false) {
      alert("Please check the region")
      return
    }


    this.isShowLoader = true;
    
    const headers = { 'content-type': 'application/json' }
    const PostEvlData: any = {
      "region_zone": this.region_zoneNew,
      "states": this.StateData,
      "chains": [],
      "CinemaChain": this.FinalArray
    };
     this.StartDate = this.datepipe.transform(this.CampaignStartDate,'dd/MMM/yyyy');
     this.EndDate = this.datepipe.transform(this.CampaignEndDate,'dd/MMM/yyyy'); 
     

    //  this.EndDate = moment(this.CampaignEndDate).format('dd/MM/yyyy');
    const body = JSON.stringify(PostEvlData)
    var url = this.global.baseUrl + 'api/Dashboard/GetPostEvalDetails/?StartDate=' + this.StartDate + '&EndData=' + this.EndDate   + '&Duration=' + this.Duration
    ///var url = this.global.baseUrl + 'api/Dashboard/GetPostEvalDetails/?StartDate=' + '01/01/2023' + '&EndData=' + '10/01/2023' + '&Duration=' + 30
    this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      debugger            
      this.FinalPostEvalData = response.Table;
      if (this.FinalPostEvalData.length == 0) {
        alert("Data not found..!")
      }
      else {
        this.IsSubmitPostEval = true
        if (this.SavePref == true && Create == 'C') {
          this.SavePostEvalDetails()
        }
      }
      this.isShowLoader = false;

    }, (error) => {
        this.isShowLoader = false;
        alert("Error Occured")

      });
    //if (this.FinalPostEvalData.length > 0) {
      //this.IsSubmitPostEval = true
      //if (this.SavePref == true && Create == 'C') {
      //  this.SavePostEvalDetails()
      //}
    //}
    



  }

  ResetChains(item) {
    debugger

    // this.cinemaChainsList = []



    const index1 = this.FinalArray.findIndex(element => element.id == item.id);
    if (index1 > -1) {
      this.FinalArray.splice(index1, 1);
    }

    const index2 = this.cinemaChainFormArray.value.findIndex(element => element.id == item.id);
    if (index2 > -1) {
      this.cinemaChainFormArray.removeAt(this.cinemaChainFormArray.value.findIndex(y => y.id === item.id))

    }
    this.selectedcinemaChainsListItems.splice(this.selectedcinemaChainsListItems.findIndex(element => element.id == item.id), 1);

    let SelectedCinemaChainListCopyArray = this.selectedcinemaChainsListItems.map(x => Object.assign({}, x));
    let FinalArrayCopyArray = this.FinalArray.map(x => Object.assign({}, x));

    if (item.spots!=undefined) {
      delete item.spots
    }

    if (item.Budget != undefined) {
      delete item.Budget
    }


    SelectedCinemaChainListCopyArray.push(item)

    let FinalArrayCopyDuplicateArray: any = []
    FinalArrayCopyDuplicateArray = [{ id: item.id, chains: item.chains, spots: "", Budget: "" }]

    for (let i = 0; i < FinalArrayCopyDuplicateArray.length; i++) {
      FinalArrayCopyArray.push(FinalArrayCopyDuplicateArray[i])
    }

    this.selectedcinemaChainsListItems = []
    this.FinalArray = []


    this.selectedcinemaChainsListItems = SelectedCinemaChainListCopyArray;
    this.FinalArray = FinalArrayCopyArray;




    // this.selectedcinemaChainsListItems.push([{ id: "4", chains: "QUBE" }]);
    // this.FinalArray.push([{ id: "4", chains: "QUBE", spots: "15", Budget: "45" }]);

    this.cinemaChainFormArrayNew = []
    this.cinemaChainFormArrayNew.push(item)

    for (let i = 0; i < this.cinemaChainFormArrayNew.length; i++) {

      this.cinemaChainFormArray.push(this.formBuilder.group({
        'id': this.cinemaChainFormArrayNew[i].id,
        'chains': this.cinemaChainFormArrayNew[i].chains,
        'spots': this.cinemaChainFormArrayNew[i] && this.cinemaChainFormArrayNew[i].spots ? this.cinemaChainFormArrayNew[i].spots : '',
        'Budget': this.cinemaChainFormArrayNew[i] && this.cinemaChainFormArrayNew[i].Budget ? this.cinemaChainFormArrayNew[i].Budget : ''

      }))
    }





  }
  

  checkValue(ev: any, zone: any) {
    debugger
    //console.log(event);

    //this.region_zoneNew = [];

    //if (event.Zone == 'HSM' && event.Status == true) {
    //  this.region_zoneNew = [{
    //    "Zone": "HSM",
    //    "Status": true
    //  },
    //  {
    //    "Zone": "South",
    //    "Status": false
    //  }]
    //}

    //else if (event.Zone == 'HSM' && event.Status == false) {
    //  this.region_zoneNew = [{
    //    "Zone": "HSM",
    //    "Status": false
    //  },
    //  {
    //    "Zone": "South",
    //    "Status": false
    //  }]
    //}

    //else if (event.Zone == 'South' && event.Status == true) {
    //  this.region_zoneNew = [{
    //    "Zone": "HSM",
    //    "Status": false
    //  },
    //  {
    //    "Zone": "South",
    //    "Status": true
    //  }]
    //}

    //else if (event.Zone == 'South' && event.Status == false) {
    //  this.region_zoneNew = [{
    //    "Zone": "HSM",
    //    "Status": false
    //  },
    //  {
    //    "Zone": "South",
    //    "Status": false
    //  }]
    //}




    //this.regionList = []
    //this.regionList = this.region_zoneNew
    
    if (ev.target.checked) {
      // Pushing the object into array
      //this.test_region_zone.push(obj);
      let removeIndex = this.regionList.findIndex(itm => itm.Zone === zone.Zone);
      this.regionList[removeIndex].Status = true;
      this.isRegionCheck = false;

    } else {
      let removeIndex = this.regionList.findIndex(itm => itm.Zone === zone.Zone);

      if (removeIndex !== -1)
        this.regionList[removeIndex].Status = false;
    }

    for (let i = 0; i < this.regionList.length; i++) {
      if (this.regionList[i].Status == true) {
        var flag = true;
        this.isRegionCheck = false;
        break
      }
      else { var flag = false; }
      this.isRegionCheck = true;
    }

   
    this.region_zoneNew = this.regionList

    const headers = { 'content-type': 'application/json' }

    const PostEvlData: any = {
      
      region_zone: this.regionList,
      states: [],
      chains: [],
    };
    const PostEvlDataState = JSON.stringify(PostEvlData);
    var url = this.global.baseUrl + 'api/Dashboard/GetDashboard/'
    this.http.post<any>(url, PostEvlDataState, { 'headers': headers }).subscribe((response) => {
      debugger
      this.PostEvlArrayList = response;
      this.StateList = this.PostEvlArrayList.states;
      this.cinemaChainsList = this.PostEvlArrayList.chains;




    }, (error) => {

    });

  }


  onSubmit() {
    debugger
    this.submitted = true;

    for (let i = 0; i < this.regionList.length; i++) {
      if (this.regionList[i].Status == true) {
        var flag = true;
        this.isRegionCheck = false;
        break
      }
      else { var flag = false; }
      this.isRegionCheck = true;
    }

    if (this.registerPostForm.invalid) {
      return;
    }
    else {
      this.ShowPostEvlData('C');
    }
  }

  SavePrefrence(event) {
    debugger
    this.SavePref = !this.SavePref
  }

  allowNumericDigitsOnlyOnKeyUp(event) {
    debugger

    // Only Integer Numbers

    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }

    //const pattern = /[0-9\+\-\ ]/;
    //let inputChar = String.fromCharCode(event.key);

    //if (!pattern.test(inputChar)) {
    //  // invalid character, prevent input
    //  event.preventDefault();
    //}

  }



  GoToPostEvalForm() {
    debugger;
    this.submitted = false;
    this.IsCreate = true;        

    this.GetPostEvalDataList();

  }


  BackPostEvlData() {
    //this._location.back();
    debugger;
    this.IsCreate = false;
    this.IsView = false;
    this.Clear();
    //this.registerPostForm.clearValidators();
    //this.registerPostForm.reset();
    this.GetPostEvalCampainList();
  }

  GetPostEvalCampainList() {
    debugger;
    var user_id = this.global.IsUserId;
    var url = this.global.baseUrl + 'api/PostEvalDetails/ShowPostEvalCamapine/?User_id=' + user_id;
    this.http.get<any>(url).subscribe(data => {
      if (data) {
        this.IsCampainPresent = true;
        this.ViewPostEvalCamapinList=data.Table
      }
      else {
        this.IsCampainPresent = false;
      }
    }) 
  }

  btnView(PostEvalId) {
    debugger;// 
    this.IsCreate = true;    
    var url = this.global.baseUrl + 'api/PostEvalDetails/ViewPostEvalDetails/?PostEvalID=' + PostEvalId;
    this.http.get<any>(url).subscribe(data => {
      if (data) {
        this.ViewPostEvalCamapinList = [];
        this.cinemaChainsList = [];
        this.FinalSelectedArray = [];
        this.regionList = [];
        this.selectedcinemaChainsListItems = [];
        this.FinalArray = [];
        this.StateList = [];
        this.selectedStateListItems = [];
        this.ViewPostEvalCamapinList = data
 
    
    
        //this.cinemaChainsList = [{ id: "1", chains: "PVR" }, { id: "2", chains: "UFO" }, { id: "3", chains: "QUBE" }, { id: "4", chains: "IONX" }];
        //this.FinalArray = [{ id: "1", chains: "PVR", spots: "24", Budget: "12" }, { id: "2", chains: "UFO", spots: "25", Budget: "12" }]
        //this.selectedcinemaChainsListItems = [{ id: "1", chains: "PVR", spots: "24", Budget: "12" }, { id: "2", chains: "UFO", spots: "25", Budget: "12" }]

        this.cinemaChainsList = this.ViewPostEvalCamapinList.chainsAlls
        this.FinalArray = this.ViewPostEvalCamapinList.CinemaChain
          this.selectedcinemaChainsListItems = this.ViewPostEvalCamapinList.CinemaChain
        this.clearFormArray(this.cinemaChainFormArray);

         this.cinemaChainFormArrayNew = []
        //this.cinemaChainFormArrayNew = [{ id: "1", chains: "PVR", spots: "24", Budget: "12" }, { id: "2", chains: "UFO", spots: "25", Budget: "12" }]
        this.cinemaChainFormArrayNew = this.ViewPostEvalCamapinList.CinemaChain

         for (let i = 0; i < this.cinemaChainFormArrayNew.length; i++) {

           this.cinemaChainFormArray.push(this.formBuilder.group({
             'id': this.cinemaChainFormArrayNew[i].id,
             'chains': this.cinemaChainFormArrayNew[i].chains,
             'spots': this.cinemaChainFormArrayNew[i] && this.cinemaChainFormArrayNew[i].spots ? this.cinemaChainFormArrayNew[i].spots : '',
             'Budget': this.cinemaChainFormArrayNew[i] && this.cinemaChainFormArrayNew[i].Budget ? this.cinemaChainFormArrayNew[i].Budget : ''

           }))
         }


        this.CampaignTitle = this.ViewPostEvalCamapinList.CampaignTitle,
          this.ClientName = this.ViewPostEvalCamapinList.ClientName,
          this.Duration = this.ViewPostEvalCamapinList.Duration,
          this.CampaignStartDate = new Date(this.ViewPostEvalCamapinList.StartDate),//this.datepipe.transform(this.ViewPostEvalCamapinList.StartDate,'MM/dd/yyyy'),
          this.CampaignEndDate = new Date(this.ViewPostEvalCamapinList.EndDate),
          this.regionList = this.ViewPostEvalCamapinList.region_zone
          
           for(let i = 0; i < this.ViewPostEvalCamapinList.chainsAlls.length; i++) {
            if (this.ViewPostEvalCamapinList.chainsAlls[i].Budget > 0) {
                delete this.ViewPostEvalCamapinList.chainsAlls[i].id

            }
           
           }
           
          //this.cinemaChainsList = this.ViewPostEvalCamapinList.chainsAlls, // [{ Budget: 12, chains: "QUBE", spots: 12 }, { Budget: 0, chains: "PVR", id: 1, spots: 0 }]
          //this.selectedcinemaChainsListItems = this.ViewPostEvalCamapinList.CinemaChain,
          //this.FinalArray = this.ViewPostEvalCamapinList.CinemaChain,
          //console.log(this.ViewPostEvalCamapinList.chainsAlls)
        //State
          this.StateList = this.ViewPostEvalCamapinList.statesAlls,
          this.selectedStateListItems = this.ViewPostEvalCamapinList.states,          
          
          this.IsChainAdd = true         
          this.region_zoneNew = this.regionList;
        this.StateData = this.selectedStateListItems;
       
          this.ShowPostEvlData('V');

      }     
    }) 
  }


  btnDelete(PostEvalId) {
    this.GolblePostEvalId = "";
    this.GolblePostEvalId = PostEvalId;
    this.IsMessage = true;
}
  DeleteNo() {
    debugger;
    this.IsMessage = false;
    this.GolblePostEvalId = "";
  }

  Delete() {
    debugger;

    
      // Save it!
      const headers = { 'content-type': 'application/json' }
      var url = this.global.baseUrl + 'api/PostEvalDetails/DeletePostEvalCampine/?PostEvalID=' + this.GolblePostEvalId;
      this.http.post<any>(url, { 'headers': headers }).subscribe((response) => {
        debugger

        this.isShowLoader = false;
        //alert(response.Message)
        this.GetPostEvalCampainList();
        this.IsMessage = false;
        this.GolblePostEvalId = "";
      }, (error) => {
        this.isShowLoader = false;
        alert("Error Occured")

      });      
   

    
  }

  SavePostEvalDetails() {
    debugger
    //this.isShowLoader = true;
    //this.IsSubmitPostEval = true
    this.FinalArray = [];
    this.FinalArray = this.registerPostForm.value.cinemachain;

    this.StartDate = this.datepipe.transform(this.CampaignStartDate, 'dd/MMM/yyyy');
    this.EndDate = this.datepipe.transform(this.CampaignEndDate, 'dd/MMM/yyyy');

    const headers = { 'content-type': 'application/json' }
    const PostEvlData: any = {
      "CampaignTitle": this.CampaignTitle,
      "ClientName": this.ClientName,
      "region_zone": this.region_zoneNew,
      "states": this.StateData,
      "chains": [],
      "CinemaChain": this.FinalArray,
      "StartDate": this.StartDate,
      "EndDate": this.EndDate,
      "User_id": this.global.IsUserId,
      "Duration": this.Duration
    };
    

    //  this.EndDate = moment(this.CampaignEndDate).format('dd/MM/yyyy');
    const body = JSON.stringify(PostEvlData)
    var url = this.global.baseUrl + 'api/PostEvalDetails/Insert_Updaed_PostEvalDetails/'
    ///var url = this.global.baseUrl + 'api/Dashboard/GetPostEvalDetails/?StartDate=' + '01/01/2023' + '&EndData=' + '10/01/2023' + '&Duration=' + 30
    this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      debugger
      //this.isShowLoader = false;
      //this.FinalPostEvalData = response;
      //this.isShowLoader = false;
      //alert(this.FinalPostEvalData.Message)
     

    }, (error) => {
      this.isShowLoader = false;
      alert("Error Occured")

    });
  }

  Clear() {
    this.CampaignTitle = "";
    this.ClientName = "";
    this.Duration = "";
    this.CampaignStartDate = "";
    this.CampaignEndDate = "";
    this.regionList = [];
    this.region_zoneNew = [];
    this.selectedStateListItems = [];
    this.StateData = []
    this.cinemaChainsList = []
    this.FinalArray = []
    this.selectedcinemaChainsListItems = []
    this.FinalPostEvalData = [];
    this.IsChainAdd = false
    this.IsSubmitPostEval = false
    this.clearFormArray(this.cinemaChainFormArray);
  }

  clearFormArray = (cinemaChainFormArray: FormArray) => {
    debugger
    while (cinemaChainFormArray.length !== 0) {
      cinemaChainFormArray.removeAt(0)
    }
  }








}
