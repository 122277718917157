import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { IAppConfig } from './app-config.model';
import { SharedService } from './shared/shared.service'
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable()
export class AppConfig {
  static settings: IAppConfig;
  private http: HttpClient;
  constructor(handler: HttpBackend, public _sharedService: SharedService) {
    this.http = new HttpClient(handler);
    
  }
  load() {
    debugger;
    const jsonFile = `../../../../Scripts/assets/config/config.json`;
    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile).toPromise().then((response: IAppConfig) => {
        debugger;
        console.log(response)
        AppConfig.settings = <IAppConfig>response;
        this._sharedService.setAPIurl(AppConfig.settings.apiServer.api_url);
        this._sharedService.setURL(AppConfig.settings.apiServer.url);
        this._sharedService.setENV(AppConfig.settings.env.name);       

        resolve();
      }).catch((response: any) => {
        reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
      });
    });
  }
}
