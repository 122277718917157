<style>

  p {
    text-align: justify;
  }
</style>
<div class="container" style="padding-top:50px">
  <div _ngcontent-oou-c51="" class="row" style="padding-top: 50px;padding-bottom: 71px;">


    <p style="    text-align: left;
        font-size: 23px;
        padding-top: -11px;
        font-family: fangsong;
        color: #532ABA;
        font-weight: 500;">Privacy Policy</p>



    <b>INTRODUCTION</b>
    <p></p>
    <p>
      This Privacy Policy details the manner in which Dusane Infotech India Pvt. Ltd. collects, stores, deals and
      handles the users information made available by the user as a result of user’s access and use of the
      Service/tools provided by us through our website https://cambioscope.com/.
    </p>

    <p>
      CamBioscope is a proprietary AI (Artificial Intelligence) based cinema advertisement planning tool designed
      with an objective to derive Maximum ROI against every rupee spent. It intelligently suggests the screens and
      rates at which any campaign must be planned to reduce the Cost Per Media Impression (CPM) and increase ROI
      (“Service”).
    </p>
    <p>
      We value the trust you place in us and we are committed to safeguard the privacy of our users. we ensure to
      maintain the highest standards for customer information privacy on our website. By accessing and using this
      website, you accept and agree to be bound by and comply with this Privacy Policy. If a user continues to
      visit and use the website and its services, after choosing to do so, then any dispute regarding breach of
      privacy will be subject to this Privacy Policy. The applicable law for any such dispute shall be governed by
      the laws of Republic of India. The competent courts at Mumbai, India will have exclusive jurisdiction over
      any or all disputes arising out of, or in connection with the Privacy Policy.
    </p>
    <p>
      The terms ‘we’, ‘us’ and ‘our’ with its allied and cognate expressions shall refer to Dusane Infotech India
      Pvt. Ltd and the terms ‘you’ and ‘your’ with its allied and cognate expressions shall refer to user(s).
    </p>
    <b>WHAT PERSONAL DATA WE GATHER ABOUT YOU</b>
    <p></p>
    <p>
      When you access our website, or sign in, or use our tools and Service, we collect the following type of
      information:
    <p></p>
    <b>1. Personal Information</b>
    <p>
      Personal Identification data and communication information which is provided to us by you shall mean and
      include your name, data of birth, age, phone number, home address, company name, email address and formal
      identification information such as national identity cards (collectively “Personal Information”) that is
      otherwise publically not available. The Personal Information may be processed and saved for the purposes of
      allowing access and use of our website, obligations, ensuring the security, maintaining back-ups of our
      databases and communicating with you. This Personal Information is necessary for the adequate performance of
      our tools and Service and its features thereof and to allow us to comply with our legal obligations. Without
      it, we may not be able to give you access to our website or you may not be able to use the various features
      and services offered through our website. In case you provide any wrong, false or incorrect data, you would
      be liable for the consequences of the same.
    </p>

    <b>2. Device Data</b>
    <p>
      Certain data which may be collected from the device on which you use this website shall include but shall
      not be limited to technical information, such as configuration information, your IP address, the device’s
      version, device’s unique ID, identification number, cookie information, aggregate information on what
      content you access or visit. This information is collected in the background while you browse our website.
    </p>

    <b>3. Location Data</b>
    <p>
      We may process data which may include your location and time zone for the purpose for providing you a better
      user experience.
    </p>

    <b>4. Financial Data</b>
    <p>
      Please note that we do not store any financial data provided by you at the time of purchasing our services
      including but not limited to your credit card and debit card numbers, bank account details, passwords etc.
      when you make payments for services on our website. This data is processed by the payment gateway service
      provider and your payments are subject to the terms and conditions of services provided by such payment
      gateway service provider.
    </p>

    <p>
      Personal Information, Device Data, Location Data and Financial Data is hereinafter collectively referred to
      as <b>‘Personal Data’.</b>
    </p>
    <b>HOW WE USE YOUR PERSONAL DATA</b>
    <p></p>
    <p>We use the Personal Data for the following purposes.</p>
    <p>• To provide and maintain our Service: including to monitor the usage of our tools and Service.</p>
    <p>
      • To manage your account: to manage your registration as a user of the Service. The Personal Data you provide
      can give you access to different functionalities of the Service that are available to you as a registered
      user.
    </p>
    <p>
      • For the performance of a contract: the development, compliance and undertaking of the purchase contract for
      the products, items or Services you have purchased or of any other contract with us through the Service.
    </p>
    <p>
      • To contact you: To contact you by email, telephone calls, SMS, or other equivalent forms of electronic
      communication, such as a mobile application's push notifications regarding updates or informative
      communications related to the functionalities, products or contracted services, including the security
      updates, when necessary or reasonable for their implementation.
    </p>
    <p>
      • To provide you with news, special offers and general information about other goods, services and events
      which we offer that are similar to those that you have already purchased or enquired about unless you have
      opted not to receive such information.
    </p>
    <p>• To manage your requests: To attend and manage your requests to us.</p>
    <p>
      • For other purposes: We may use your information for other purposes, such as data analysis, identifying
      usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our
      Service, products, marketing and your experience.
    </p>
    <p>
      • We might process your Personal Data for additional purposes that are not mentioned here, but are compatible
      with the original purpose for which the data was gathered. To do this, we will ensure that: the link between
      purposes, context and nature of Personal Data is suitable for further processing; the further processing
      would not harm your interests and there would be appropriate safeguard for processing.
    </p>
    <p></p>
    <p>We will inform you of any further processing and purposes.</p>

    <b>WHAT ARE YOUR RIGHTS WITH RESPECT TO YOUR PERSONAL DATA?</b>
    <p></p>
    <p>You are entitled to the following rights:</p>
    <p></p>
    <p>
      • Right to rectification – Meaning you have the right to request rectification or erasure of your Personal
      Data that is inaccurate or incomplete.
    </p>
    <p>
      • Right to erasure – Meaning in certain circumstances you can request for your Personal Data to be erased
      from our records.
    </p>
    <p>
      • Right to delete account – Meaning that you may delete your account from our website. Thereafter you will
      not be able to avail our services.
    </p>
    <p>
      • Right to withdraw consent – You have the right withdraw any given consent for Processing of your Personal
      Data. However, this will be effective prospectively and shall not apply to past transactions wherein your
      data has already been processed.
    </p>
    <p></p>
    <b>WHAT ABOUT THIRD-PARTY LINKS?</b>
    <p></p>
    <p>
      Our website may contain links to other websites (Third-Party Links) as hyperlinks or for references and their
      privacy policy and terms of service may differ from us. If you submit your Personal Data to any of those
      sites, your information will be governed by their privacy policy. We recommend and encourage you to read the
      privacy policy of any website your visit. We shall not be liable for any loss, damage, claim or expenses
      suffered by you as a result for accessing these third-party websites. It should not be construed that we are
      recommending or encouraging you to buy any products or avail any services from such third-party websites or
      that we are representing that any contents or information on such third party websites is accurate and
      correct. We strongly advise you to review the Privacy Policy of any third party site you visit.
    </p>
    <p></p>

    <b>HOW AND WITH WHOM DO WE “SHARE” YOUR PERSONAL DATA?</b>
    <p></p>
    <p>
      We may share your Personal data with our affiliates and other third party affiliates for various purposes,
      including but not limited to providing you with the services requested by you, help the detection and
      prevention of identity theft, fraud, potentially illegal acts; to facilitate marketing and promotional
      activities. We may also disclose Personal Data required to do so by law or in the good faith belief that
      such disclosure is reasonably necessary to respond to investigation, court orders, or other legal process.
      We may disclose Personal Data to law enforcement offices, third party rights owners, or others in the good
      faith belief that such disclosure is reasonably necessary to: enforce this Privacy Policy; respond to claims
      that an advertisement, posting or other content violates the rights of a third party; or protect the rights,
      property or personal safety of Our users or the general public.
    </p>

    <p>
      We and our affiliates will also share / sell some or all of your Personal Data with another business entity
      should we (or our assets) plan to merge with, or be acquired by that business entity, or re-organization,
      amalgamation, restructuring of business.
    </p>
    <p></p>
    <b>WHY DO WE “STORE” YOUR PERSONAL DATA?</b>
    <p></p>
    <p>
      We will store and retain your Personal Data only for as long as is necessary for the purposes set out in this
      Privacy Policy. We will retain and use your data to the extent necessary to comply with our legal
      obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
      disputes, and enforce our legal agreements and policies. We will also retain Personal Data for internal
      analysis purposes to strengthen the security or to improve the functionality of our website, services and
      tools, or we are legally obligated to retain this data for longer time periods. We may also use your
      Personal Data to send you information about our products, services and offers that may be relevant to you.
    </p>
    <p></p>
    <b>HOW DO WE SECURE YOUR DATA?</b>
    <p></p>
    <p>
      The security of your data is important to us. We have implemented security measures designed to protect the
      Personal Data you share with us, including physical, electronic and procedural measures. We regularly
      monitor our systems for possible vulnerabilities and attacks, and regularly seek new ways for further
      enhancing the security of our website and protection of your Personal Data. However, no method of
      transmission over the Internet, or method of electronic storage, is 100% secure.
    </p>
    <p></p>
    <b>WHAT ABOUT “ACCOUNT DELETION”?</b>
    <p></p>
    <p>
      If you wish to delete your user account from the website, you can do so at your discretion through our
      account deletion feature or by sending a request at the address provided at the bottom of this Privacy
      policy. Please note that, after your user account is deleted from our website, you will not be able to login
      into the website. In that case, you may sign up and create a user account again and use our website. After
      you delete your account, all exiting Personal Data and history will be erased from our website except the
      information we are legally bound to retain as per Information Technology Act, 2020 including all amendments
      made to it and rules, guidelines framed thereunder.
    </p>
    <p></p>
    <b>OUR COMMITMENT TO CHILDREN'S PRIVACY</b>
    <p></p>
    <p>
      The website will not knowingly collect, use or retain Personal Data from anyone under the age of 18, for any
      purpose whatsoever (including internal or external marketing or promotional purposes). If you are under the
      age of 18, you will need your parent or guardian's consent before you can provide any personal information
      to us for purposes of registration.
    </p>
    <p>
      By registering to this website, we have reasons to believe that you have taken required consents from your
      Parents / Guardian.
    </p>
    <p></p>
    <b>HACKING OR MISREPRESENTATION OR FALSE CLAIM</b>
    <p></p>
    <p>
      In the event of any attempt made by you to hack all or any portion of the Website, misrepresent information
      on the Website, or make false claims against Website, we shall have the right to claim and recover damages,
      compensation, liabilities, losses (including but not limited to loss of reputation and/or goodwill), claims,
      expenses, costs, attorney fees and including but not limited to third party claims etc. from you.
    </p>

    <p>
      We store the Personal Data on our servers or on third-party cloud platforms or servers which are only
      accessible to employees or agents. Industry-standard safeguards are employed to protect the confidentiality
      of your Personal Data under our control and also prevent unauthorized loss, misuse, or alteration of such
      Personal Data. However, considering the nature of internet transmissions, complete security of Personal Data
      communicated through such transmissions cannot be guaranteed. Hence, our responsibility of maintaining your
      Personal Data shall be on best effort basis only. In the unlikely event of the disclosure of your Personal
      Data as a result of transmission errors or unauthorised acts of third parties. you agree that our liability
      shall not exceed an amount equal to one-month subscription fee paid by you on our website.
    </p>
    <p></p>
    <b>COOKIE POLICY</b>
    <p></p>
    <p>
      Cookie is a small file of letters and numbers downloaded on to your computer or device when you access our
      website. It enables us to identify you and log you into the website, understand and store your preferences
      based on your previous visit, and remember certain information to make the website more user-friendly to
      you, it acts as an element of the security measures used to protect user accounts, including preventing
      fraudulent use of login credentials, and to protect our website. We also use cookies to help us compile
      aggregated and anonymized data about the traffic on the website so that we can offer better experiences and
      tools in the future. Please note that browsers automatically accept cookies, and if you do not wish cookies
      to be used, you may need to actively delete or block the cookies. You can choose to reject or block all or
      specific types of cookies by clicking on the cookie preferences on this website or by changing your browser
      settings. This may however impact your user-experience and the functions of the website will be limited.
    </p>
    <p></p>
    <b>WILL OUR WEBSITE CHANGE PRIVACY POLICY IN THE FUTURE?</b>
    <p>
      This Privacy Policy provides a general statement of the ways in which our website aims to protect your
      Personal Data. You may, however, in connection with specific products or services offered by our website, be
      provided with privacy policies or statements that substitute or supplement this Privacy Policy. The terms
      and conditions of this Privacy Policy may be changed from time to time, without your consent (express or
      implied) to reflect changes in our practices concerning the collection and use of your Personal Data. The
      revised Privacy Policy will be effective immediately upon posting to our website unless otherwise mentioned.
      You shall be bound by the revised Privacy Policy and it is your sole responsibility to check the same from
      time to time. You acknowledge that no knowledge of the revised Privacy Policy shall be a defence in any
      proceedings with regard to the Privacy Policy.
    </p>
    <p></p>
    <b>CONSENT</b>
    <p>
      By accessing this website and using the services offered through this website, you accept the terms of, and
      be bound by this Privacy Policy. By voluntary providing information on this website, you consent to the
      collection, use and disclosure of such information and Personal Data in the manner set forth in this Privacy
      Policy.
    </p>
    <p></p>

    <b>CONTACT INFORMATION</b>
    <p></p>
    <p>
      If you have any questions or comments regarding our Privacy Policy Statement, you can contact our Grievance
      Officer, the name and contact details of which are provided below:
    </p>
    <p></p>
    <p>Name: </p>
    <p></p>

    <p>
      Address: 4th Floor, TJSB House, Sambhav IT Park Road No 1, Plot # B5, Wagle Estate, MIDC, Thane West, Thane,
      Maharashtra - 400604.
    </p>
    <p>Email: cambioscope@dusaneinfotech.com</p>


  </div>
</div>
