<!-- <div _ngcontent-oou-c51="" class="row" style="height:500px;padding-top: 120px;padding-bottom: 71px;">

    <p style="text-align: center;

    font-size: 46px;
    padding-top: 60px;
    font-family: fangsong;
    color: #532ABA;">Coming Soon...</p>
</div> -->
  <style>
    p,li,ul{
      text-align:justify;
    }
  </style>


<div *ngIf="this.global.IsLoggedIn =='false'" class="container" style="padding-top:50px">


  <div class="row" style="    margin-top: 35px;">
    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="    padding-top: 10px;">
      <img src="../../../../Scripts/assets/img/content/ad_palnner.png" />
      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 caption"
           style="    text-align: center;">

        <p style="text-align:center">Cinema Statistics</p>
      </div>
      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 captionnew"
           style="text-align: center;margin-bottom: 20px;">


        <div class="tp-caption butn primary rs-parallaxlevel-3 position-relative z-index-99 xs-wide"
             (click)="GoToLoginFromCineStart()" style="background-color: #532ABA;border-radius: 7px"><span>LOGIN</span></div>

      </div>

    </div>
  </div>

  <div class="row" style="    margin-top: 21px;">
    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <p>
        Cine Stats is designed to give pulse of the Indian Cinema Industry. This gives a complete overview as
        well as the insights of the industry from advertizers perspective. Indian cinema industry is broadly
        divided in two Geographies
        <ul>
          <li>
            South : Southern part of India comprizing of Kerala, Andrapradesh, Telangana, Karnataka, Tamilnadu
            and Pondicherry
          </li>

          <li>Hindi Speaking Markets(HSM) : Rest of india is primarily treated as HSM.</li>
        </ul>

        CineStats Helps users Slice and Dice the data across multiple paramaters for these two primary markets.
        Some of the key data points include
        <ul>
          <li>Active Screens</li>
          <li>Show per day</li>
          <li>Admits per Day\Per Show</li>
          <li>Avg Box office collections</li>
          <li>Occupancy</li>
        </ul>





    </div>

  </div>
</div>

<div *ngIf="this.global.IsLoggedIn =='true'" style="padding-top:50px">
  <iframe width="100%" height="700" [src]="CineStartURL | safe" frameborder="0" allowFullScreen="true"></iframe>
</div>



