import { Component, OnInit } from '@angular/core';
import { Global } from '../global/global'
import { Router, NavigationExtras } from '@angular/router';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // MenuH
  UserFullname: string;
  EmailID: string;
  phoneNumber: string;
  UserDetails: any = [];
  FirstName: string;
  EditProfileDetailsArray: any = [];
  IsDAVPUser: boolean = false;
  IsGeneralUser: boolean = true;
  constructor(public global: Global, private router: Router) {
    this.EmailID = "";
    this.UserFullname = "";
    this.phoneNumber = "";
    this.FirstName = "";

  }

  // UserMgt
  ngOnInit(): void {
    debugger
    this.global.IsLoggedIn = sessionStorage.getItem('IsLoggedIn');
    if (this.global.IsLoggedIn == null) {
      this.global.IsLoggedIn = 'false';
    }
    console.log(this.global.IsLoggedIn)
    this.global.IsOnMenu = true
    this.global.IsOnApproachMenu = false
    this.global.IsOnPlannerMenu = false
    this.global.IsOnPostEvalMenu = false
    this.global.IsOnContactUsMenu = false
    this.global.IsOnStartedMenu = false
    this.global.IsCinemaStats = false


    var UserType = localStorage.getItem('UserType');
    if (UserType != null) {
      if (UserType == "General") {
        this.IsDAVPUser = false;
        this.IsGeneralUser = true;
      }
      else {
        this.IsDAVPUser = true;
        this.IsGeneralUser = false;
      }
    }

    var EditProfileDetails = JSON.parse(localStorage.getItem('EditProfileDetails'));
    //this.EditProfileDetailsArray = [];
    //this.EditProfileDetailsArray.push(EditProfileDetails[0]);

    //EditProfileDetails=this.EditProfileDetailsArray;

    if (EditProfileDetails.length > 0) {
      this.FirstName = EditProfileDetails[0].FirstName;
      this.UserFullname = EditProfileDetails[0].FirstName + " " + EditProfileDetails[0].LastName;
      this.EmailID = EditProfileDetails[0].EmailID;
      this.phoneNumber = EditProfileDetails[0].Phone;
    }
    else {
      this.FirstName = EditProfileDetails.FirstName;
      this.UserFullname = EditProfileDetails.FirstName + " " + EditProfileDetails.LastName;
      this.EmailID = EditProfileDetails.EmailID;
      this.phoneNumber = EditProfileDetails.Phone;
    }


  }

  GotoStartedPage() {
    window.location.href = "./Login"
  }

  GotoPlanner() {
    window.location.href = "./Planner"
  }

  GotoPlanner2() {
    window.location.href = "./CBPlanner2"
  }

  GoToPostEval() {
    window.location.href = "./PostEval"
  }

  ApproachClick() {
    this.global.IsOnMenu = false
    this.global.IsOnApproachMenu = true
    this.global.IsOnPlannerMenu = false
    this.global.IsOnPostEvalMenu = false
    this.global.IsOnContactUsMenu = false
    this.global.IsOnStartedMenu = false

  }

  // UserMgt
  LogoutApp() {
    debugger

    this.global.IsLoggedIn = 'false';
    sessionStorage.setItem('IsLoggedIn', String(this.global.IsLoggedIn));
    localStorage.clear();
    window.location.href = "./"


  }

  GotoContact() {
    window.location.href = "./Contact"
  }

  GoToCinemaStats() {
    window.location.href = "./CinemaStats"
  }

  GoToHome() {
    debugger
    window.location.href = "./"
  }

  GoforRegistration() {
    debugger
    window.location.href = "./Registration"
  }

  GotoPlanner1() {
    debugger
    window.location.href = "./PlannerDraft"
  }
  GoToPlannerDAVP() {
    debugger
    window.location.href = "./planner"
  }

}
