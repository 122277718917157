import { Component, OnInit } from '@angular/core';
import { Global } from '.././global/global'
import * as models from 'powerbi-models';

@Component({
  selector: 'app-screen-report',
  templateUrl: './screen-report.component.html',
  styleUrls: ['./screen-report.component.css']
})
export class ScreenReportComponent implements OnInit {

  EmbededURL: string
  Details: any = [];
  //State: State[];
  //Region: Region[];

  constructor(private global: Global) {

  }

  ngOnInit(): void {
    debugger
    var ScreenFiltersDetails = JSON.parse(localStorage.getItem('ScreenFiltersDetails'));
    this.global.IsLoggedIn = sessionStorage.getItem('IsLoggedIn');

    //this.Details =
    //  {
    //  State: ["Maharashtra", "GOA", "Kerala"],      
    //  Tier: ["02 Tier 3", "03 Tier 2"],
    //  CityID: ["U|99|66641", "U|99|800452"],
    //  Network: ["01 UFO","02 QUBE"],
    //  ATP: [200]

    //  }
    //if (ScreenFiltersDetails != null) {
    this.Details =
      {
        State: ScreenFiltersDetails[0],
        Tier: ScreenFiltersDetails[1],
        //CityID: ScreenFiltersDetails[],
        Network: ScreenFiltersDetails[2],
        // ATP: ScreenFiltersDetails[3],
        CityID: ScreenFiltersDetails[4],


      }
    //}
    //else {
    //  this.Details =
    //    {
    //      Zone: ["South","HSM"]
    //    }
    //}


    if (this.global.IsLoggedIn == 'true') {
      //this.EmbededURL = this.global.EmbededURL + "/EmbedReport?Details=" + JSON.stringify(this.Details);
      this.EmbededURL = this.global.EmbededURL + "/EmbedScreenReport?Details=" + encodeURIComponent(JSON.stringify(this.Details));
      //this.EmbededURL = this.global.EmbededURL + "/EmbedReport";
    }
    else {
      window.location.href = "./Planner"
    }


    this.EmbededURL = this.EmbededURL.trim();

    if (Boolean(localStorage.getItem('IsPlannerLogin'))) {
      this.global.IsOnMenu = false
      this.global.IsOnApproachMenu = false
      this.global.IsOnPlannerMenu = true
      this.global.IsOnPostEvalMenu = false
      this.global.IsOnContactUsMenu = false
      this.global.IsOnStartedMenu = false
    }


  }

  GoToLoginFromPlanner() {
    window.location.href = "./Login"
  }

  GotoPlanner() {
    window.location.href = "./Planner"
  }

  GoToPostEval() {
    window.location.href = "./PostEval"
  }

}


