<style>

  #spanCity {
    padding-left: 2px;
    text-align: left;
    font: normal normal normal 12px/12px Archivo Variable;
    font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
    letter-spacing: 0.3px;
    color: #F0F0F0;
    /*text-transform: uppercase;*/
    color: #40444e;
    /* opacity: 0.7; */
  }

  #inputNew[type=text] {
    background: transparent;
    border: none;
    border-bottom: 1px solid #000000;
  }


  input {
    color: black;
  }

    input:nth-child(odd) {
      color: #777;
    }

    input:nth-child(even) {
    }

  .myStyle {
    /* #aaaedd */
    background: #c0c3e5
  }


  /* Rates Screen  */

  /* Table css */

  /*table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
      border: 2px solid #c9c1c1;
      border-radius: 10px 5% / 20px 30px;
      ;
    }*/



  th {
    background-color: #866ACF;
    border: 1px solid #866ACF;
  }

  td {
  }

  td,
  th {
    text-align: left;
    padding: 5px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }


  h1 {
    color: green;
  }



  /* Switch Btn CSS */

  .switch {
    position: relative;
    display: inline-block;
    width: 3.75em;
    height: 2.121em;
  }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

  .sliderToggle {
    position: absolute;
    cursor: pointer !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

    .sliderToggle:before {
      position: absolute;
      content: "";
      height: 1.625em;
      width: 1.625em;
      left: 0.25em;
      bottom: 0.25em;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

  input:checked + .sliderToggle {
    background-color: #866ACF;
  }

  input:focus + .sliderToggle {
    box-shadow: 0 0 1px #866ACF;
  }

  input:checked + .sliderToggle:before {
    -webkit-transform: translateX(1.625em);
    -ms-transform: translateX(1.625em);
    transform: translateX(1.625em);
  }

  .sliderToggle.round {
    border-radius: 2.121em;
  }

    .sliderToggle.round:before {
      border-radius: 50%;
    }

  #height.form-control {
    height: 38px important !;
  }


  /* PinCode */
  textarea {
    font-size: 12pt;
    font: normal normal normal Archivo Variable;
    color: #40444e;
  }


  .ScreenCountcss {
    font: normal normal normal 14px/12px Archivo Variable;
    font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
    letter-spacing: 0.3px;
    color: #F0F0F0;
    /*text-transform: uppercase;*/
    color: #40444e;
  }

  .screenCountLabelcss {
    color: #532ABA;
    text-decoration: underline;
    cursor: pointer !important;
  }


  /* Expand Collapse Css */

  .content {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    background-color: #f1f1f1;
  }

  legend {
    background-color: #000;
    color: #fff;
    padding: 3px 6px;
  }

  .minmaxcss {
    width: 35%;
    padding: 2px 4px;
    line-height: 15px;
    border-radius: 4px;
    border-width: 1px;
    border: 1px solid #a0a3a5;
  }

  .focus {
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
    border-color: #eaeaea;
    background-color: #efefef;
  }

  /* table tr:last-child {
    font-weight: bold;
    color: red;
  }
  tr:nth-child
  {
      font-weight: bold;
      color: red;

  } */


  /* #items  td:last-child {
    color: #359900;
    font-weight: bold;
  } */

  table.items tr:last-child {
    background: #cabfe5;
    font-weight: bold;
    font-weight: 700;
  }

  #gameplay-baseball-field {
    padding-right: 10px;
    padding-left: 10px;
  }

  .shadowCss {
    /* box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%); */
    border-color: #eaeaea;
    background-color: #fffdfd;
    border-radius: 8px;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0px 0px 11px #878484;
    color: #656972;
  }

  .shadowCssChange {
    border-color: #eaeaea;
    background-color: #7851d9;
    border-radius: 8px;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0px 0px 11px #a892df;
    color: #fff;
  }

  .configShowscss {
    width: 100%;
    padding: 2px 4px;
    line-height: 15px;
    border-radius: 4px;
    border-width: 1px;
    border: 1px solid #a0a3a5;
  }

  #period {
    border-radius: 4px;
    padding: 8px 6px;
    line-height: 15px;
    width: 100%;
    border-width: 1px;
    border: 1px solid #a0a3a5;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    opacity: 1;
    border-radius: 12px;
    -webkit-box-shadow: 0 8px 6px -6px black;
  }
  }
</style>

<div class="message" *ngIf="IsMessage">
  <div class="row" style="padding-top: 21px;
    padding-left: 40px;">
    <span style=" color: #40444e;
      font-weight: 400;">
      DO you want delete this campaign details?
    </span>
  </div>
  <div class="row" style="padding-top: 30px;">
    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="text-align: right">
      <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
            border: 1px solid #532ABA;opacity: 1;height: 34px;width:85px" (click)=" Delete()">
        Yes
      </button>
    </div>

    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
      <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
            border: 1px solid #532ABA;opacity: 1;height: 34px;width:85px" (click)="DeleteNo()">
        No
      </button>
    </div>

  </div>
</div>


<div *ngIf="this.global.IsLoggedIn =='true' && this.IsCreate == true" class="container" style="padding-top:63px">
  <!-- 50px -->
  <div class="loadingGif" *ngIf="isShowLoader"></div>

  <div class="row" style="padding-top: 11px;">
    <!-- Stepper  -->
    <div class="row">
      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 border" style="margin-bottom: 16px;">
        <div class="overflow-x:auto">

          <div class="wrapper-progressBar">
            <ul class="progressBar" style="margin-left: -52px;">
              <li class="active">Campaign Info</li>
              <li [ngClass]="{active: this.IsScreenStep== true}">Screen Selection</li>
              <li [ngClass]="{active: this.IsRatesStep== true}">Rates</li>
              <li [ngClass]="{active: this.IsConfiguration== true}">Show Configuration</li>


            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Body -->


    <div class="row">
      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="x_panel_border" style="padding-top: 28px;margin-bottom: 32px;" *ngIf="AllLoadDiv">

          <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">


            <!-- <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                 style="text-align: right;margin-left: -8px;" *ngIf="this.IsCollapseScreenSelection == true">
              <img id="logo" src="../.../../../Scripts/assets/img/icons/DArrow.png" style="width:2% ;cursor: pointer;">
            </div> -->
            <!-- Campaign Basic Form -->


            <form [formGroup]="CampaignBasicForm" novalidate *ngIf="IsCampaignBasicDiv">
              <img id="logo" src="../.../../../Scripts/assets/img/icons/circle-left-solid.svg" alt="logo"
                   height='25' width='25' style="margin-bottom:10px" (click)="BackPlanner()" />

              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <p style=" color: #7851d9;  font-weight: 500;  text-transform: uppercase;text-align: center;font-size: 16px;">
                  Campaign Details
                </p>
              </div>
              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">


                <div class="row">
                  <!-- Client Name -->
                  <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">

                    <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                     font: normal normal normal 12px/12px Archivo Variable;
                      font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
          letter-spacing: 0.3px;
          color: #F0F0F0;
          text-transform: uppercase
          ;color: #40444e  ;opacity: 0.7;">Client Name<span class="mandatory">*</span></span>

                    <div>
                      <!-- <input type="text" name="CampaignStartDate" class="form-control" [(ngModel)]="CampaignStartDate" /> -->
                      <input type="text" placeholder="Client Name" class="form-control border-input"
                             formControlName="ClientName" [(ngModel)]="ClientName"
                             class="form-control border-input"
                             [ngClass]="{ 'is-invalid': submitted && CampaignBasicForm.controls.ClientName.errors }">
                    </div>

                    <div *ngIf=" submitted && CampaignBasicForm.controls.ClientName.errors" class="text-danger"
                         style="margin-left: 0px;">
                      <div *ngIf=" submitted && CampaignBasicForm.controls.ClientName.errors.required">
                        Client Name required.
                      </div>
                    </div>
                  </div>

                  <!-- Brands -->
                  <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
              font: normal normal normal 12px/12px Archivo Variable;
              font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
              letter-spacing: 0.3px;
              color: #F0F0F0;
              text-transform: uppercase
              ;color: #40444e  ;opacity: 0.7;">Brands<span class="mandatory">*</span></span>

                    <div>

                      <input type="text" placeholder="Brands" class="form-control border-input"
                             formControlName="Brands" [(ngModel)]="Brands"
                             class="form-control border-input"
                             [ngClass]="{ 'is-invalid': submitted && CampaignBasicForm.controls.Brands.errors }">
                    </div>
                    <div *ngIf=" submitted && CampaignBasicForm.controls.Brands.errors" class="text-danger"
                         style="margin-left: 0px;">
                      <div *ngIf=" submitted && CampaignBasicForm.controls.Brands.errors.required">
                        Brands required.
                      </div>
                    </div>
                  </div>


                  <!-- Duration-->
                  <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
          font: normal normal normal 12px/12px Archivo Variable;
          font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
          letter-spacing: 0.3px;
          color: #F0F0F0;
          text-transform: uppercase
          ;color: #40444e  ;opacity: 0.7;">Duration<span class="mandatory">*</span></span>

                    <div>
                      <input type="text" placeholder="Duration" class="form-control border-input"
                             formControlName="Duration" [(ngModel)]="Duration" class="form-control border-input"
                             [ngClass]="{ 'is-invalid': submitted && CampaignBasicForm.controls.Duration.errors }"
                             maxlength="3" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">
                    </div>

                    <div *ngIf=" submitted && CampaignBasicForm.controls.Duration.errors" class="text-danger"
                         style="margin-left: 0px;">
                      <div *ngIf=" submitted && CampaignBasicForm.controls.Duration.errors.required">
                        Duration required.
                      </div>
                    </div>

                  </div>



                </div>

                <div class="row" style="padding-top: 20px;">
                  <!-- Campaign Objective -->
                  <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
          font: normal normal normal 12px/12px Archivo Variable;
          font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
          letter-spacing: 0.3px;
          color: #F0F0F0;
          text-transform: uppercase
          ;color: #40444e  ;opacity: 0.7;">Campaign Objective<span class="mandatory">*</span></span>

                    <div>
                      <input type="text" placeholder="Campaign Objective" class="form-control border-input"
                             formControlName="CampaignObject" [(ngModel)]="CampaignObject"
                             class="form-control border-input"
                             [ngClass]="{ 'is-invalid': submitted && CampaignBasicForm.controls.CampaignObject.errors }">
                    </div>

                    <div *ngIf=" submitted && CampaignBasicForm.controls.CampaignObject.errors"
                         class="text-danger" style="margin-left: 0px;">
                      <div *ngIf=" submitted && CampaignBasicForm.controls.CampaignObject.errors.required">
                        Campaign Objective required.
                      </div>
                    </div>

                  </div>

                  <!-- Campaign StartDate -->
                  <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
              font: normal normal normal 12px/12px Archivo Variable;
              font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
              letter-spacing: 0.3px;
              color: #F0F0F0;
              text-transform: uppercase
              ;color: #40444e  ;opacity: 0.7;">Campaign Start Date<span class="mandatory">*</span></span>
                    <div>
                      <input type="text" placeholder="Select StartDate" bsDatepicker
                             [(ngModel)]="CampaignStartDate" class="form-control border-input"
                             value="{{ CampaignStartDate | date:'dd-MMM-yyyy' }}"
                             formControlName="CampaignStartDate" [bsConfig]="{ dateInputFormat: 'DD-MMM-YYYY' }"
                             [ngClass]="{ 'is-invalid': submitted && CampaignBasicForm.controls.CampaignStartDate.errors }"
                             readonly>
                    </div>

                    <div *ngIf=" submitted && CampaignBasicForm.controls.CampaignStartDate.errors"
                         class="text-danger" style="margin-left: 0px;">
                      <div *ngIf=" submitted && CampaignBasicForm.controls.CampaignStartDate.errors.required">
                        Start Date required.
                      </div>
                    </div>

                  </div>



                  <!-- Campaign EndDate -->
                  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                        font: normal normal normal 12px/12px Archivo Variable;
                        font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                        letter-spacing: 0.3px;
                        color: #F0F0F0;
                        text-transform: uppercase
                        ;color: #40444e  ;opacity: 0.7;">Campaign End Date<span class="mandatory">*</span></span>
                    <div>
                      <input type="text" placeholder="Select End Date" bsDatepicker
                             [(ngModel)]="CampaignEndDate" class="form-control border-input"
                             value="{{ CampaignEndDate | date:'dd-MMM-yyyy'}}" formControlName="CampaignEndDate"
                             [bsConfig]="{ dateInputFormat: 'DD-MMM-YYYY' }"
                             [ngClass]="{ 'is-invalid': submitted && CampaignBasicForm.controls.CampaignEndDate.errors }"
                             readonly />
                    </div>

                    <div *ngIf=" submitted && CampaignBasicForm.controls.CampaignEndDate.errors"
                         class="text-danger" style=" margin-left: 0px;">
                      <div *ngIf=" submitted && CampaignBasicForm.controls.CampaignEndDate.errors.required">
                        End Date required.
                      </div>
                    </div>
                  </div>









                </div>

                <!-- Duration -->
                <div class="row" style="padding-top: 20px;">
                  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">

                      <div class="col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div class="row">
                          <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                                                    font: normal normal normal 12px/12px Archivo Variable;
                                                    font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                                                    letter-spacing: 0.3px;
                                                    color: #F0F0F0;
                                                    text-transform: uppercase
                                                     ;color: #40444e  ;opacity: 0.7;">Analysis Period</span>


                          </div>

                        </div>

                        <div class="row" style="padding-top: 6px;padding-bottom: 8px;">
                          <div class="row">
                            <div class="col-3 col-xs-3 col-sm-4 col-md-4 col-lg-3 col-xl-3" style="    margin-top: 8px;
                                                        text-align: center;">


                              <!-- <div ngbDropdown>
                                  <button class="btn btn-primary" style="background: #fff;
                              border-color: gray;
                              color: grey;" id="refreshPeriodddl"
                                      ngbDropdownToggle>{{PeriodSelection}}</button>



                                  <div ngbDropdownMenu="gfg">

                                      <button ngbDropdownItem
                                          (click)="GetPeriodDuration('Last')">Last</button> -->
                              <!-- <button ngbDropdownItem
                                  (click)="GetPeriodDuration('Next')">Next</button>
                              <button ngbDropdownItem
                                  (click)="GetPeriodDuration('This')">This</button> -->
                              <!-- </div>
                              </div> -->


                              <span style="padding-left: 2px;
                                                            text-align: left;
                                                            font: normal normal normal 15px/12px Archivo Variable;
                                                            font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                                                            letter-spacing: 0.3px;
                                                            color: #F0F0F0;
                                                            /* text-transform: uppercase; */
                                                            color: #40444e;
                                                            opacity: 0.7;
                                                            font-weight: bold;">Last</span>


                            </div>

                            <div class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                                 style="padding-left: 1px;">
                              <div>
                                <!-- <input [(ngModel)]="PeriodInputValue" type="number" id=period
                                (input)="GetPeriodInputVal($event)"> -->
                                <input type="number" id=period class=" border-input"
                                       formControlName="PeriodInputValue" [(ngModel)]="PeriodInputValue"
                                       (input)="GetPeriodInputVal($event)" min="1">
                              </div>


                            </div>

                            <div class="col-5 col-xs-5 col-sm-4 col-md-4 col-lg-5 col-xl-5"
                                 style="padding-left: 1px;">
                              <div ngbDropdown>
                                <button class="btn btn-primary" style="background: #fff;
                                                            border-color: gray;
                                                            color: grey;" id="gfg"
                                        ngbDropdownToggle>
                                  {{PeriodCalenderselection}}
                                </button>
                                <div ngbDropdownMenu="gfg">
                                  <button ngbDropdownItem
                                          (click)="GetPeriodDuration('Day')">
                                    Day
                                  </button>
                                  <button ngbDropdownItem
                                          (click)="GetPeriodDuration('Weeks')">
                                    Weeks
                                  </button>
                                  <button ngbDropdownItem
                                          (click)="GetPeriodDuration('Months')">
                                    Months
                                  </button>
                                  <!-- <button ngbDropdownItem
                                  (click)="GetPeriodDuration('Years')">Years</button> -->
                                </div>
                              </div>
                            </div>


                          </div>
                        </div>




                      </div>

                    </div>
                  </div>


                </div>


                <div class="row" style="padding-top: 20px;">
                  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">

                      <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div class="row">
                          <div class="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                            <span style="font-weight: 500;opacity: 0.6;">{{ToPeriodDate}}</span>
                          </div>
                          <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"
                               style="    padding-left: 1px;">
                            <span style="font-weight: 500;opacity: 0.6;">-</span>
                          </div>
                          <div class="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5">

                            <span style="font-weight: 500;opacity: 0.6;">{{FromPeriodDate}}</span>
                          </div>

                        </div>

                      </div>
                      <div class="col-12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">

                      </div>
                    </div>
                  </div>
                </div>


                <div class="row" style="padding-top: 50px;padding-bottom: 25px;">

                  <!-- Submit Button -->
                  <div class="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <div class="row">
                      <div class="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-3 col-xl-2">
                        <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
                                             border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="DarftBasicInfo(0)">
                          Save
                        </button>
                      </div>

                      <div class="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-3 col-xl-2"
                           style="    margin-left: -44px;">
                        <button id="BasicInfoNext" class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
              border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="onCampaignBasicSubmit()">
                          Next
                        </button>
                      </div>
                    </div>




                  </div>

                </div>

              </div>



            </form>

            <!-- Screen Form -->

            <form [formGroup]="ScreenForm" *ngIf="IsScreenStepDiv" novalidate>
              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <p style=" color: #7851d9;  font-weight: 500;  text-transform: uppercase;text-align: center;font-size: 16px;">
                  Screen Selection
                </p>
              </div>

              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <img id="logo" src="../.../../../Scripts/assets/img/icons/FIlterNew.png"
                         style="width:6% ;cursor: pointer; opacity: 0.7;border: 1px solid #9b9090;"
                         (click)="ClearScreenSelection()">
                  </div>
                </div>
                <div class="row">
                  <!-- Region -->
                  <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">

                    <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                            font: normal normal normal 12px/12px Archivo Variable;
                            font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                            letter-spacing: 0.3px;
                            color: #F0F0F0;
                            text-transform: uppercase
                            ;color: #40444e  ;padding-bottom: 6px;opacity: 0.7;">Region<span class="mandatory">*</span></span>

                    <div class="row" style="padding-top: 6px;">
                      <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                           *ngFor="let regionlist of regionList">
                        <div class="row ">
                          <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                               style="color: #40444e;font-size:15px;opacity: 0.7;">
                            {{regionlist.Region}}
                          </div>
                          <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <!-- <input type="checkbox"  class="chck" id="{{'btn'+ regionlist.Zone}}"
                            [checked]="regionlist.Status" (change)="checkValue(regionlist)"> -->
                            <input type="checkbox" [checked]="regionlist.Status" class="chck"
                                   name="check"
                                   id="{{'btn'+ regionlist.Region}}"
                                   (change)="checkValue($event,regionlist)">
                            <!-- (change)="checkValue(regionlist)" -->
                          </div>
                        </div>




                      </div>

                    </div>




                  </div>

                  <!-- State -->
                  <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                        font: normal normal normal 12px/12px Archivo Variable;
                        font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                        letter-spacing: 0.3px;
                        color: #F0F0F0;
                        text-transform: uppercase
                        ;color: #40444e  ;padding-bottom: 6px;opacity: 0.7;">
                      State<span class="mandatory">*</span>
                    </span>


                    <ng-multiselect-dropdown [settings]="dropdownStateSettings" [data]="StateList"
                                             [(ngModel)]="selectedStateListItems" (onSelect)="onItemStateSelect($event)"
                                             (onSelectAll)="onStateSelectAll($event)" (onDeSelect)="onItemStateDeSelect($event)"
                                             (onDeSelectAll)="onUnStateSelectAll()" formControlName="selectStateList"
                                             [ngClass]="{ 'is-invalid': Screensubmitted && ScreenForm.controls.selectStateList.errors }">
                    </ng-multiselect-dropdown>

                    <div *ngIf=" Screensubmitted && ScreenForm.controls.selectStateList.errors"
                         class="text-danger" style="margin-left: 0px;">
                      <div *ngIf=" Screensubmitted && ScreenForm.controls.selectStateList.errors.required">
                        State required.
                      </div>

                    </div>
                  </div>


                  <!-- Tiers -->
                  <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                        font: normal normal normal 12px/12px Archivo Variable;
                        font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                        letter-spacing: 0.3px;
                        color: #F0F0F0;
                        text-transform: uppercase
                        ;color: #40444e  ;padding-bottom: 6px;opacity: 0.7;">
                      Tiers<span class="mandatory">*</span>
                    </span>



                    <ng-multiselect-dropdown [settings]="dropdownTiersSettings" [data]="TiersList"
                                             [(ngModel)]="selectedTiersListItems" (onSelect)="onItemTiersSelect($event)"
                                             (onSelectAll)="onTiersSelectAll($event)" (onDeSelect)="onItemTiersDeSelect($event)"
                                             (onDeSelectAll)="onUnTiersSelectAll()" formControlName="selectTiersList"
                                             [ngClass]="{ 'is-invalid': Screensubmitted && ScreenForm.controls.selectTiersList.errors }" (click)="getTiers()">
                    </ng-multiselect-dropdown>

                    <div *ngIf=" Screensubmitted && ScreenForm.controls.selectTiersList.errors"
                         class="text-danger" style="margin-left: 0px;">
                      <div *ngIf=" Screensubmitted && ScreenForm.controls.selectTiersList.errors.required">
                        Tiers required.
                      </div>

                    </div>
                  </div>





                </div>


                <div class="row" style="padding-top: 20px">

                  <!-- Screen Type -->
                  <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">

                    <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                                    font: normal normal normal 12px/12px Archivo Variable;
                                    font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                                    letter-spacing: 0.3px;
                                    color: #F0F0F0;
                                    text-transform: uppercase
                                              ;color: #40444e  ;padding-bottom: 6px;opacity: 0.7;">Screen Type<span class="mandatory">*</span></span>

                    <div class="row" style="padding-top: 6px;">
                      <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                           *ngFor="let screenTypeLt of ScreenTypeList">
                        <div class="row ">
                          <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                               style="color: #40444e;font-size:15px;opacity: 0.7;">
                            {{screenTypeLt.ScreenType}}
                          </div>
                          <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">

                            <input type="checkbox" [checked]="screenTypeLt.Status" class="chck"
                                   name="check" id="{{'btn'+ screenTypeLt.ScreenType}}"
                                   (change)="checkScreenValue($event,screenTypeLt)">

                          </div>
                        </div>




                      </div>

                    </div>




                  </div>

                  <!-- Cities -->
                  <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                        font: normal normal normal 12px/12px Archivo Variable;
                        font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                        letter-spacing: 0.3px;
                        color: #F0F0F0;
                        text-transform: uppercase
                        ;color: #40444e  ;padding-bottom: 6px;opacity: 0.7;">
                      City<!--<span class="mandatory">*</span>-->
                    </span>



                    <ng-multiselect-dropdown [settings]="dropdownCitySettings"
                                             [(ngModel)]="selectedCityListItems" (onSelect)="onItemCitySelect($event)"
                                             (onSelectAll)="onCitySelectAll($event)"
                                             (onDeSelect)="onItemCityDeSelect($event)"
                                             (onDeSelectAll)="onUnCitySelectAll()" formControlName="selectCityList"
                                             [ngClass]="{ 'is-invalid': Screensubmitted && ScreenForm.controls.selectCityList.errors }"
                                             (click)="getCities()" [disabled]="true">
                    </ng-multiselect-dropdown>

                    <div *ngIf=" Screensubmitted && ScreenForm.controls.selectCityList.errors"
                         class="text-danger" style="margin-left: 0px;">
                      <div *ngIf=" Screensubmitted && ScreenForm.controls.selectCityList.errors.required">
                        City required.
                      </div>

                    </div>
                  </div>

                  <!-- Pin Code -->
                  <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                        font: normal normal normal 12px/12px Archivo Variable;
                        font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                        letter-spacing: 0.3px;
                        color: #F0F0F0;
                        text-transform: uppercase
                        ;color: #40444e  ;padding-bottom: 6px;opacity: 0.7;">
                      Pin Code<!--<span class="mandatory">*</span>-->
                    </span>



                    <ng-multiselect-dropdown [settings]="dropdownPinCodeSettings"
                                             [(ngModel)]="selectedPinCodeListItems" (onSelect)="onItemPinCodeSelect($event)"
                                             (onSelectAll)="onPinCodeSelectAll($event)" (onDeSelect)="onItemPinCodeDeSelect($event)"
                                             (onDeSelectAll)="onUnPinCodeSelectAll()" formControlName="selectPinCodeList"
                                             [ngClass]="{ 'is-invalid': Screensubmitted && ScreenForm.controls.selectPinCodeList.errors }" [disabled]="true" (click)="getPinCodes()">
                    </ng-multiselect-dropdown>

                    <div *ngIf=" Screensubmitted && ScreenForm.controls.selectPinCodeList.errors"
                         class="text-danger" style="margin-left: 0px;">
                      <div *ngIf=" Screensubmitted && ScreenForm.controls.selectPinCodeList.errors.required">
                        Pin Code required.
                      </div>

                    </div>
                  </div>




                  <!-- rangeValue.innerText = this.value"   -->




                </div>

                <div class="row" style="padding-top: 20px">

                  <!-- Network -->
                  <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                                                font: normal normal normal 12px/12px Archivo Variable;
                                                font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                                                letter-spacing: 0.3px;
                                                color: #F0F0F0;
                                                text-transform: uppercase
                                                ;color: #40444e  ;padding-bottom: 6px;opacity: 0.7;">
                      Network<span class="mandatory">*</span>
                    </span>



                    <ng-multiselect-dropdown [settings]="dropdownNetworkSettings" [data]="NetworkList"
                                             [(ngModel)]="selectedNetworkListItems" (onSelect)="onItemNetworkSelect($event)"
                                             (onSelectAll)="onNetworkSelectAll($event)" (onDeSelect)="onItemNetworkDeSelect($event)"
                                             (onDeSelectAll)="onUnNetworkSelectAll()" formControlName="selectNetworkList"
                                             [ngClass]="{ 'is-invalid': Screensubmitted && ScreenForm.controls.selectNetworkList.errors }" (click)="getNetworks()">
                    </ng-multiselect-dropdown>

                    <div *ngIf=" Screensubmitted && ScreenForm.controls.selectNetworkList.errors"
                         class="text-danger" style="margin-left: 0px;">
                      <div *ngIf=" Screensubmitted && ScreenForm.controls.selectNetworkList.errors.required">
                        Network required.
                      </div>

                    </div>
                  </div>


                  <!-- Seating Capacity -->
                  <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                       style="padding-top: 5px;">
                    <div class="row" style="    padding-left: 14px;padding-bottom: 4px;">
                      <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                            font: normal normal normal 12px/12px Archivo Variable;
                            font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                            letter-spacing: 0.3px;
                            color: #F0F0F0;
                            text-transform: uppercase;color: #40444e  ;opacity: 0.7;">Seating Capacity</span>
                      <!-- <span class="mandatory">*</span> -->
                    </div>

                    <fieldset class="focus"
                              style=" border-radius: 3px;padding-top: 9px;padding-bottom: 5PX;">

                      <label for="min"
                             style="padding-left: 6px;padding-right: 5px;font-size: 13px;">
                        Min:
                      </label>
                      <input type="number" class="minmaxcss" id="min" name="min" formControlName="MinCapacity"
                             style="width:35%;font-size: 12px;  color: #777;"
                             [(ngModel)]="MinCapacity" />

                      <label for="max"
                             style="padding-left: 9px;padding-right: 5px;font-size: 13px;">
                        Max:
                      </label>
                      <input type="number" class="minmaxcss" id="max" name="max" formControlName="MaxCapacity"
                             style="width:35%;font-size: 12px;  color: #777;"
                             [(ngModel)]="MaxCapacity" />
                    </fieldset>





                  </div>

                  <!-- ATP -->
                  <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                       style="padding-top: 5px;">
                    <div class="row" style="    padding-left: 14px;padding-bottom: 4px;">
                      <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                        font: normal normal normal 12px/12px Archivo Variable;
                        font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                        letter-spacing: 0.3px;
                        color: #F0F0F0;
                        text-transform: uppercase;color: #40444e  ;opacity: 0.7;">ATP</span>
                      <!-- <span class="mandatory">*</span> -->
                    </div>

                    <fieldset class="focus"
                              style="border-radius: 3px;padding-top: 9px;padding-bottom: 5PX;">

                      <label for="min"
                             style="padding-left: 6px;padding-right: 5px;font-size: 13px;">
                        Min:
                      </label>
                      <input type="number" class="minmaxcss" id="min" name="min" formControlName="MinATP"
                             style="width:35%;font-size: 12px;  color: #777;" [(ngModel)]="MinATP" />

                      <label for="max"
                             style="padding-left: 9px;padding-right: 5px;font-size: 13px;">
                        Max:
                      </label>
                      <input type="number" class="minmaxcss" id="max" name="max" formControlName="MaxATP"
                             style="width:35%;font-size: 12px;  color: #777;" [(ngModel)]="MaxATP" />
                    </fieldset>





                  </div>






                  <!-- Atp Based Screen Filters -->
                  <!-- <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                                      font: normal normal normal 12px/12px Archivo Variable;
                                      font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                                      letter-spacing: 0.3px;
                                      color: #F0F0F0;
                                      text-transform: uppercase
                                      ;color: #40444e  ;padding-bottom: 6px;opacity: 0.7;">Screen
                      Filters<span class="mandatory">*</span></span>



                  <div class="slider">
                      <input type="range" min="0" max="200" value="100" (change)="rangeChange($event)">
                      <p id="rangeValue" style="text-align: left;
                                              font: normal normal normal 18px/12px Archivo Variable;
                                              font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                                              letter-spacing: 0.3px;
                                              color: #F0F0F0;
                                              text-transform: uppercase
                                              ;color: #40444e;margin-right: 16px;margin-top: 13px;">{{Atp}}
                      </p>
                  </div> -->
                  <!-- <div *ngIf=" submitted && ScreenForm.controls.selectTiersList.errors" class="text-danger"
                      style="margin-left: 0px;">
                      <div *ngIf=" submitted && ScreenForm.controls.selectTiersList.errors.required">
                          Tiers required.
                      </div>

                  </div> -->
                  <!-- </div> -->


                </div>


                <!-- Screen Count  -->
                <div class="row" style="padding-top: 20px;padding-bottom: 25px;">
                  <!-- Tags -->
                  <div class="col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div class="row">
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
              font: normal normal normal 12px/12px Archivo Variable;
              font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
              letter-spacing: 0.3px;
              color: #F0F0F0;
              text-transform: uppercase
                                                     ;color: #40444e  ;opacity: 0.7;">Tags</span>
                        <!-- <span class="mandatory">*</span> -->

                        <div>
                          <input type="text" placeholder="Tags"
                                 class="form-control border-input" formControlName="Tags"
                                 [(ngModel)]="Tags" class="form-control border-input">
                        </div>
                        <!-- [ngClass]="{ 'is-invalid': Screensubmitted && ScreenForm.controls.Tags.errors }" -->
                        <!-- <div *ngIf=" Screensubmitted && ScreenForm.controls.Tags.errors" class="text-danger"
                            style="margin-left: 0px;">
                            <div *ngIf=" Screensubmitted && ScreenForm.controls.Tags.errors.required">
                                Tags required.
                            </div>
                        </div> -->
                      </div>

                    </div>
                  </div>

                  <!-- Screens -->
                  <!--<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                        font: normal normal normal 12px/12px Archivo Variable;
                        font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                        letter-spacing: 0.3px;
                        color: #F0F0F0;
                        text-transform: uppercase
                        ;color: #40444e  ;padding-bottom: 6px;opacity: 0.7;">
                      Screen
                    </span>



                    <ng-multiselect-dropdown [settings]="dropdownScreenSettings"
                                             [(ngModel)]="selectedScreenListItems" (onSelect)="onItemScreenSelect($event)"
                                             (onSelectAll)="onScreenSelectAll($event)"
                                             (onDeSelect)="onItemScreenDeSelect($event)"
                                             (onDeSelectAll)="onUnScreenSelectAll()" formControlName="selectScreenList"
                                             [ngClass]="{ 'is-invalid': Screensubmitted && ScreenForm.controls.selectScreenList.errors }"
                                             (click)="getScreens()" [disabled]="true">
                    </ng-multiselect-dropdown>

                    <div *ngIf=" Screensubmitted && ScreenForm.controls.selectScreenList.errors"
                         class="text-danger" style="margin-left: 0px;">
                      <div *ngIf=" Screensubmitted && ScreenForm.controls.selectScreenListme.errors.required">
                        Screen required.
                      </div>

                    </div>
                  </div>-->
                </div>

                <!--Accordion-->
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" (click)="ShowScreenDiv()">
                        Show Details
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" *ngIf="IsShowScreenDiv" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <div class="row" style="padding-top: 15px;">
                          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                     font: normal normal normal 12px/12px Archivo Variable;
                     font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                     letter-spacing: 0.3px;
                     color: #F0F0F0;
                     text-transform: uppercase
                     ;color: #40444e  ;padding-bottom: 6px;opacity: 0.7;">
                              Screen
                            </span>
                            <ng-multiselect-dropdown [settings]="dropdownScreenSettings"
                                                     [(ngModel)]="selectedScreenListItems" (onSelect)="onItemScreenSelect($event)"
                                                     (onSelectAll)="onScreenSelectAll($event)"
                                                     (onDeSelect)="onItemScreenDeSelect($event)"
                                                     (onDeSelectAll)="onUnScreenSelectAll()" formControlName="selectScreenList"
                                                     [ngClass]="{ 'is-invalid': Screensubmitted && ScreenForm.controls.selectScreenList.errors }"
                                                     (click)="getScreens()" [disabled]="true">
                            </ng-multiselect-dropdown>
                            <div *ngIf=" Screensubmitted && ScreenForm.controls.selectScreenList.errors"
                                 class="text-danger" style="margin-left: 0px;">
                              <div *ngIf=" Screensubmitted && ScreenForm.controls.selectScreenListme.errors.required">
                                Screen required.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" *ngIf="IsScreenCountScreenOpen">

                          <div class="row" style="margin-bottom: 4px;">
                            <div class="col-10 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                            </div>
                            <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"
                                 style="text-align: right;">
                              <img id="logo" src="../.../../../Scripts/assets/img/icons/refresh4.png" alt="logo"
                                   height='25' width='25' (click)="PlannerSelectedScreenCount()" />
                            </div>
                            <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"
                                 style="text-align: right;">
                              <img id="logo" src="../.../../../Scripts/assets/img/icons/ExcelExport.png" alt="logo"
                                   height='25' width='25' (click)="exportData()" />
                            </div>
                          </div>
                          <div class="row" style="padding-bottom: 30px">

                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <table style="width: 100%" class="items">
                                <!-- Header -->
                                <tr>
                                  <th class="ScreenCountcss"
                                      style="color:#fff">
                                    <span>Network</span>
                                  </th>
                                  <th class="ScreenCountcss"
                                      style="color:#fff;text-align: right;">
                                    <span>Screens</span>
                                  </th>
                                  <th class="ScreenCountcss"
                                      style="color:#fff;text-align: right;">
                                    <span>Weekly Shows/ Screen</span>
                                  </th>
                                  <th class="ScreenCountcss"
                                      style="color:#fff;text-align: right;">
                                    <span>Weekly Admits</span>
                                  </th>
                                  <th class="ScreenCountcss"
                                      style="color:#fff;text-align: right;">
                                    <span>Admits </span>
                                  </th>
                                  <th class="ScreenCountcss"
                                      style="color:#fff;text-align: right;">
                                    <span>Expected Budget</span>
                                  </th>
                                  <th class="ScreenCountcss"
                                      style="color:#fff;text-align: right;    padding-right: 15px;">
                                    <span>Expected CPC </span>
                                  </th>
                                </tr>
                                <tr *ngFor="let tbl of ScreenCountData">
                                  <td class="ScreenCountcss">
                                    {{tbl.Networks}}
                                  </td>
                                  <td class="ScreenCountcss" style="text-align: right;">
                                    {{tbl.Screens | number:'1.0-0'}}
                                  </td>
                                  <td class="ScreenCountcss" style="text-align: right;">
                                    {{tbl.Weekly_ShowsPer_Screen | number:'1.0-2'}}
                                  </td>
                                  <td class="ScreenCountcss" style="text-align: right;">
                                    {{tbl.Weekly_Admits | number:'1.0-0'}}
                                  </td>
                                  <td class="ScreenCountcss" style="text-align: right;">
                                    {{tbl.Admits_for_Period | number:'1.0-0'}}
                                  </td>
                                  <td class="ScreenCountcss" style="text-align: right;">
                                    {{tbl.Campaign_Budget | number:'1.0-0'}}
                                  </td>
                                  <td class="ScreenCountcss" style="text-align: right;    padding-right: 15px;">
                                    {{tbl.Campaign_CPC | number:'1.0-2'}}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                






                <div class="row" style="padding-top: 50px;padding-bottom: 28px;">

                  <!-- Submit Button -->
                  <div class="col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                    <div class="row">
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-2 col-xl-2">
                        <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
                                         border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="DraftScreenInfo()">
                          Save
                        </button>
                      </div>

                      <div class="col-4  col-xs-4 col-sm-4 col-md-4 col-lg-2 col-xl-2"
                           style="    margin-left: -44px;">
                        <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
      border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="goBack()">
                          Back
                        </button>
                      </div>

                      <div class="col-4  col-xs-4 col-sm-4 col-md-4 col-lg-2 col-xl-2"
                           style="    margin-left: -44px;">
                        <button id="ScreenNext" class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
          border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="onScreenSubmit()">
                          Next
                        </button>
                      </div>
                    </div>




                  </div>

                </div>


              </div>
            </form>



            <!-- Rate Form -->
            <!-- *ngIf="IsRatesStep" -->
            <div class="row" style="    padding-bottom: 39px;" *ngIf="IsRatesStepDiv">
              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <p style=" color: #7851d9;  font-weight: 500;  text-transform: uppercase;text-align: center;font-size: 16px;">
                  Rates
                </p>
              </div>
              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top: 39px;">

                <div class="row">
                  <!--<div class="col-12 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">

                  </div>-->
                  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <table>

                      <tr style="height: 30px;">

                        <th></th>
                        <th class="RateScreenHeader">Discounts (%)</th>
                        <th class="RateScreenHeader">Premiums (%)</th>
                        <th class="RateScreenHeader" style="text-align: center">
                          Rate Type
                        </th>
                        <th></th>

                      </tr>

                      <tr style="height: 40px;" *ngFor="let item of selectedNetworkListItems;">

                        <td style=" font: normal normal normal 14px/12px Archivo Variable;
                                            font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                                            letter-spacing: 0.3px;
                                            color: #F0F0F0;
                                            text-transform: uppercase
                                            ;color: #40444e  ;opacity: 0.7;">
                          <b>{{item.Networks}}</b>
                        </td>

                        <td>
                          <div class="quantity">
                            <a class="quantity__minus" (click)="DecrementValue(item)" style="cursor:pointer"><span style="color: #fff">-</span></a>
                            <input name="quantity" type="number" class="quantity__input" value="5" min="1"
                                   style="font-size: 13px;" [(ngModel)]="item.Discount" (input)="handleChange($event,item)">
                            <a class="quantity__plus" (click)="IncrementValue(item)" style="cursor:pointer"><span style="color: #fff">+</span></a>
                          </div>
                          <!-- <input type="text"  placeholder="Enter Discount (%)" style="font-size: 13px;"> -->
                        </td>

                        <td>
                          <div class="quantity">
                            <a class="quantity__minus" (click)="DecrementPrimiumValue(item)" style="cursor:pointer"><span style="color: #fff">-</span></a>
                            <input name="quantity" type="number" class="quantity__input" value="5" min="1"
                                   style="font-size: 13px;" [(ngModel)]="item.Premiums" (input)="handlePrimiumChange($event,item)">
                            <a class="quantity__plus" (click)="IncrementPrimiumValue(item)" style="cursor:pointer"><span style="color: #fff">+</span></a>
                          </div>
                          <!-- <input type="text"   placeholder="Enter Rates (%)" style="font-size: 13px;"> -->
                        </td>

                        <td style="cursor: pointer">

                          <!-- <input type="checkbox" id="switch" class="checkbox" />
                          <label for="switch" class="toggle">
                          </label> -->
                          <div class="row">

                            <div class="col-12 col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5"
                                 style="color: #40444e;font-size:13px;padding-top: 7px;">
                              Rack
                            </div>
                            <div class="col-12 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
                                 style="color: #40444e;font-size:13px;padding-top: 7px; text-align: center;">
                              <label class="switch" style="    margin-left: -30px;">
                                <input type="checkbox" checked [(ngModel)]="item.RateType">
                                <span class="sliderToggle round"></span>
                              </label>
                            </div>
                            <div class="col-12 col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5"
                                 style="color: #40444e;font-size:13px;padding-top: 7px;">
                              Flat

                            </div>

                          </div>

                        </td>
                        <td style="width: 12%;">
                          <input type="number" name="txtFlat" style="width:74%;opacity: 0.7;" value="" [(ngModel)]="item.Flat" [hidden]="!item.RateType" />
                        </td>


                      </tr>
                    </table>


                  </div>

                  <!--<div class="col-12 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">


                  </div>-->
                </div>
              </div>

              <div class="accordion" id="accordionExample" style="padding-top:25px">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" (click)="ShowScreenDiv()">
                      Show Details
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" *ngIf="IsShowScreenDiv" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="row" style="padding-top: 15px;">
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                          <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                     font: normal normal normal 12px/12px Archivo Variable;
                     font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                     letter-spacing: 0.3px;
                     color: #F0F0F0;
                     text-transform: uppercase
                     ;color: #40444e  ;padding-bottom: 6px;opacity: 0.7;">
                            Screen
                          </span>
                          <ng-multiselect-dropdown [settings]="dropdownScreenSettings"
                                                   [(ngModel)]="selectedScreenListItems" (onSelect)="onItemScreenSelect($event)"
                                                   (onSelectAll)="onScreenSelectAll($event)"
                                                   (onDeSelect)="onItemScreenDeSelect($event)"
                                                   (onDeSelectAll)="onUnScreenSelectAll()"                                                  
                                                   (click)="getScreens()" [disabled]="true">
                          </ng-multiselect-dropdown>
                          <!--<div *ngIf=" Screensubmitted && ScreenForm.controls.selectScreenList.errors"
                               class="text-danger" style="margin-left: 0px;">
                            <div *ngIf=" Screensubmitted && ScreenForm.controls.selectScreenListme.errors.required">
                              Screen required.
                            </div>
                          </div>-->
                        </div>
                      </div>
                      <div class="row" *ngIf="IsScreenCountScreenOpen">

                        <div class="row" style="margin-bottom: 4px;">
                          <div class="col-10 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                          </div>
                          <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"
                               style="text-align: right;">
                            <img id="logo" src="../.../../../Scripts/assets/img/icons/refresh4.png" alt="logo"
                                 height='25' width='25' (click)="PlannerSelectedScreenCount()" />
                          </div>
                          <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"
                               style="text-align: right;">
                            <img id="logo" src="../.../../../Scripts/assets/img/icons/ExcelExport.png" alt="logo"
                                 height='25' width='25' (click)="exportData()" />
                          </div>
                        </div>
                        <div class="row" style="padding-bottom: 30px">

                          <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <table style="width: 100%" class="items">
                              <!-- Header -->
                              <tr>
                                <th class="ScreenCountcss"
                                    style="color:#fff">
                                  <span>Network</span>
                                </th>
                                <th class="ScreenCountcss"
                                    style="color:#fff;text-align: right;">
                                  <span>Screens</span>
                                </th>
                                <th class="ScreenCountcss"
                                    style="color:#fff;text-align: right;">
                                  <span>Weekly Shows/ Screen</span>
                                </th>
                                <th class="ScreenCountcss"
                                    style="color:#fff;text-align: right;">
                                  <span>Weekly Admits</span>
                                </th>
                                <th class="ScreenCountcss"
                                    style="color:#fff;text-align: right;">
                                  <span>Admits </span>
                                </th>
                                <th class="ScreenCountcss"
                                    style="color:#fff;text-align: right;">
                                  <span>Expected Budget</span>
                                </th>
                                <th class="ScreenCountcss"
                                    style="color:#fff;text-align: right;    padding-right: 15px;">
                                  <span>Expected CPC </span>
                                </th>
                              </tr>
                              <tr *ngFor="let tbl of ScreenCountData">
                                <td class="ScreenCountcss">
                                  {{tbl.Networks}}
                                </td>
                                <td class="ScreenCountcss" style="text-align: right;">
                                  {{tbl.Screens | number:'1.0-0'}}
                                </td>
                                <td class="ScreenCountcss" style="text-align: right;">
                                  {{tbl.Weekly_ShowsPer_Screen | number:'1.0-2'}}
                                </td>
                                <td class="ScreenCountcss" style="text-align: right;">
                                  {{tbl.Weekly_Admits | number:'1.0-0'}}
                                </td>
                                <td class="ScreenCountcss" style="text-align: right;">
                                  {{tbl.Admits_for_Period | number:'1.0-0'}}
                                </td>
                                <td class="ScreenCountcss" style="text-align: right;">
                                  {{tbl.Campaign_Budget | number:'1.0-0'}}
                                </td>
                                <td class="ScreenCountcss" style="text-align: right;    padding-right: 15px;">
                                  {{tbl.Campaign_CPC | number:'1.0-2'}}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row" style="padding-top: 50px;padding-bottom: 25px;">

                  <!-- Submit Button -->
                  <div class="col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" style="    margin-left: 94px;">
                    <div class="row">
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-2 col-xl-2">
                        <button id="RateDraft" class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
                                     border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="DraftRateInfo()">
                          Save
                        </button>
                      </div>

                      <div class="col-4  col-xs-4 col-sm-4 col-md-4 col-lg-2 col-xl-2"
                           style="    margin-left: -44px;">
                        <button id="RateBack" class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
    border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="goBackRate()">
                          Back
                        </button>
                      </div>

                      <div class="col-4  col-xs-4 col-sm-4 col-md-4 col-lg-2 col-xl-2"
                           style="    margin-left: -44px;">
                        <button id="RateNext" class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
      border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="OnSubmitRateScreen()">
                          Next
                        </button>
                      </div>
                    </div>




                  </div>

                </div>
              </div>
            </div>





            <!-- Configuration Screen  -->
            <div class="row" style="padding-bottom: 35px;" *ngIf="IsConfigurationDiv">
              <!-- <div class="row" style="padding-bottom: 35px;" *ngIf="true"> -->
              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <p style=" color: #7851d9;  font-weight: 500;  text-transform: uppercase;text-align: center;font-size: 16px;">
                  Show Configuration
                </p>
              </div>

              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                   style="padding-top: 39px;">



                <div class="row">
                  <div class="col-12 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">

                  </div>
                  <div class="col-12 col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">

                    <table>

                      <tr style="height: 30px;">

                        <th style="width:20%"></th>
                        <th class="RateScreenHeader" style="    text-align: center;width:35%">
                          Weekly Show
                        </th>
                        <th class="RateScreenHeader" style="    text-align: center;width:45%">
                          Show
                          Time
                        </th>
                        <!-- <th class="RateScreenHeader" style="text-align: center">Rate Type
                        </th> -->

                      </tr>

                      <tr *ngFor="let time of ConfigList; let i = index"
                          style="height: 40px;">

                        <td style=" font: normal normal normal 14px/12px Archivo Variable;
                                            font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                                            letter-spacing: 0.3px;
                                            color: #F0F0F0;
                                            text-transform: uppercase
                                            ;color: #40444e  ;opacity: 0.7;">
                          <b> {{time.Networks}}</b>
                        </td>

                        <td>
                          <input type="number" class="configShowscss" id="shows" name="shows"
                                 style="font-size: 12px;  color: #777;    text-align: center;"
                                 [(ngModel)]="time.ConfShows" />

                          <!-- <input type="text" [(ngModel)]="time.ConfShows" placeholder="Enter show"
                          style="font-size: 13px;" > -->
                        </td>

                        <td>


                          <div>
                            <!-- <ng-multiselect-dropdown [placeholder]="'Select'" [data]="time.SlotData"
                              [(ngModel)]="time.selectedItems" [settings]="dropdownShowTimeSettings" name='countrySelect'>
                            </ng-multiselect-dropdown> -->

                            <ng-multiselect-dropdown [settings]="dropdownShowTimeSettings"
                                                     [data]="SlotData" [(ngModel)]="time.selectedItems"
                                                     id="ddl">
                            </ng-multiselect-dropdown>
                          </div>

                        </td>




                      </tr>




                    </table>

                  </div>

                  <div class="col-12 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">

                  </div>
                </div>

              </div>

              <div class="row" style="padding-top: 15px;">
                <!--<div class="col-12 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">

                </div>-->

                <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-bottom:10px">

                  <div class="accordion" id="accordionExample" style="padding-top:25px">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" (click)="ShowScreenDiv()">
                          Show Details
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" *ngIf="IsShowScreenDiv" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div class="row" style="padding-top: 15px;">
                            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                              <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                     font: normal normal normal 12px/12px Archivo Variable;
                     font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                     letter-spacing: 0.3px;
                     color: #F0F0F0;
                     text-transform: uppercase
                     ;color: #40444e  ;padding-bottom: 6px;opacity: 0.7;">
                                Screen
                              </span>
                              <ng-multiselect-dropdown [settings]="dropdownScreenSettings"
                                                       [(ngModel)]="selectedScreenListItems" (onSelect)="onItemScreenSelect($event)"
                                                       (onSelectAll)="onScreenSelectAll($event)"
                                                       (onDeSelect)="onItemScreenDeSelect($event)"
                                                       (onDeSelectAll)="onUnScreenSelectAll()"
                                                       
                                                       (click)="getScreens()" [disabled]="true">
                              </ng-multiselect-dropdown>
                              <!--<div *ngIf=" Screensubmitted && ScreenForm.controls.selectScreenList.errors"
                                   class="text-danger" style="margin-left: 0px;">
                                <div *ngIf=" Screensubmitted && ScreenForm.controls.selectScreenListme.errors.required">
                                  Screen required.
                                </div>
                              </div>-->
                            </div>
                          </div>
                          <div class="row" *ngIf="IsScreenCountScreenOpen">

                            <div class="row" style="margin-bottom: 4px;">
                              <div class="col-10 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                              </div>
                              <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"
                                   style="text-align: right;">
                                <img id="logo" src="../.../../../Scripts/assets/img/icons/refresh4.png" alt="logo"
                                     height='25' width='25' (click)="PlannerSelectedScreenCount()" />
                              </div>
                              <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"
                                   style="text-align: right;">
                                <img id="logo" src="../.../../../Scripts/assets/img/icons/ExcelExport.png" alt="logo"
                                     height='25' width='25' (click)="exportData()" />
                              </div>
                            </div>
                            <div class="row" style="padding-bottom: 30px">

                              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <table style="width: 100%" class="items">
                                  <!-- Header -->
                                  <tr>
                                    <th class="ScreenCountcss"
                                        style="color:#fff">
                                      <span>Network</span>
                                    </th>
                                    <th class="ScreenCountcss"
                                        style="color:#fff;text-align: right;">
                                      <span>Screens</span>
                                    </th>
                                    <th class="ScreenCountcss"
                                        style="color:#fff;text-align: right;">
                                      <span>Weekly Shows/ Screen</span>
                                    </th>
                                    <th class="ScreenCountcss"
                                        style="color:#fff;text-align: right;">
                                      <span>Weekly Admits</span>
                                    </th>
                                    <th class="ScreenCountcss"
                                        style="color:#fff;text-align: right;">
                                      <span>Admits </span>
                                    </th>
                                    <th class="ScreenCountcss"
                                        style="color:#fff;text-align: right;">
                                      <span>Expected Budget</span>
                                    </th>
                                    <th class="ScreenCountcss"
                                        style="color:#fff;text-align: right;    padding-right: 15px;">
                                      <span>Expected CPC </span>
                                    </th>
                                  </tr>
                                  <tr *ngFor="let tbl of ScreenCountData">
                                    <td class="ScreenCountcss">
                                      {{tbl.Networks}}
                                    </td>
                                    <td class="ScreenCountcss" style="text-align: right;">
                                      {{tbl.Screens | number:'1.0-0'}}
                                    </td>
                                    <td class="ScreenCountcss" style="text-align: right;">
                                      {{tbl.Weekly_ShowsPer_Screen | number:'1.0-2'}}
                                    </td>
                                    <td class="ScreenCountcss" style="text-align: right;">
                                      {{tbl.Weekly_Admits | number:'1.0-0'}}
                                    </td>
                                    <td class="ScreenCountcss" style="text-align: right;">
                                      {{tbl.Admits_for_Period | number:'1.0-0'}}
                                    </td>
                                    <td class="ScreenCountcss" style="text-align: right;">
                                      {{tbl.Campaign_Budget | number:'1.0-0'}}
                                    </td>
                                    <td class="ScreenCountcss" style="text-align: right;    padding-right: 15px;">
                                      {{tbl.Campaign_CPC | number:'1.0-2'}}
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--<div class="col-12 col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
                  
                </div>-->
                <!--<div class="col-12 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                </div>-->


              </div>






              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row" style="padding-top: 40px">

                  <div class="col-12 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">

                    

                  </div>
                  <!-- Submit Button -->
                  <div class="col-12 col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <div class="row">
                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-2 col-xl-2">
                        <button id="RateDraft" class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
                                     border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="OnSavePublishConfig()">
                          Save
                        </button>
                      </div>

                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-2 col-xl-2"
                           style="    margin-left: -44px;">
                        <button id="RateBack" class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
    border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="goBackfromConfiguration()">
                          Back
                        </button>
                      </div>

                      <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-2 col-xl-2"
                           style="    margin-left: -44px;">
                        <button id="RateNext" class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
      border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="OnPublishConfig(1)">
                          Publish
                        </button>
                      </div>
                    </div>




                  </div>

                  <div class="col-12 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">

                  </div>



                </div>
              </div>


            </div>





          </div>



        </div>

        <div class="row" *ngIf="IsScreenCountScreenOpenForConf">

          <!--*ngIf="IsScreenCountScreenOpen"-->
          <div class="row" style="margin-bottom: 4px;">
            <div class="col-10 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10" style="text-align:left">
              <img id="logo" src="../.../../../Scripts/assets/img/icons/circle-left-solid.svg" alt="logo"
                   height='25' width='25' style="margin-bottom:10px" (click)="BackConfigToList()" />
            </div>
            <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"
                 style="text-align: right;">
              <img id="logo" src="../.../../../Scripts/assets/img/icons/refresh4.png" alt="logo"
                   height='25' width='25' (click)="PlannerSelectedScreenCount()" />
            </div>
            <div class="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"
                 style="text-align: right;">
              <img id="logo" src="../.../../../Scripts/assets/img/icons/ExcelExport.png" alt="logo"
                   height='25' width='25' (click)="exportData()" />
            </div>
          </div>
          <div class="row" style="padding-bottom: 30px">
            <!-- <div class="x_panel_border" style="background-color: white;padding-top: 28px;margin-bottom: 100px;"> -->
            <!-- <div class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            </div> -->
            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <table style="width: 100%" class="items">
                <!-- Header -->
                <tr>


                  <th class="ScreenCountcss"
                      style="color:#fff">
                    <span>Network</span>
                  </th>

                  <th class="ScreenCountcss"
                      style="color:#fff;text-align: right;">
                    <span>Screens</span>
                  </th>

                  <th class="ScreenCountcss"
                      style="color:#fff;text-align: right;">
                    <span>Weekly Shows/ Screen</span>
                  </th>


                  <th class="ScreenCountcss"
                      style="color:#fff;text-align: right;">
                    <span>Weekly Admits</span>
                  </th>
                  <th class="ScreenCountcss"
                      style="color:#fff;text-align: right;">
                    <span>Admits</span>
                  </th>
                  <th class="ScreenCountcss"
                      style="color:#fff;text-align: right;">
                    <span>Expected Budget</span>
                  </th>
                  <th class="ScreenCountcss"
                      style="color:#fff;text-align: right;padding-right: 15px;">
                    <span>Expected CPC </span>

                  </th>




                </tr>


                <tr *ngFor="let tbl of ScreenCountData">

                  <td class="ScreenCountcss">
                    {{tbl.Networks}}
                  </td>
                  <td class="ScreenCountcss" style="text-align: right;">
                    {{tbl.Screens | number:'1.0-2'}}
                  </td>
                  <td class="ScreenCountcss" style="text-align: right;">
                    {{tbl.Weekly_ShowsPer_Screen | number:'1.0-2'}}
                  </td>
                  <td class="ScreenCountcss" style="text-align: right;">
                    {{tbl.Weekly_Admits | number:'1.0-2'}}
                  </td>
                  <td class="ScreenCountcss" style="text-align: right;">
                    {{tbl.Admits_for_Period | number:'1.0-2'}}
                  </td>
                  <td class="ScreenCountcss" style="text-align: right;">
                    {{tbl.Campaign_Budget | number:'1.0-2'}}
                  </td>
                  <td class="ScreenCountcss" style="text-align: right; padding-right: 15px;">
                    {{tbl.Campaign_CPC | number:'1.0-2'}}
                  </td>









                </tr>



              </table>
            </div>
            <!-- <div class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            </div> -->
            <!-- </div> -->
          </div>

        </div>



        <div class="row" *ngIf="CitypopUp">
          <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="x_panel_border" style="background-color: white;padding-top: 28px;margin-bottom: 100px;">
              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                   style=" margin-top: -21px;margin-bottom: 12px;  ">
                <img id="logo" src="../.../../../Scripts/assets/img/icons/circle-left-solid.svg" alt="logo" height='21' width='21'
                     (click)=BackToList() />
              </div>
              <!-- src="assets/img/icons/remove.png" -->
              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                   style="padding-top: 3px;padding-bottom: 22px;">
                <!-- Header -->
                <div class="row">
                  <!-- <div class="col-12 col-xs-12 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div> -->
                  <div class="row">
                    <div class="col-12 col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                      <div class="row">
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input type="checkbox" style="margin-left: 4px;padding-left: 2px;"
                                 (click)="SelectAllCityList($event)" [(ngModel)]="masterSelected">
                          <span style="margin-left: 3px;color: #532ABA;text-transform: uppercase;font-weight:500">
                            Available
                            Cities
                          </span>
                        </div>
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input type="text" id="inputNew" style="margin-left: 8px;padding-left: 2px;"
                                 placeholder="Search City" (input)="SearchAvailableCities($event)"
                                 [(ngModel)]="AvailableSearchText">
                        </div>
                      </div>
                      <div class="row">
                        <div style="height: 200px;overflow: auto;padding-top: 6px;">
                          <div *ngFor="let city of CityListAllData;let index = index"
                               [ngClass]="{'myStyle': 0 === index % 2}">
                            <input type="checkbox" [(ngModel)]="!city.IsSelected"
                                   style="margin-left: 8px;padding-left: 2px;"
                                   (change)="GetSelectedCitiesList($event,city)"><span id="spanCity">
                              {{city.CityWithState}}
                            </span>
                            <!-- [(ngModel)]="chkisSelected"  -->
                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <div class="row" style="padding-top: 55px; ">
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                             style="text-align: center;">

                          <img id="logo" src="../.../../../Scripts/assets/img/content/pngwing.com.png" alt="logo"
                               style="width:40%;-webkit-transform: scaleX(-1);cursor: pointer;"
                               (click)="AddDataTosSelectedList()" />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                             style="text-align: center;">
                          <img id="logo" src="../.../../../Scripts/assets/img/content/pngwing.com.png" alt="logo"
                               style="width:40%;cursor: pointer;"
                               (click)="RemoveDataTosSelectedList()" />
                        </div>
                      </div>

                    </div>

                    <div class="col-12 col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                      <div class="row">
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input type="checkbox" style="margin-left: 4px;padding-left: 2px;"
                                 (click)="SelectedAllCityList($event)" [(ngModel)]="masterCitySelected">
                          <span style="margin-left: 3px;color: #532ABA;text-transform: uppercase;font-weight:500;">
                            Selected
                            Cities
                          </span>
                        </div>
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input type="text" id="inputNew" style="margin-left: 8px;padding-left: 2px;"
                                 placeholder="Search City" (input)="SearchSelectedCities($event)"
                                 [(ngModel)]="selectedSearchText" />
                        </div>
                      </div>
                      <div class="row">
                        <div style="height: 200px;overflow: auto;padding-top: 6px">
                          <div *ngFor="let selectedcity of SelectedCities;let index = index"
                               [ngClass]="{'myStyle': 0 === index % 2}">
                            <input type="checkbox" [(ngModel)]="!selectedcity.IsSelected"
                                   style="margin-left: 8px;padding-left: 2px;"
                                   (change)="DeleteCitiesList($event,selectedcity)"><span id="spanCity">
                              {{selectedcity.CityWithState}}
                            </span>
                            <!-- [(ngModel)]="SelectedSearchText"  -->
                          </div>

                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-12 col-xs-12 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div> -->
                    <!-- New Code -->

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>




        <div class="row" *ngIf="PinCodepopUp">
          <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div style="background-color: white;margin-bottom: 100px;">
              <!-- Go Back -->
              <!-- <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style=" margin-top: -21px">

              </div> -->
              <!-- Body -->
              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                   style="padding-bottom: 22px;">

                <div class="row">
                  <!-- Left Panel -->

                  <div class="col-12 col-xs-12 col-sm-1 col-md-1 col-lg-1 col-xl-1" style="padding-top: 32px;">
                    <img id="logo" src="../.../../../Scripts/assets/img/icons/circle-left-solid.svg" alt="logo" height='21' width='21'
                         (click)=goBackPinCode() />
                  </div>

                  <div class="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8 col-xl-8"
                       style="padding-top: 30px;">
                    <div class="row">
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                           style="font-size: 15px;">

                        <span style="color: #532ABA;text-transform: uppercase;font-weight:500">
                          Select
                          Pin Code
                        </span>
                      </div>
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                           style="    padding-top: 2px;">
                        <textarea type="number" name="textarea" rows="8" cols="60"
                                  placeholder="Enter Pin Code" style="border-radius: 5px;
                            border: 2px solid #98abd7;" [(ngModel)]="PinCodeModel" (click)="GetTextAreaPinCodeValue()">&nbsp;</textarea>


                      </div>
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top: 2px;" *ngIf="IsPinCodeSubmit">
                        <button id="RateNext" class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
                                border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="SubmitPinCodes()">
                          Submit
                        </button>
                      </div>
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                           style="    padding-top: 2px;" *ngIf="IsInValidPinCodeGet">

                        <span style="color: #ed5765;text-transform: uppercase;font-weight:500">
                          Invalid
                          Pin Code
                        </span>

                      </div>
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                           style="    padding-top: 2px;" *ngIf="IsInValidPinCodeGet">

                        <textarea name="textarea" rows="8" cols="60" style="border-radius: 5px;
                                    border: 2px solid #fff;" [(ngModel)]="InvalidPinCode"></textarea>

                      </div>
                    </div>





                  </div>

                </div>

                <!-- <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                        <div class="col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="padding-top: 30px;"
                        *ngIf="IsInValidPinCode">
                        <div class="row">
                           <div class="col-12 col-xs-12 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                style="font-size: 15px;">
                                <span style="color: #ed5765;text-transform: uppercase;font-weight:500">Invalid
                                    Pin Code
                                </span>
                            </div>
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                style="    padding-top: 2px;">
                                <textarea name="textarea" rows="8" cols="40" style="border-radius: 5px;
                border: 2px solid #98abd7;" [(ngModel)]="InvalidPinCode"></textarea>
                                <br>

                            </div>
                        </div>



                    </div>
                   </div> -->
              </div>




            </div>
          </div>



        </div>

        <!--Screen pop up-->
        <div class="row" *ngIf="ScreenpopUp">
          <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="x_panel_border" style="background-color: white;padding-top: 28px;margin-bottom: 100px;">
              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                   style=" margin-top: -21px;margin-bottom: 12px;  ">
                <img id="logo" src="../.../../../Scripts/assets/img/icons/circle-left-solid.svg" alt="logo" height='21' width='21'
                     (click)=BackToScreenList() />
              </div>
              <!-- src="assets/img/icons/remove.png" -->
              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                   style="padding-top: 3px;padding-bottom: 22px;">
                <!-- Header -->
                <div class="row">
                  <!-- <div class="col-12 col-xs-12 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div> -->
                  <div class="row">
                    <div class="col-12 col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                      <div class="row">
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input type="checkbox" style="margin-left: 4px;padding-left: 2px;"
                                 (click)="SelectAllScreenList($event)" [(ngModel)]="ScreenmasterSelected">
                          <span style="margin-left: 3px;color: #532ABA;text-transform: uppercase;font-weight:500">
                            Available
                            Screens
                          </span>
                        </div>
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input type="text" id="inputNew" style="margin-left: 8px;padding-left: 2px;"
                                 placeholder="Search Screen" (input)="SearchAvailableScreens($event)"
                                 [(ngModel)]="AvailableSearchTextScreen">
                        </div>
                      </div>
                      <div class="row">
                        <div style="height: 200px;overflow: auto;padding-top: 6px;">
                          <div *ngFor="let Screen of ScreenListAllData;let index = index"
                               [ngClass]="{'myStyle': 0 === index % 2}">
                            <input type="checkbox" [(ngModel)]="!Screen.IsSelected"
                                   style="margin-left: 8px;padding-left: 2px;"
                                   (change)="GetSelectedScreensList($event,Screen)"><span id="spanCity">
                              {{Screen.ScreenNameWithProperty}}
                            </span>
                            <!-- [(ngModel)]="chkisSelected"  -->
                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <div class="row" style="padding-top: 55px; ">
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                             style="text-align: center;">

                          <img id="logo" src="../.../../../Scripts/assets/img/content/pngwing.com.png" alt="logo"
                               style="width:40%;-webkit-transform: scaleX(-1);cursor: pointer;"
                               (click)="AddDataTosSelectedScreenList()" />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                             style="text-align: center;">
                          <img id="logo" src="../.../../../Scripts/assets/img/content/pngwing.com.png" alt="logo"
                               style="width:40%;cursor: pointer;"
                               (click)="RemoveDataTosSelectedScreenList()" />
                        </div>
                      </div>

                    </div>

                    <div class="col-12 col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                      <div class="row">
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input type="checkbox" style="margin-left: 4px;padding-left: 2px;"
                                 (click)="SelectedAllScreenList($event)" [(ngModel)]="masterScreenSelected">
                          <span style="margin-left: 3px;color: #532ABA;text-transform: uppercase;font-weight:500;">
                            Selected
                            Screens
                          </span>
                        </div>
                        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input type="text" id="inputNew" style="margin-left: 8px;padding-left: 2px;"
                                 placeholder="Search Screen" (input)="SearchSelectedCities($event)"
                                 [(ngModel)]="selectedSearchText">
                        </div>
                      </div>
                      <div class="row">
                        <div style="height: 200px;overflow: auto;padding-top: 6px">
                          <div *ngFor="let selectedscreen of SelectedScreens;let index = index"
                               [ngClass]="{'myStyle': 0 === index % 2}">
                            <input type="checkbox" [(ngModel)]="!selectedscreen.IsSelected"
                                   style="margin-left: 8px;padding-left: 2px;"
                                   (change)="DeleteScreensList($event,selectedscreen)"><span id="spanCity">
                              {{selectedscreen.ScreenNameWithProperty}}
                            </span>
                            <!-- [(ngModel)]="SelectedSearchText"  -->
                          </div>

                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-12 col-xs-12 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div> -->
                    <!-- New Code -->

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <!--**************************************************-->

      </div>

    </div>
  </div>
</div>


<!--Create button-->
<div *ngIf="this.global.IsLoggedIn =='true' && this.IsCreate == false" class="container" style="padding-top:50px">
  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div class="row" style="padding-top:20px;padding-bottom: 10px;align-content:center">
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"></div>
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"></div>
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="text-align:right">
        <button class="btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;border: 1px solid #532ABA;opacity: 1;height: 40px; width: 40%;" (click)="GoToPlannerForm()">
          Create Plan
        </button>
      </div>

    </div>
  </div>

  <div *ngIf="this.IsPlannerCampainPresent == true" class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom: 100px;overflow-x:auto">
    <table style="width: 100%">
      <!-- Header -->
      <tr>


        <!--<th class="fontTextSubmit" style="color:white;padding-left: 10px;">Planner Master Id</th>-->
        <th class="fontTextSubmit" style="color:white;padding-left: 10px;">Client Name</th>
        <th class="fontTextSubmit" style="color:white;padding-left: 10px;">Brand</th>
        <!--<th class="fontTextSubmit" style="color:white;padding-left: 10px;">Duration (Sec)</th>-->
        <th class="fontTextSubmit" style="color:white;padding-left: 10px;">Start Date</th>
        <th class="fontTextSubmit" style="color:white;padding-left: 10px;">End Date</th>
        <!--<th class="fontTextSubmit" style="color:white;padding-left: 10px;">Campanign Details</th>-->
        <th class="fontTextSubmit" style="color:white;padding-left: 10px;"></th>
      </tr>

      <tr *ngFor="let tbl of ViewPlannerCamapinList">

        <!--<td class="fontTextSubmit">{{tbl.PlannerMasterId}}</td>-->
        <td class="fontTextSubmit">{{tbl.ClientName}}</td>
        <td class="fontTextSubmit">{{tbl.Brands}}</td>
        <!--<td class="fontTextSubmit">{{tbl.Duration}}</td>-->
        <td class="fontTextSubmit">{{tbl.StartDate | date: 'dd-MMM-yyyy'}}</td>
        <td class="fontTextSubmit">{{tbl.EndDate | date: 'dd-MMM-yyyy'}}</td>
        <!--<td class="fontTextSubmit">{{tbl.Campanign_Details}}</td>-->
        <td class="fontTextSubmit">
          <img id="logo" src="../.../../../Scripts/assets/img/icons/view_icon.svg" alt="logo"
               height='25' width='25' (click)="btnView(tbl.PlannerMasterId)" />

          <img id="logo" src="../.../../../Scripts/assets/img/icons/delete_icon.svg" alt="logo"
               height='25' width='25' (click)="btnDelete(tbl.PlannerMasterId)" />


        </td>
        

       
      </tr>
      

     
    </table>
  </div>

</div>




