<div class="loadingGif" *ngIf="isShowLoader"></div>


<!-- Old login page  -->
<div class="loadingGif" *ngIf="isShowLoader"></div>


<div class="row" style="padding-top:120px;    padding-bottom: 71px;">

  <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
    <h6 class></h6>
  </div>



  <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 loginbg" [style.height.px]="myInnerHeight" style=" border-radius: 11px; 
    border-color: #fafafa;
     
    background: #fafafa;">
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">

      <!-- Login With Username Pass Section -->
      <div class="container-fluid" style="padding-top: 16px;" *ngIf="IsForgotPass">
        <div class="row"><span style="text-align: center;
                font: normal normal normal 14px/32px Archivo Variable;
                font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: uppercase;
                opacity: 1;color: #8a8b8e;">LOG INTO YOUR ACCOUNT</span></div>
        <div class="row" style="padding-top: 10px; width: 100%;opacity: 0.2;">
          <hr>
        </div>

        <div class="row" style="padding-top: 10px;text-align: left;opacity: 0.7;">
          <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                    font: normal normal normal 12px/12px Archivo Variable;
                    font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                    letter-spacing: 0.3px;
                    color: #F0F0F0;
                    text-transform: uppercase
                    ;color: #8a8b8e;">UserName</span>
        </div>
        <div class="row form-group" style="padding-top: 5px;">
          <input type="text" formControlName="email" class="form-control" [(ngModel)]="UserName" />
          <!-- <div *ngIf="submitted && f['UserName'].errors" class="invalid-feedback">
            <div *ngIf="f['UserName'].errors['required']">Email is required</div>
            <div *ngIf="f['UserName'].errors['UserName']">Email must be a valid email address</div> -->


        </div>

        <div class="row" style="padding-top: 10px;text-align: left;opacity: 0.7;">
          <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                    font: normal normal normal 12px/12px Archivo Variable;
                    font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                    letter-spacing: 0.3px;
                    color: #F0F0F0;
                    text-transform: uppercase
                    ;color: #8a8b8e;">Password</span>
        </div>
        <div class="row form-group" style="padding-top: 5px;">
          <input type="password" formControlName="password" class="form-control" [(ngModel)]="UsrPassword" />
          <!-- <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
            <div *ngIf="f['password'].errors['required']">password is required</div> -->

        </div>



        <div class="row" style="padding-top: 11px">
          <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
                            border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="Login()">
            Login
          </button>
        </div>

        <!-- <div style="font-size: 14px;
          width: 100%;
          padding: 10px 0;
          border-radius: 0 0 4px 4px;">
          <p>Not a member? <a (click)="GoforRegistration()" style="text-decoration:underline;color: #532ABA;">Create
              Account</a>
          <p>
        </div> -->

        <div class="row">
          <div class="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
            <img src="./../../../Scripts/assets/img/content/forgot-password.png" style="width: 50%;margin-top: 13px;" />
          </div>
          <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="margin-left: -23px;
          font-size: 12px;
          padding-top: 15px;
         
          font-weight: 600;cursor: pointer">
            <p style="margin-left: -19px;"> <a (click)="GoforForgotPass()"
                style="text-decoration:underline;color: #532ABA;">Forgot password?</a>
          </div>
          <!--<div class="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
            <img src="./../../../Scripts/assets/img/content/new-user.png" style="width: 50%;margin-top: 13px;margin-left: 88px;" />

          </div>-->
          <!--<div class="col-4  col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style=" 
          font-size: 12px;
          padding-top: 15px;
          
          font-weight: 600;cursor: pointer">
            <p style="margin-left: 49px;"> <a (click)="GoforRegistration()"
                style="text-decoration:underline;color: #532ABA;">Sign Up</a>
            <p>
          </div>-->
        </div>
      </div>

      <!-- Forgot password Section -->
      <div class="container-fluid" style="padding-top: 16px;" *ngIf="IsForgotPass == false && OtpVerfy">
        <div class="row"><span style="text-align: center;
                font: normal normal normal 14px/32px Archivo Variable;
                font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: uppercase;
                opacity: 1;color: #8a8b8e;">Reset password</span></div>
        <div class="row" style="padding-top: 10px; width: 100%;opacity: 0.2;">
          <hr>
        </div>

        <div class="row" style="padding-top: 10px;text-align: left;opacity: 0.7;">
          <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                    font: normal normal normal 12px/12px Archivo Variable;
                    font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                    letter-spacing: 0.3px;
                    color: #F0F0F0;
                    text-transform: uppercase
                    ;color: #8a8b8e;">Email</span>
        </div>
        <div class="row form-group" style="padding-top: 5px;">
          <input type="text" formControlName="email" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" [(ngModel)]="UsrEmailId" />
          <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
            <div *ngIf="f['email'].errors['required']">Email is required</div>
            <div *ngIf="f['email'].errors['email']">Email must be a valid email address</div>
          </div>

        </div>

        <div class="row" style="padding-top: 11px">
          <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
                            border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="OtpVerfication()">
            NEXT
          </button>
        </div>


      </div>






    </form>

    <!-- Otp Section -->
    <div class="container-fluid" style="padding-top: 16px;" *ngIf="OtfDet">

      <div class="row">
        <span style="text-align: center;
                                        font: bold normal normal 14px/32px Archivo Variable;
                                        font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                                        letter-spacing: 0px;
                                        color: #8a8b8e;
                                       
                                        opacity: 1;">OTP Verification</span>
      </div>
      <div class="row" style="padding-top:0px;padding-left: 36px;">
        <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                    font: normal normal normal 13px/12px Archivo Variable;
                    font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                    letter-spacing: 0.3px;
                    color: #8a8b8e;
                     
                    opacity: 0.75;">
          Enter the otp sent to your email.
        </span>
      </div>
      <div class="row" style="padding-top: 17px;opacity: 0.2">
        <hr>
      </div>

      <div class="row" style="padding-top: 17px;">



        <input type="text" #txt1 maxlength="1" (keyup)="move($event,'',txt1,txt2)"
          style="width:13%; height:34px ; text-align: center;margin-left: 28px" class=" s2"
          (keypress)="noallowed($event)" [(ngModel)]="opt1">
        <input type="text" #txt2 maxlength="1" (keyup)="move($event,txt1,txt2,txt3)"
          style="width: 13%; height:34px ;text-align: center; margin-left: 28px;" class=" s2"
          (keypress)="noallowed($event)" [(ngModel)]="opt2">
        <input type="text" #txt3 maxlength="1" (keyup)="move($event,txt2,txt3,txt4) "
          style="width: 13%;  height:34px ;text-align: center; margin-left: 28px;" class=" s2"
          (keypress)="noallowed($event)" [(ngModel)]="opt3">
        <input type="text" #txt4 maxlength="1" (keyup)="move($event,txt3,txt4,'')"
          style="width: 13%; height:34px ;text-align: center; margin-left: 28px;" class=" s2"
          (keypress)="noallowed($event)" [(ngModel)]="opt4">






      </div>

      <div class="row" style="padding-top:25px">
        <span style=" font-size: 6px; color:white;text-align: center;
                        font: normal normal normal 14px/12px Archivo Variable;
                        font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                        letter-spacing: 0.3px;
                        color: #F0F0F0;
                         
                        opacity: 0.6;">Didn't receive OTP?</span>

      </div>
      <div class="row" style="padding-top:9px">
        <a style=" font-size: 6px; color:white;text-align: center;
                               font: bold normal normal 11px/12px Archivo Variable;
                               font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                               letter-spacing: 0.3px;
                               color: #00BEFF;
                               text-transform: uppercase;
                               opacity: 0.75;font-weight:900px;text-decoration: underline;opacity: 1;letter-spacing: -0.18px;"
          (click)="ResendOTP()">RESEND OTP</a>
      </div>
      <div class="row" style="padding-top:15px;    padding-right: 15px;">

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 " style="text-align:center">

          <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
                                border: 1px solid #532ABA;opacity: 1;height: 34px;    width: 107%;"
            (click)="GetStartedPage()">
            Verify OTP
          </button>

        </div>
      </div>

    </div>


    <!-- New Pass Generate Section -->
    <div   class="container-fluid" style="padding-top: 16px;" *ngIf="IsNewPassGenerate">
 


      <div class="row"><span style="text-align: center;
        font: normal normal normal 14px/32px Archivo Variable;
        font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
        letter-spacing: 0px;
        color: #FFFFFF;
        text-transform: uppercase;
        opacity: 1;color: #8a8b8e;">Create new password</span></div>
      <div class="row" style="padding-top: 10px; width: 100%;opacity: 0.2;">
        <hr>
      </div>





      <div class="row" style="padding-top: 10px;text-align: left;opacity: 0.7;">
        <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                  font: normal normal normal 12px/12px Archivo Variable;
                  font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                  letter-spacing: 0.3px;
                  color: #F0F0F0;
                  text-transform: uppercase
                  ;color: #8a8b8e;">Password</span>
      </div>
      <div class="row form-group" style="padding-top: 5px;">
        <input type="password" name="Newpassword" class="form-control" [(ngModel)]="Newpassword" />

      </div>

      <!-- CnfPass -->


      <div class="row" style="padding-top: 10px;text-align: left;opacity: 0.7;">
        <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                    font: normal normal normal 12px/12px Archivo Variable;
                    font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                    letter-spacing: 0.3px;
                    color: #F0F0F0;
                    text-transform: uppercase
                    ;color: #8a8b8e;">Confirm Password</span>
      </div>
      <div class="row form-group" style="padding-top: 5px;">
        <input type="text" name="Newcnfpassword" class="form-control" [(ngModel)]="Newcnfpassword" />



      </div>

   

      <div class="row" style="padding-top: 11px">
        <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
                          border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="ResetPassword()">
          Reset Password
        </button>
      </div>




     
  </div>





</div>


<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">


</div>
</div>
