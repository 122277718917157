import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

@Injectable()
 export class SharedService {
  public api_url: string
  public url: string //= AppConfig.settings.apiServer.url;
  public env: string //= AppConfig.settings.env.name;
  public aurl: string //= AppConfig.settings.apiServer.aurl;
  public MovieROReportUrl: string //= AppConfig.settings.apiServer.stock_url;
  public stock_url: string //= AppConfig.settings.apiServer.stock_url;
  public Intranet_url: string //= AppConfig.settings.apiServer.intra_url;
  public stock_url_Lamp: string //= AppConfig.settings.apiServer.stock_url;
  public DefaultAgency: string = '35';

  constructor() { }

  getAPIurl() {
    return this.api_url;
  }

  setAPIurl(val: string) {
    this.api_url = val;
  }

  getURL() {
    return this.url;
  }

  setURL(val: string) {
    this.url = val;
  }

  getENV() {
    return this.env;
  }

  setENV(val: string) {
    this.env = val;
  }
}
