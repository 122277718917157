import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';

import { throwError as observableThrowError, Observable, from } from 'rxjs';
import {Global} from '../global/global'


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json ;charset=utf-8',
        'accept': 'application/json'
    })
};
@Injectable()
export class RegistrationPageService {


    constructor(private _http: HttpClient, private global: Global) { }

    getRegistrationAPIData(array: any): Observable<any> {
        debugger

        const body = {
 
            FirstName: array.Rfirstname,
            LastName: array.Rlastname,
            emailid: array.Remail,
            Phone: array.Rphone,
            CompanyName: array.Rcompanyname,
            Password:array.Rpassword
            
          };
          
 
        return this._http.post(this.global.baseUrl + 'api/Registration/UserRegistration', body, httpOptions).pipe(
            map((response:any) => {
                debugger
                 return response
            })
        )
  }

  GetEditProfileDetails(userId): Observable<any> {
    debugger;
    return this._http.get(this.global.baseUrl + '/api/Registration/SelectRegistration?User_id=' + userId).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));

  }

  GetDisableProfileDetails(userId): Observable<any> {
    debugger;
    return this._http.get(this.global.baseUrl + '/api/Registration/DisableAccount?Userid=' + userId).pipe(
      map((response: Response) => <any>response),
      catchError(this._errorHandler));

  }



    _errorHandler(error: Response) {
        debugger

        console.log(error);
        return observableThrowError(() => error || "Internal server error");
    }









}
