import { Component, OnInit } from '@angular/core';
import { Global } from '../global/global'
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-plannerreport2',
  templateUrl: './plannerreport2.component.html',
  styleUrls: ['./plannerreport2.component.css']
})
export class Plannerreport2Component implements OnInit {

  Planner2URL: string;
  Login: string;

  constructor(public global: Global) { }

  ngOnInit(): void {
   debugger
    this.global.IsLoggedIn = sessionStorage.getItem('IsLoggedIn');

    if (this.global.IsLoggedIn == null) {
      this.global.IsLoggedIn = 'false';
    }
    this.Login = this.global.IsLoggedIn;
    this.global.IsOnMenu = false
    this.global.IsOnApproachMenu = false
    this.global.IsOnPlannerMenu = false
    this.global.IsOnPostEvalMenu = false
    this.global.IsOnContactUsMenu = false
    this.global.IsOnStartedMenu = false
    this.global.IsCinemaStats = false
    this.global.IsOnPlanner2Menu = true

    if (this.global.IsLoggedIn == 'true') {
      this.Planner2URL = this.global.EmbededURL + "/Planner2Report";
      this.Planner2URL = this.Planner2URL.trim();
    }
    else {
      window.location.href = "./Planner"
    }
  }

  GoToLoginFromPlanner() {
    debugger
    localStorage.setItem('IsPlannerLogin', "false");
    localStorage.setItem('IsPostEvalLogin', "false"); 
    localStorage.setItem('IsPlanner2Login', "true");

    window.location.href = "./Login"
  }

}
