import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginPagesService } from './LoginPagesService'
import { Global } from '../global/global'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { strict } from 'assert';
import { Local } from 'protractor/built/driverProviders';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  registerForm: FormGroup;
  // registerLoginForm:FormGroup
  submitted = false;
  IsForgotPass: boolean = true
  IsNewPassGenerate: boolean = false


  OtpVerfy: boolean = false;
  OtfDet: boolean = false
  UsrEmailId: string
  FullOtp: number
  opt1: number
  opt2: number
  opt3: number
  opt4: number
  isShowLoader: boolean = false
  IsResendOTP: number
  UsrPassword: string
  UserName: string
  Newcnfpassword: string
  Newpassword: string
  myInnerHeight: any = 346;
  ipAddress: string;



  constructor(private http: HttpClient, public global: Global, private router: Router, private loginpageservice: LoginPagesService, private formBuilder: FormBuilder) {
    window.sessionStorage.clear();
    this.ipAddress = '';

  }


  GoforRegistration() {
    debugger



    window.location.href = "./Registration"
  }

  GetVerifiyEmailOtp() {
    debugger
    this.isShowLoader = true;

    this.loginpageservice.GetVerifiyEmailOtp(this.UsrEmailId.trim(), this.FullOtp).subscribe(
      data => {
        debugger
        // this.theaterList = [];
        if (data[0].EmailID != 'Invalid OTP') {
          debugger
          // window.sessionStorage.clear();
          // sessionStorage.setItem('SMasterId', data[0].MasterId);
          // sessionStorage.setItem('SUserType', data[0].UserTypeId);
          this.isShowLoader = false;
          // this.global.IsLoggedIn = 'true';
          // sessionStorage.setItem('IsLoggedIn', String(this.global.IsLoggedIn));

          // window.location.href = "./PostEval"

          this.OtfDet = false
          this.IsNewPassGenerate = true
        }
        else {
          this.isShowLoader = false;
          alert("please check OTP you entered");

          return
        }
      });
  }

  GetStartedPage() {
    debugger
    this.FullOtp = this.opt1 + this.opt2 + this.opt3 + this.opt4
    this.GetVerifiyEmailOtp();


  }

  move(e: any, p: any, c: any, n: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute('maxlength');
    if (length == maxlength) {
      if (n != "") {
        n.focus();
      }
    }
    if (e.key === "Backspace") {
      if (p != "") {
        p.focus();
      }
    }

  }

  GetLoginOtp() {
    debugger
    // this.OtpVerfy = !this.OtpVerfy;
    // this.OtfDet = !this.OtfDet;

    debugger
    this.isShowLoader = true;
    this.loginpageservice.getLoginPageOtpData(this.UsrEmailId.trim()).subscribe(
      data => {
        debugger
        // this.theaterList = [];
        if (data == 'success') {
          debugger
          // this.theaterList = data.states;
          // console.log(this.theaterList );
          if (this.IsResendOTP != 1) {
            this.OtpVerfy = !this.OtpVerfy;
            this.OtfDet = !this.OtfDet;
          }
          else {
            alert("Otp send successfully.");
          }
          this.isShowLoader = false;
        }
        else {
          this.isShowLoader = false;
          alert("please check email you entered");

          return
        }
      });
  }

  ResendOTP() {
    debugger
    this.IsResendOTP = 1;
    this.GetLoginOtp();
  }



  OtpVerfication() {
    debugger
    this.myInnerHeight =346
    if (this.UsrEmailId == undefined) {
      alert("Please enter the emailId to login")

    }
    else {
      // this.OtpVerfy=!this.OtpVerfy;
      // this.OtfDet=!this.OtfDet;
      this.GetLoginOtp();
    }
  }


  noallowed(event: any): boolean {
    const charcode = (event.which) ? event.which : event.keyCode;
    if (charcode > 31 && (charcode < 48 || charcode > 57)) {
      console.log('charcode restricted is' + charcode);
      return false;
    }
    return true;
  }
  // UserMgt
  ngOnInit(): void {
    debugger
    this.global.IsPlannerLogin = localStorage.getItem('IsPlannerLogin');
    this.global.IsPostEvalLogin = localStorage.getItem('IsPostEvalLogin');

    this.global.IsLoggedIn = sessionStorage.getItem('IsLoggedIn');
    console.log(this.global.IsLoggedIn)
    if (this.global.IsLoggedIn == null) {
      this.global.IsLoggedIn = 'false';
    }
    this.global.IsOnMenu = false
    this.global.IsOnApproachMenu = false
    this.global.IsOnPlannerMenu = false
    this.global.IsOnPostEvalMenu = false
    this.global.IsOnContactUsMenu = false
    this.global.IsOnStartedMenu = true
    this.global.IsCinemaStats = false

    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

  }

  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value))
  }

  GotoStartedPage() {
    window.location.href = './Login'
  }

  // UserMgt
  LogoutApp() {
    localStorage.clear();
    this.global.IsLoggedIn = 'false';
    sessionStorage.setItem('IsLoggedIn', String(this.global.IsLoggedIn));
    window.location.href = "./Login"
   

  }
  GoToPostEval() {
    window.location.href = "./PostEval"
  }

  GotoPlanner() {
    window.location.href = "./Planner"
  }

  Login() {

    if (this.UserName && this.UsrPassword) {
      debugger

      this.loginpageservice.getLoginPageData(this.UserName.trim(), this.UsrPassword.trim()).subscribe(
        data => {

          debugger
         
          // this.theaterList = [];
          if (data[0].EmailID == 'Please enter a valid email id') {
            debugger
            alert(data[0].EmailID);
            


          }
          else if (data[0].EmailID == 'Please enter a valid password and try again') {
            alert(data[0].EmailID);
          }
          else {
            localStorage.setItem("EditProfileDetails", JSON.stringify(data))
            sessionStorage.setItem('IsUserId', String(data[0].UserId));
            this.global.IsLoggedIn = 'true'; 
            sessionStorage.setItem('IsLoggedIn', String(this.global.IsLoggedIn));
            localStorage.setItem('UserType', String(data[0].UserType));
            //sessionStorage.setItem('FullName', String(data[0].FullName))
            //sessionStorage.setItem('Email', String(data[0].EmailID))
            //sessionStorage.setItem('Phone', String(data[0].Phone))            
            //sessionStorage.setItem('FirstName', String(data[0].FirstName))
            


            if (this.global.IsPlannerLogin == "true") {
              window.location.href = "./Planner"
            }
            else if (this.global.IsPostEvalLogin == "true") {
              window.location.href = "./PostEval"
            }
            else if (this.global.IsCineStatsLogin == "true")
            {
              window.location.href = "./CinemaStats"
            }
            else {
              window.location.href = "./"
            }

          }
        });
    }
    else {
      alert("Please enter Username and password")
    }


  }

  GoforForgotPass() {
    debugger
    this.myInnerHeight = 258;
    this.IsForgotPass = false
    this.OtpVerfy = true
  }


  ResetPassword() {
    debugger
    if (this.Newpassword == this.Newcnfpassword) {
      debugger

      var url = this.global.baseUrl + 'api/Login/ForgotPassword?Email_ID=' + this.UsrEmailId + '&Password=' + this.Newpassword;
      this.http.get<any>(url).subscribe(data => {
        debugger
        if (data == 'success') {
          alert("Reset password successfully");
          window.location.href = "./Login"
        }
      }, (error) => {
        debugger

        console.log("Error.error is", error.error);

      });

      // const body = { EmailID: this.UsrEmailId, Password: this.Newpassword };
      /// const body = { screenID: '175' ,showDate : this.datepipe.transform( this.createCampaignModel.startDate, 'yyyy-MM-dd')};
      //this.http.get<any>(this.global.baseUrl + 'api/Login/ForgotPassword?EmailID=' + this.UsrEmailId + '&Password=' + this.Newpassword, {}).subscribe(data => {
      //  debugger
      //  if (data == 'success') {
      //    alert("Reset password successfully");
      //    window.location.href = "./Login"
      //  }
      //}, (error) => {
      //  debugger

      //  console.log("Error.error is", error.error);

      //});
      // this.loginpageservice.SetNewPassword(this.UsrEmailId.trim(), this.Newpassword.trim()).subscribe(
      //   data => {
      //     debugger
      //     if (data == "success") {
      //       alert("Password reset successfully")
      //       window.location.href = "./Login"
      //     }
      //     // this.theaterList = [];

      //   });
    }
    else {
      alert("Password and Confirm Password should be same.")
    }
  }




}
