<style>
  input {
    width: 100%;
    padding: 8px 2px;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    /* width: 31%; */
    border: 2px solid #c9c1c1;
    border-radius: 10px 5% / 20px 30px;
  }

  th {
    background-color: #866ACF;
    border: 1px solid #866ACF;
  }

  td {
    /* border: 1px solid #dddddd; */
  }

  td,
  th {
    text-align: left;
    padding: 5px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }

  input.chck {
    width: 16px;
    height: 27px;
  }


  .multiselect-dropdown {
    border: 1px solid #422490 !important;
    background-color: #422490 !important;
  }

  .mandatory {
    color: #dc3545 !important;
    font-weight: 800;
    font-size: 15px;
  }

  p,
  li {
    text-align: justify;
  }
</style>
<!-- IF user not login -->

<div class="loadingGif" *ngIf="isShowLoader"></div>


<div class="message"  *ngIf="IsMessage">
  <div class="row" style="padding-top: 21px;
    padding-left: 40px;">
    <span style=" color: #40444e;
      font-weight: 400;">
      Do you want to delete this campaign?
    </span>
  </div>
  <div class="row" style="padding-top: 30px;">
    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="text-align: right">
      <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
            border: 1px solid #532ABA;opacity: 1;height: 34px;width:85px" (click)=" Delete()">
        Yes
      </button>
    </div>

    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
      <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
            border: 1px solid #532ABA;opacity: 1;height: 34px;width:85px" (click)="DeleteNo()">
        No
      </button>
    </div>

  </div>
</div>


<div class="container" style="padding-top:50px" *ngIf="this.global.IsLoggedIn =='false'">

  <div class="row" style="    margin-top: 21px;">
    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <img src="../.../../../Scripts/assets/img/content/campaign_post_eval.png" />
      <!-- style="height: 95%;"  -->
      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 caption"
           style="    text-align: center;">

        <p style="text-align:center">Campaign PostEval</p>
      </div>

      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 captionnew"
           style="   text-align: center;margin-bottom: 20px;;">


        <div class="tp-caption butn primary rs-parallaxlevel-3 position-relative z-index-99 xs-wide"
             (click)="GoToLoginFromPlanner()" style="background-color: #532ABA;border-radius: 7px">
          <span>LOGIN</span>
        </div>

      </div>


    </div>
  </div>

  <div class="row" style="    margin-top: 21px;">

    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top: 25px;">
      <p>
        The Campaign PostEval, a powerful tool for advertisers who want to evaluate the effectiveness of their
        marketing campaigns. The Campaign PostEval is designed to help advertisers measure the success of their
        campaigns and make data-driven decisions for future campaigns. Using a combination of data from various
        sources, it provides detailed analysis on key metrics such as reach, engagement, and ROI. One of the key
        features of this tool is the ability to measure ROI for your ongoing campaigns. CamBioscope helps
        advertisers measure ROI by deriving CPC for ongoing campaigns
      </p>

      <p></p>
      <p>Key Features of the tool includes:</p>
      <p></p>
      <p>
        <b>Measure Campaign Effectiveness:</b> understand how well your campaigns have performed in terms of reach and
        ROI.
      </p>
      <p>
        <b>Identify areas for improvement:</b> Identify areas where your campaigns could be improved to increase
        effectiveness and ROI.
      </p>
      <p>
        <b>Compare performance of different campaigns:</b> compare the performance of multiple campaigns and determine
        which strategies were most effective.
      </p>
      <p>
        <b>Measure ROI for ongoing campaigns:</b> CAMBioscope helps measure ROI for ongoing campaigns by deriving CPC.
      </p>
      <p>
        <b>Make data-driven decisions:</b> Use the insights gained from the Campaign PostEval to make data-driven
        decisions
        for future campaigns.
      </p>


    </div>
  </div>





  <div class="row" style="padding-top:20px">

  </div>
</div>


<!--  IF user log in -->
<div class="row" style="padding-top:100px;" *ngIf="this.global.IsLoggedIn =='true' && this.IsCreate == true">


  <div class="container">
    <img id="logo" src="../.../../../Scripts/assets/img/icons/circle-left-solid.svg" alt="logo"
         height='25' width='25' style="margin-bottom:10px" (click)="BackPostEvlData()" />
    <div class="x_panel_border" style="padding-top: 28px;margin-bottom: 100px;">

      <form [formGroup]="registerPostForm" (ngSubmit)="onSubmit()">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom: -55px;">

          <!-- Campaign, Title Client, Name Region  -->
          <div class="row">

            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                          font: normal normal normal 12px/12px Archivo Variable;
                          font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                          letter-spacing: 0.3px;
                          color: #F0F0F0;
                          text-transform: uppercase
                          ;color: #40444e  ;opacity: 0.7;">Title<span class="mandatory">*</span></span>

              <div>
                <!-- <input type="text" name="CampaignStartDate" class="form-control" [(ngModel)]="CampaignStartDate" /> -->
                <input type="text" formControlName="CampaignTitle" placeholder="Title"
                       [(ngModel)]="CampaignTitle" class="form-control border-input"
                       [ngClass]="{ 'is-invalid': submitted && registerPostForm.controls.CampaignTitle.errors }" >



              </div>
              <div *ngIf=" submitted && registerPostForm.controls.CampaignTitle.errors"
                   class="text-danger" style=" margin-left: 0px;">
                <div *ngIf=" submitted && registerPostForm.controls.CampaignTitle.errors.required">
                  Title required.
                </div>

              </div>
            </div>


            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">

              <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                    font: normal normal normal 12px/12px Archivo Variable;
                    font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                    letter-spacing: 0.3px;
                    color: #F0F0F0;
                    text-transform: uppercase
                    ;color: #40444e  ;opacity: 0.7;">Client Name<span class="mandatory">*</span></span>

              <div>
                <!-- <input type="text" name="CampaignStartDate" class="form-control" [(ngModel)]="CampaignStartDate" /> -->
                <input type="text" placeholder="Client Name" [(ngModel)]="ClientName"
                       class="form-control border-input" formControlName="ClientName"
                       [ngClass]="{ 'is-invalid': submitted && registerPostForm.controls.ClientName.errors }" >



              </div>
              <div *ngIf=" submitted && registerPostForm.controls.ClientName.errors" class="text-danger"
                   style="margin-left: 0px;">
                <div *ngIf=" submitted && registerPostForm.controls.ClientName.errors.required">
                  Client Name required.
                </div>
              </div>
            </div>


            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">


              <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                    font: normal normal normal 12px/12px Archivo Variable;
                    font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                    letter-spacing: 0.3px;
                    color: #F0F0F0;
                    text-transform: uppercase
                    ;color: #40444e  ;opacity: 0.7;">AD Duration (Sec)<span class="mandatory">*</span></span>

              <div>

                <!-- <input type="text" name="CampaignStartDate" class="form-control" [(ngModel)]="CampaignStartDate" /> -->
                <input type="text" placeholder="Duration" [(ngModel)]="Duration" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                       class="form-control border-input" formControlName="Duration"
                       [ngClass]="{ 'is-invalid': submitted && registerPostForm.controls.Duration.errors }"  maxlength="3">



              </div>
              <div *ngIf=" submitted && registerPostForm.controls.Duration.errors" class="text-danger"
                   style="margin-left: 0px;">
                <div *ngIf=" submitted && registerPostForm.controls.Duration.errors.required">
                  Duration required.
                </div>


              </div>


              <!-- <div *ngIf="IsCharacter" class="text-danger"
                  style="margin-left: 0px;">

                  Only Numbers are allowed.


              </div> -->




            </div>
          </div>

          <!-- Campaign, Start Date Campaign, Start Date  State -->
          <div class="row" style="padding-top: 20px;">

            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                          font: normal normal normal 12px/12px Archivo Variable;
                          font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                          letter-spacing: 0.3px;
                          color: #F0F0F0;
                          text-transform: uppercase
                          ;color: #40444e  ;opacity: 0.7;">Start Date<span class="mandatory">*</span></span>

              <div>
                <!-- <input type="text" name="CampaignStartDate" class="form-control" [(ngModel)]="CampaignStartDate" /> -->
                <input type="text" placeholder="Select Start Date" bsDatepicker
                       [(ngModel)]="CampaignStartDate" class="form-control border-input"
                       value="{{ CampaignStartDate | date:'dd-MMM-yyyy' }}" [maxDate]="CampaignEndDate"
                       formControlName="CampaignStartDate" [bsConfig]="{ dateInputFormat: 'DD-MMM-YYYY' }"
                       [ngClass]="{ 'is-invalid': submitted && registerPostForm.controls.CampaignStartDate.errors }"  >
                <!-- [minDate]="todayDate" -->


              </div>
              <div *ngIf=" submitted && registerPostForm.controls.CampaignStartDate.errors"
                   class="text-danger" style="margin-left: 0px;">
                <div *ngIf=" submitted && registerPostForm.controls.CampaignStartDate.errors.required">
                  Start Date required.
                </div>
              </div>
            </div>


            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">


              <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                        font: normal normal normal 12px/12px Archivo Variable;
                        font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                        letter-spacing: 0.3px;
                        color: #F0F0F0;
                        text-transform: uppercase
                        ;color: #40444e  ;opacity: 0.7;">End Date<span class="mandatory">*</span></span>

              <div>
                <!-- <input type="text" name="CampaignEndDate" class="form-control" [(ngModel)]="CampaignEndDate" /> -->

                <input type="text" placeholder="Select End Date" bsDatepicker
                       [(ngModel)]="CampaignEndDate" class="form-control border-input"
                       value="{{ CampaignEndDate | date:'dd-MMM-yyyy'}}" [minDate]="CampaignStartDate"
                       [maxDate]="todayDate" formControlName="CampaignEndDate" [bsConfig]="{ dateInputFormat: 'DD-MMM-YYYY' }"
                       [ngClass]="{ 'is-invalid': submitted && registerPostForm.controls.CampaignEndDate.errors }"   />



              </div>
              <div *ngIf=" submitted && registerPostForm.controls.CampaignEndDate.errors"
                   class="text-danger" style=" margin-left: 0px;">
                <div *ngIf=" submitted && registerPostForm.controls.CampaignEndDate.errors.required">
                  End Date required.
                </div>
              </div>






            </div>
          </div>


          <!-- Cinema Chains, Duration -->
          <div class="row" style="padding-top: 20px;">

            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">

              <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                        font: normal normal normal 12px/12px Archivo Variable;
                        font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                        letter-spacing: 0.3px;
                        color: #F0F0F0;
                        text-transform: uppercase
                        ;color: #40444e  ;padding-bottom: 6px;opacity: 0.7;">Region<span class="mandatory">*</span></span>

              <div class="row" style="padding-top: 6px;">
                <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                     *ngFor="let regionlist of regionList;let i=index ">
                  <div class="row ">
                    <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                         style="color: #40444e;font-size:18px;opacity: 0.7;">
                      {{regionlist.Zone}}
                    </div>
                    <div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">

                      <!--<input type="checkbox" [checked]="regionlist.Status" class="chck"
        name="check" [(ngModel)]="regionlist.Status"
        (change)="checkValue(regionlist)" [formControlName]="i"
        [ngClass]="{ 'is-invalid': submitted && registerPostForm.controls.regionCheckbox.errors }">-->

                      <input type="checkbox" [checked]="regionlist.Status" class="chck"
                             name="check" id="{{'btn'+ regionlist.Zone}}"
                             (change)="checkValue($event,regionlist)" >

                    </div>

                  </div>

                </div>
                <div class="text-danger" style="margin-left: 0px;">
                  <div *ngIf="isRegionCheck">
                    Region required.
                  </div>

                </div>



              </div>
            </div>

            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">


              <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                        font: normal normal normal 12px/12px Archivo Variable;
                        font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                        letter-spacing: 0.3px;
                        color: #F0F0F0;
                        text-transform: uppercase
                        ;color: #40444e  ;padding-bottom: 6px;opacity: 0.7;">State<span class="mandatory">*</span></span>



              <ng-multiselect-dropdown [settings]="dropdownStateSettings" [data]="StateList"
                                       [(ngModel)]="selectedStateListItems" (onSelect)="onItemStateSelect($event)"
                                       (onSelectAll)="onStateSelectAll($event)" (onDeSelect)="onItemStateDeSelect($event)"
                                       (onDeSelectAll)="onUnStateSelectAll()" formControlName="selectStateList"
                                       [ngClass]="{ 'is-invalid': submitted && registerPostForm.controls.selectStateList.errors }">
              </ng-multiselect-dropdown>

              <div *ngIf=" submitted && registerPostForm.controls.selectStateList.errors"
                   class="text-danger" style="margin-left: 0px;">
                <div *ngIf=" submitted && registerPostForm.controls.selectStateList.errors.required">
                  State required.
                </div>

              </div>

            </div>








            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">

            </div>





          </div>

          <div class="row">
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">

              <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                              font: normal normal normal 12px/12px Archivo Variable;
                              font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                              letter-spacing: 0.3px;
                              color: #F0F0F0;
                              text-transform: uppercase
                              ;color: #40444e  ;padding-bottom: 6px;opacity: 0.7;">Cinema Chains<span class="mandatory">*</span></span>

              <div class="row">
                <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <ng-multiselect-dropdown [settings]="dropdowncinemachainSettings"
                                           [data]="cinemaChainsList" [(ngModel)]="selectedcinemaChainsListItems"
                                           (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                                           (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onUnSelectAll()"
                                           formControlName="ChainList"
                                           [ngClass]="{ 'is-invalid': submitted && registerPostForm.controls.ChainList.errors }">
                  </ng-multiselect-dropdown>

                  <div *ngIf=" submitted && registerPostForm.controls.ChainList.errors"
                       class="text-danger" style=" margin-left: 0px;">
                    <div *ngIf=" submitted && registerPostForm.controls.ChainList.errors.required">
                      Chain required.
                    </div>

                  </div>
                </div>



                <!-- <div class="col-2  col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
                                    style="margin-left: -25px">
                                    <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
                border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="AddChainData()">
                                        Add
                                    </button>
                                </div> -->
              </div>


            </div>
          </div>


          <!-- SPOTS & BUDGET -->
          <div class="row">
            <div class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                 style="padding-top: 3px;overflow-x:auto" *ngIf="IsChainAdd">

              <div *ngIf="IsChainAdd">

                <table>
                  <!-- Header -->
                  <tr>

                    <th></th>
                    <th class="fontText" style="color:white;padding-left: 10px;">
                      SPOTS<span class="mandatory">*</span>
                    </th>
                    <th class="fontText" style="color:white;padding-left: 10px;">
                      BUDGET<span class="mandatory">*</span>
                    </th>
                    <th class="fontText"></th>
                  </tr>
                  <ng-container [formGroup]="registerPostForm">
                    <ng-container formArrayName="cinemachain">
                      <tr [formGroupName]="i" *ngFor="let lst of FinalArray;let i=index">

                        <td class="fontText">{{lst.chains}}</td>
                        <td>

                          <input type="text" formControlName="spots" style="font-size: 12px;" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" />
                                 
                        </td>

                        <td>
                          <input type="text" formControlName="Budget"
                                 style="    font-size: 12px;"
                                 oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">
                        </td>

                        <td style="cursor: pointer">
                          <img id="logo"
                               src="../.../../../Scripts/assets/img/icons/Reset_logo.svg" alt="logo" height='25'
                               width='25' (click)=ResetChains(lst) />
                        </td>
                        
                        
                      </tr>
                    </ng-container>
                  </ng-container>

                </table>

              </div>
            </div>
            <div class="col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">

            </div>
          </div>

          <div class="row" style="padding-top: 20px">
            <div class="col-2 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
              <input type="checkbox" [checked]="SavePref" class="chck" name="check"
                     (change)="SavePrefrence(SavePref)">

            </div>

            <div class="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
                 style="color: #40444e;font-size:18px;opacity: 0.7;margin-left: -61px;">
              <span style=" font-size: 15px; color:white;
                            font: normal normal normal 12px/12px Archivo Variable;
                            font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                            letter-spacing: 0.3px;
                            color: #F0F0F0;
                            text-transform: uppercase
                            ;color: #40444e">Save Preference</span>

            </div>



          </div>


          <!-- Submit -->
          <div class="row" style="padding-top: 20px;padding-bottom: 100px;">
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">

            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                 style="text-align: center;padding-top: 33px;">
              <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
                                     border: 1px solid #532ABA;opacity: 1;height: 34px;">
                Submit
              </button>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="text-align: center;padding-top: 33px;">
              <!--<button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
                                     border: 1px solid #532ABA;opacity: 1;height: 34px;" (click)="BackPostEvlData()">
                Back
              </button>-->
            </div>

          </div>

        </div>

      </form>

      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
           style="margin-bottom: 100px;overflow-x:auto" *ngIf="IsSubmitPostEval">
        <div>
          <table style="width: 100%">
            <!-- Header -->
            <tr>


              <th class="fontTextSubmit" style="color:white;padding-left: 10px;">Chain</th>
              <th class="fontTextSubmit" style="color:white;padding-left: 10px;">Spots</th>
              <th class="fontTextSubmit" style="color:white;padding-left: 10px;">Budget</th>
              <!--<th class="fontTextSubmit" style="color:white;padding-left: 10px;">Shows</th>
              <th class="fontTextSubmit" style="color:white;padding-left: 10px;">Admits</th>-->
              <th class="fontTextSubmit" style="color:white;padding-left: 10px;">Views</th>
              <!-- <th class="fontTextSubmit" style="color:white;padding-left: 10px;">Budget(10Sec)</th> -->
              <th class="fontTextSubmit" style="color:white;padding-left: 10px;">CPC</th>

            </tr>

            <tr *ngFor="let tbl of FinalPostEvalData">

              <td class="fontTextSubmit">{{tbl.Chain}}</td>
              <td class="fontTextSubmit">{{tbl.Spots}}</td>
              <td class="fontTextSubmit">{{tbl.Budget}}</td>
              <!--<td class="fontTextSubmit">{{tbl.Shows}}</td>
              <td class="fontTextSubmit">{{tbl.Admits}}</td>-->
              <td class="fontTextSubmit">{{tbl.EffectiveAdmits}}</td>
              <!-- <td class="fontTextSubmit">{{tbl.Budget10sec}}</td> -->
              <td class="fontTextSubmit">{{tbl.CPC}}</td>







            </tr>


          </table>
        </div>
      </div>
    </div>



  </div>
</div>


<!--Create button-->
<div *ngIf="this.global.IsLoggedIn =='true' && this.IsCreate == false" class="container" style="padding-top:50px">

  <div *ngIf="this.IsCampainPresent ==false" class="row" style="padding-top:20px">
    <p>
      The Campaign PostEval, a powerful tool for advertisers looking to evaluate the effectiveness of their
      marketing campaigns.

      The Campaign PostEval is designed to help advertisers measure the success of their campaigns and make
      data-driven decisions about future campaigns. Using a combination of data from CAMBioscope, as well as
      other sources, it provides detailed analysis on key metrics such as reach, engagement, conversions, and
      ROI. One of the key feature of this tool is it's ability to measure ROI for your running campaigns.
      CamBioscope helps advertisers to measure the ROI by deriving the CPM for any running campaign.
    </p>
  </div>

  <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div class="row" style="padding-top:20px;padding-bottom: 10px;align-content:center">
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"></div>
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"></div>
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="text-align:right">
        <button class="btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;border: 1px solid #532ABA;opacity: 1;height: 40px; width: 40%;" (click)="GoToPostEvalForm()">
          Create Post Eval
        </button>
      </div>

    </div>
  </div>

  <div *ngIf="this.IsCampainPresent == true" class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom: 100px;overflow-x:auto">
    <table style="width: 100%">
      <!-- Header -->
      <tr>


        <th class="fontTextSubmit" style="color:white;padding-left: 10px;">Title</th>
        <th class="fontTextSubmit" style="color:white;padding-left: 10px;">Client Name</th>
        <th class="fontTextSubmit" style="color:white;padding-left: 10px;">AD Duration (Sec)</th>
        <th class="fontTextSubmit" style="color:white;padding-left: 10px;">Start Date</th>
        <th class="fontTextSubmit" style="color:white;padding-left: 10px;">End Date</th>
        <th class="fontTextSubmit" style="color:white;padding-left: 10px;"></th>
      </tr>

      <tr *ngFor="let tbl of ViewPostEvalCamapinList">

        <td class="fontTextSubmit">{{tbl.CampaignTitle}}</td>
        <td class="fontTextSubmit">{{tbl.ClientName}}</td>
        <td class="fontTextSubmit">{{tbl.Duration}}</td>
        <td class="fontTextSubmit">{{tbl.StartDate | date: 'dd-MMM-yyyy'}}</td>
        <td class="fontTextSubmit">{{tbl.EndDate | date: 'dd-MMM-yyyy'}}</td>
        <td class="fontTextSubmit">
          <img id="logo" src="../.../../../Scripts/assets/img/icons/view_icon.svg" alt="logo"
               height='25' width='25' (click)="btnView(tbl.PostEvalID)" />

          <img id="logo" src="../.../../../Scripts/assets/img/icons/delete_icon.svg" alt="logo"
               height='25' width='25' (click)="btnDelete(tbl.PostEvalID)" />


        </td>
      </tr>
    </table>
  </div>

</div>
