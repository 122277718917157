import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SearchPipePipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) { }
  
  transform(EmbededURL) {
    debugger;
    return this.domSanitizer.bypassSecurityTrustResourceUrl(EmbededURL);
  }

}





