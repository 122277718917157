import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';

import { throwError as observableThrowError, Observable, from } from 'rxjs';
import {Global} from '../global/global'


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json ;charset=utf-8',
        'accept': 'application/json'
    })
};
@Injectable()
export class LoginPagesService {


    constructor(private _http: HttpClient, private global: Global) { }

    getLoginPageOtpData(email: string): Observable<any> {
        debugger


        // return this._http.get(this.global.baseLoginUrl + '/Login/GetUser?EmailID=' + email).pipe(
        //   map((response: Response) => {}),
        //   catchError(this._errorHandler));

        return this._http.get(this.global.baseUrl + 'api/Login/GetUser?EmailID=' + email).pipe(
            map((response) => {
                 return response
            })
        )
    }

    GetVerifiyEmailOtp(email: string, otp: number): Observable<any> {
        debugger


        // return this._http.get(this.global.baseLoginUrl + '/Login/GetUser_Role?EmailID=' + email + '&OTP=' + otp).pipe(
        //   map((response: Response) => <any>response),
        //   catchError(this._errorHandler));

        return this._http.get(this.global.baseUrl + 'api/Login/Get_User_Rights?EmailID=' + email + '&OTP=' + otp).pipe(
            map((response: any) => { return response }), catchError(this._errorHandler));



    }

    getLoginPageData(Uname: string, pass: string): Observable<any> {
        debugger


        // return this._http.get(this.global.baseLoginUrl + '/Login/GetUser_Role?EmailID=' + email + '&OTP=' + otp).pipe(
        //   map((response: Response) => <any>response),
        //   catchError(this._errorHandler));

        return this._http.get(this.global.baseUrl + 'api/Login/Get_User_Login?EmailID=' + Uname +'&Password=' + pass).pipe(
            map((response: any) => { return response }), catchError(this._errorHandler));



    }


    // SetNewPassword(Uname: string, pass: string): Observable<any> {
    //     debugger


    //     // return this._http.get(this.global.baseLoginUrl + '/Login/GetUser_Role?EmailID=' + email + '&OTP=' + otp).pipe(
    //     //   map((response: Response) => <any>response),
    //     //   catchError(this._errorHandler));

    //     return this._http.post(this.global.baseUrl + 'api/Login/ForgotPassword?EmailID=' + Uname +'&Password=' + pass,).pipe(
    //         map((response: any) => { return response }), catchError(this._errorHandler));



    // }


    


 








    _errorHandler(error: Response) {
        debugger

        console.log(error);
        return observableThrowError(() => error || "Internal server error");
    }









}