<style>
  p {
    text-align: justify;
  }
</style>
<div class="container" style="padding-top:50px">
  <div _ngcontent-oou-c51="" class="row" style="padding-top: 50px;padding-bottom: 71px;">


    <p style="    text-align: left;
        font-size: 23px;
        padding-top: -11px;
        font-family: fangsong;
        color: #532ABA;
        font-weight: 500;">FAQ</p>

    <div class="row">
      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <b style="font-size: 21px; font-weight: 500;">What is a Planner?</b>
        <p></p>
        <p></p>
        <p>
          The AD Planner provides advertisers with the tools they need to make data-driven marketing decisions.
          Through
          CAMBioscope's audience measurement and analytics, advertisers can gain valuable insight. In addition
          to
          identifying target audiences, planning cinema campaigns, and assessing their effectiveness, this
          information
          can be used to measure the success of advertising campaigns.
        </p>
        <p></p>
        <b style="color: grey;">Features of ADPlanner:</b>
        <p></p>
        <p>
          Target Audience: Segment audiences by demographics, interests, and behaviors to target specific
          audiences.
        </p>
        <p></p>
        <p>
          Campaign Planning: Planning and optimizing Cinema Campaigns using audience data can maximize
          the
          effectiveness of your campaigns.
        </p>
        <p></p>
        <p>
          Performance tracking: Evaluate the results of your ads in near real-time and make tweaks based
          on
          data.
        </p>
        <p></p>
        <p>
          Analytics: Create detailed reports and analytics on your ad campaigns so you can better
          understand
          your audience and improve your marketing efforts.
        </p>
        <p></p>


        <b style="font-size: 21px; font-weight: 500;">What is a Post Eval?</b>
        <p></p>
        <p></p>
        <p>
          The Campaign PostEval, a powerful tool for advertisers who want to evaluate the effectiveness of
          their
          marketing campaigns. The Campaign PostEval is designed to help advertisers measure the success of
          their
          campaigns and make data-driven decisions for future campaigns. Using a combination of data from
          various
          sources, it provides detailed analysis on key metrics such as reach, engagement, and ROI. One of the
          key
          features of this tool is the ability to measure ROI for your ongoing campaigns. CamBioscope helps
          advertisers measure ROI by deriving CPC for ongoing campaigns
        </p>

        <p></p>
        <p>Key Features of the tool includes:</p>
        <p></p>
        <p>
          Measure Campaign Effectiveness: understand how well your campaigns have performed in terms of
          reach
          and
          ROI.
        </p>
        <p>
          Identify areas for improvement: Identify areas where your campaigns could be improved to
          increase
          effectiveness and ROI.
        </p>
        <p>
          Compare performance of different campaigns: compare the performance of multiple campaigns and
          determine
          which strategies were most effective.
        </p>
        <p>
          Measure ROI for ongoing campaigns: CAMBioscope helps measure ROI for ongoing campaigns by
          deriving
          CPC.
        </p>
        <p>
          Make data-driven decisions: Use the insights gained from the Campaign PostEval to make
          data-driven
          decisions
          for future campaigns.
        </p>
        <p></p>

        <b style="font-size: 21px; font-weight: 500;">What are Cinema Stats?</b>
        <p></p>
        <p></p>
        <p>
          Cine Stats is designed to give pulse of the Indian Cinema Industry. This gives a complete overview
          as
          well as the insights of the industry from advertizers perspective. Indian cinema industry is broadly
          divided in two Geographies
          <ul>
            <li>
              South : Southern part of India comprizing of Kerala, Andrapradesh, Telangana, Karnataka,
              Tamilnadu
              and Pondicherry
            </li>

            <li>Hindi Speaking Markets(HSM) : Rest of india is primarily treated as HSM.</li>
          </ul>

          CineStats Helps users Slice and Dice the data across multiple paramaters for these two primary markets.
          Some of the key data points include
          <ul>
            <li>Active Screens</li>
            <li>Show per day</li>
            <li>Admits per Day\Per Show</li>
            <li>Avg Box office collections</li>
            <li>Occupancy</li>
          </ul>

      </div>
    </div>
  </div>
</div>
