import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './Home/home.component';
import { LoginComponent } from './Login/login.component';
import { HeaderComponent } from './Header/header.component';
import { FooterComponent } from './Footer/footer.component';
import { RegistrationComponent } from './Registration/registration.component';
import {PlannerComponent} from './Planner/planner.component'
import {PostevalComponent} from './PostEva/posteval.component'
import { EmbededReportComponent } from './embeded-report/embeded-report.component';
import { TeamComponent } from './Team/team.component'
import { CasestudiesComponent } from './CaseStudies/casestudies.component';
import { ContactComponent } from './Contact/contact.component';
import { PrivacypolicyComponent } from './PrivacyPolicy/privacypolicy.component'
import { CinemastatsComponent } from './CinemaStats/cinemastats.component'
import { AboutusComponent } from './About/aboutus.component'
import { DisclaimerComponent } from './Disclaimer/disclaimer.component'
import { Plannerreport2Component } from './plannerreport2/plannerreport2.component'
import { PlannerdraftComponent } from './PlannerDraft/plannerdraft.component'
import { ScreenReportComponent } from './screen-report/screen-report.component'
import { PlannerDAVPComponent} from './planner-davp/planner-davp.component'

const routes: Routes = [
 
  { path: 'Login', component: LoginComponent },
  { path: 'Header', component: HeaderComponent },
  
  { path: 'Footer', component: FooterComponent },
  { path: '', component: HomeComponent },
  { path: 'Registration', component: RegistrationComponent },
  { path: 'Planner', component: PlannerComponent },
  { path: 'PostEval', component: PostevalComponent },
  { path: 'CBPlanner',component: EmbededReportComponent },
  { path: 'Team', component: TeamComponent },
  { path: 'CaseStudies', component: CasestudiesComponent },
  { path: 'Contact', component: ContactComponent },
  { path: 'Privacy', component: PrivacypolicyComponent },
  { path: 'CinemaStats', component: CinemastatsComponent },
  { path: 'AboutUs', component: AboutusComponent },
  { path: 'Disclaimer', component: DisclaimerComponent },
  { path: 'CBPlanner2', component: Plannerreport2Component },
  { path: 'PlannerDraft', component: PlannerdraftComponent },
  { path: 'ScreenReport', component: ScreenReportComponent },
  { path: 'planner', component: PlannerDAVPComponent }
  









  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
