<!-- Old Registration Code  -->
  <style>
    .mandatory {
      color: #dc3545 !important;
      font-weight: 800;
      font-size: 15px;
    }
    
   
   a:hover {
    color: #dd6a6a !important;
}
  
  </style>


<div class="loadingGif" *ngIf="isShowLoader"></div>

<div class="message" *ngIf="IsMessage">
  <div class="row" style="padding-top: 21px;
    padding-left: 40px;">
    <span style=" color: #40444e;
      font-weight: 400;">
      Do you want to delete profile?
    </span>
  </div>
  <div class="row" style="padding-top: 30px;">
    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="text-align: right">
      <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
            border: 1px solid #532ABA;opacity: 1;height: 34px;width:85px" (click)=" Delete()">
        Yes
      </button>
    </div>

    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
      <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
            border: 1px solid #532ABA;opacity: 1;height: 34px;width:85px" (click)="DeleteNo()">
        No
      </button>
    </div>

  </div>
</div>

<div class="row" style="padding-top:120px;padding-bottom: 71px;">

  <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
    <h6 class></h6>
  </div>
  


  <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 loginbg register-form" [style.height.px]="myInnerHeight"
       style="height: 800px;  border-radius: 11px; border-color: #fafafa; background: #fafafa;">
    
    <div class="container-fluid" style="padding-top: 16px;">
      <div class="row">
        <div class="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
          <img id="logo" src="../.../../../Scripts/assets/img/icons/circle-left-solid.svg" alt="logo"
               height='25' width='25' (click)="Back()" />
        </div>
        <div class="col-10 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10" style="padding-left: 82px;">
          <span style="text-align: center;
          font: normal normal normal 14px/32px Archivo Variable;
          font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
          letter-spacing: 0px;
          color: #FFFFFF;
          text-transform: uppercase; 
          opacity: 1;color: #8a8b8e;">Registration</span>
        </div>

        
      </div>

        <div class="row" style="padding-top: 10px; width: 100%;opacity: 0.2">
          <hr>
        </div>

        <form [formGroup]="RegistrationForm" (ngSubmit)="onSubmit()">
          <!-- FirstName -->
          <div class="row" style="padding-top: 10px;text-align: left;opacity: 0.7;">
            <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                      font: normal normal normal 12px/12px Archivo Variable;
                      font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                      letter-spacing: 0.3px;
                      color: #F0F0F0;
                      text-transform: uppercase
                      ;color: #8a8b8e;">First Name<span class="mandatory">*</span></span>
          </div>

          <div class="row form-group" style="padding-top: 5px;">
            <input type="text" class="form-control" formControlName="firstname" [(ngModel)]="firstname" autocomplete="off"
                   [ngClass]="{ 'is-invalid': submitted && RegistrationForm.controls.firstname.errors }" />
          </div>

          <div *ngIf=" submitted && RegistrationForm.controls.firstname.errors" class="text-danger"
               style="margin-top: -13px;margin-left: -13px;">
            <div *ngIf=" submitted && RegistrationForm.controls.firstname.errors.required">
              First Name required.
            </div>

          </div>

          <!-- LastName -->
          <div class="row" style="padding-top: 10px;text-align: left;opacity: 0.7;">
            <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                      font: normal normal normal 12px/12px Archivo Variable;
                      font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                      letter-spacing: 0.3px;
                      color: #F0F0F0;
                      text-transform: uppercase
                      ;color: #8a8b8e;">Last Name</span>
          </div>

          <div class="row form-group" style="padding-top: 5px;">
            <input type="text" class="form-control" formControlName="lastname" [(ngModel)]="lastname" autocomplete="off" />
          </div>

          <!-- Email -->
          <div class="row" style="padding-top: 10px;text-align: left;opacity: 0.7;">
            <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                      font: normal normal normal 12px/12px Archivo Variable;
                      font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                      letter-spacing: 0.3px;
                      color: #F0F0F0;
                      text-transform: uppercase
                      ;color: #8a8b8e;">Email<span class="mandatory">*</span></span>
          </div>

          <div class="row form-group" style="padding-top: 5px;">
            <input type="text" name="email" class="form-control" formControlName="email" [(ngModel)]="email" autocomplete="off" [disabled]="true"
                   [ngClass]="{ 'is-invalid': submitted && RegistrationForm.controls.email.errors }" />
          </div>

          <div *ngIf=" submitted && RegistrationForm.controls.email.errors" class="text-danger"
               style="margin-top: -13px;margin-left: -13px;">
            <div *ngIf=" submitted && RegistrationForm.controls.email.errors.required">
              Email required.
            </div>
            <div *ngIf=" submitted && RegistrationForm.controls.email.errors.email">
              Email is not valid..
            </div>


          </div>

          <!-- Phone -->
          <div class="row" style="padding-top: 10px;text-align: left;opacity: 0.7;">
            <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                      font: normal normal normal 12px/12px Archivo Variable;
                      font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                      letter-spacing: 0.3px;
                      color: #F0F0F0;
                      text-transform: uppercase
                      ;color: #8a8b8e;">Phone<span class="mandatory">*</span></span>
          </div>

          <div class="row form-group" style="padding-top: 5px;">
            <input type="text" name="phone" class="form-control" formControlName="phone" [(ngModel)]="phone" autocomplete="off"
                   [ngClass]="{ 'is-invalid': submitted && RegistrationForm.controls.phone.errors }" />
          </div>

          <div *ngIf=" submitted && RegistrationForm.controls.phone.errors" class="text-danger"
               style="margin-top: -13px;margin-left: -13px;">
            <div *ngIf=" submitted && RegistrationForm.controls.phone.errors.required">
              Phone required.
            </div>
            <div *ngIf=" submitted && RegistrationForm.controls.phone.errors.pattern">
              Provide 10 digit phone number.
            </div>
          </div>

          <!-- CompanyName -->
          <div class="row" style="padding-top: 10px;text-align: left;opacity: 0.7;">
            <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                    font: normal normal normal 12px/12px Archivo Variable;
                    font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                    letter-spacing: 0.3px;
                    color: #F0F0F0;
                    text-transform: uppercase
                    ;color: #8a8b8e;">Company Name</span>
          </div>

          <div class="row form-group" style="padding-top: 5px;">
            <input type="text" name="companyname" class="form-control" formControlName="companyname" autocomplete="off"
                   [(ngModel)]="companyname" />


          </div>

          <!-- Pass -->
          <div *ngIf="Profile_view_mode!='E'">
            <div class="row" style="padding-top: 10px;text-align: left;opacity: 0.7;">
              <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                    font: normal normal normal 12px/12px Archivo Variable;
                    font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                    letter-spacing: 0.3px;
                    color: #F0F0F0;
                    text-transform: uppercase
                    ;color: #8a8b8e;">Password<span class="mandatory">*</span></span>
            </div>
            <div class="row form-group" style="padding-top: 5px;">
              <input type="password" name="password" class="form-control" formControlName="password" [(ngModel)]="password" autocomplete="off"
                     [ngClass]="{ 'is-invalid': submitted && RegistrationForm.controls.password.errors }" />


            </div>
            <div *ngIf=" submitted && RegistrationForm.controls.password.errors" class="text-danger"
                 style="margin-top: -13px;margin-left: -13px;">
              <div *ngIf=" submitted && RegistrationForm.controls.password.errors.required">
                Password required.
              </div>
            </div>
          </div>


          <!-- CnfPass -->
          <div *ngIf="Profile_view_mode!='E'">
            <div class="row" style="padding-top: 10px;text-align: left;opacity: 0.7;">
              <span style=" font-size: 15px; color:white;    padding-left: 2px;text-align: left;
                    font: normal normal normal 12px/12px Archivo Variable;
                    font-family: 'Archivo-Variable', Arial, Helvetica, sans-serif;
                    letter-spacing: 0.3px;
                    color: #F0F0F0;
                    text-transform: uppercase
                    ;color: #8a8b8e;">Confirm Password<span class="mandatory">*</span></span>
            </div>
            <div class="row form-group" style="padding-top: 5px;">
              <input type="text" name="password" class="form-control" formControlName="cnfpassword" [(ngModel)]="cnfpassword" autocomplete="off"
                     [ngClass]="{ 'is-invalid': submitted && RegistrationForm.controls.cnfpassword.errors }" />


            </div>
            <div *ngIf=" submitted && RegistrationForm.controls.cnfpassword.errors" class="text-danger"
                 style="margin-top: -13px;margin-left: -13px;">
              <div *ngIf=" submitted && RegistrationForm.controls.cnfpassword.errors.required">
                Confirm required.
              </div>
              <div *ngIf="submitted && RegistrationForm.controls.cnfpassword.errors.confirmedValidator">Password and Confirm Password must be match.</div>
            </div>
          </div>



          <!-- Registration -->

          <div *ngIf="Profile_view_mode!='E'" class="row" style="padding-top: 11px;padding-bottom: 15px;">
            <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
                              border: 1px solid #532ABA;opacity: 1;height: 34px;">
              <!--Register-->Register
            </button>
          </div>
          <div *ngIf="Profile_view_mode=='E'" class="row" style="padding-top: 11px">
            <button class="btn btn btn-success" style="background: #532ABA 0% 0% no-repeat padding-box;
                              border: 1px solid #532ABA;opacity: 1;height: 34px;">
              <!--Register-->Update
            </button>

          </div>


        </form>

        <div class="row" style="padding-top:10px;text-align:left">
          <a (click)="DisableAccount()" style="margin-left: -8px;text-decoration: underline;color: red;" role="button">Delete Account</a>
        </div>

      </div>
  </div>






  <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5">


  </div>

</div>
