import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  GotoPlanner() {
    window.location.href = "./Planner"
  }

  GoToPostEval() {
    window.location.href = "./PostEval"
  }
  GoToCinemaStats() {
    window.location.href = "./CinemaStats"
  }

  GotoAbout() {
    window.location.href = "./AboutUs"
  }
  GotoDisclaimer() {
    window.location.href = "./Disclaimer"
  }
  GotoPrivacy() {
    window.location.href = "./Privacy"
  }
  GotoCaseStudies() {
    window.location.href = "./CaseStudies"
  }
  GotoTeam()
  {
    window.location.href = "./Team"
  }


}
