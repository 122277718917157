import { Component, OnInit } from '@angular/core';
import { Global } from '../global/global'

@Component({
  selector: 'app-planner-davp',
  templateUrl: './planner-davp.component.html',
  styleUrls: ['./planner-davp.component.css']
})
export class PlannerDAVPComponent implements OnInit {
  PlannerDAVPURL: string;
  Login: string;
  constructor(public global: Global) { }

  ngOnInit(): void {
    debugger
    this.global.IsLoggedIn = sessionStorage.getItem('IsLoggedIn');

    console.log(this.global.IsLoggedIn)
    if (this.global.IsLoggedIn == null) {
      this.global.IsLoggedIn = 'false';
    }
    this.Login = this.global.IsLoggedIn;
    this.global.IsOnMenu = false
    this.global.IsOnApproachMenu = false
    this.global.IsOnPlannerMenu = false
    this.global.IsOnPostEvalMenu = false
    this.global.IsOnContactUsMenu = false
    this.global.IsOnStartedMenu = false
    this.global.IsCinemaStats = false
    this.global.IsPlannerDAVPMenu=true

    if (this.global.IsLoggedIn == 'true') {
      this.PlannerDAVPURL = this.global.EmbededURL + "/DAVPReport";
      this.PlannerDAVPURL = this.PlannerDAVPURL.trim();
    }
  }

}
