import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as XLSX from 'xlsx';
import { Global } from '../global/global';
import { ConfigScreen } from '../Model/ConfigScreen'
import { fail } from 'assert';
import { transcode } from 'buffer';
import { SharedService } from '../shared/shared.service'



@Component({
  selector: 'app-plannerdraft',
  templateUrl: './plannerdraft.component.html',
  styleUrls: ['./plannerdraft.component.css']
})
export class PlannerdraftComponent implements OnInit {

  // Campaign Basic
  todayDate = new Date();
  FromDate = new Date();
  CampaignBasicForm: FormGroup;
  submitted = false;
  Screensubmitted = false
  ClientName: any
  Brands: any
  Duration: any;
  CampaignObject: any
  CampaignBudget: any
  CampaignStartDate: any
  CampaignEndDate: any

  // Network
  // Screen
  ScreenForm: FormGroup
  selectedStateListItems: any = [];
  selectedTiersListItems: any = [];
  selectedCityListItems: any = [];
  selectedScreenListItems: any = [];
  selectedNetworkListItems: any = [];

  selectedPinCodeListItems: any = [];
  StateList: any = [];
  TiersList: any = [];
  CityList: any = [];
  ScreenList: any = [];
  NetworkList: any = [];
  Atp: any = 100
  //New
  ScreenTypeList: any = []
  PinCodeList = []
  Tags: any
  NoOfShows: any
  screeenFilter: any
  dropdownStateSettings: IDropdownSettings
  dropdownTiersSettings: IDropdownSettings
  dropdownCitySettings: IDropdownSettings
  dropdownNetworkSettings: IDropdownSettings
  dropdownPinCodeSettings: IDropdownSettings
  dropdownShowTimeSettings: IDropdownSettings
  dropdownScreenSettings: IDropdownSettings

  //Flags
  IsCampaignBasicStep: boolean = true
  IsScreenStep: boolean = false
  IsRatesStep: boolean = false
  IsCampaignBasicDiv: boolean = true
  IsScreenStepDiv: boolean = false
  IsRatesStepDiv: boolean = false

  // Loader
  isShowLoader: boolean = false

  //All Data Dump
  CityListAllData: any = []
  ScreenListAllData: any=[]

  ///CityUI
  RateForm: FormGroup
  CitypopUp: boolean = false
  ScreenpopUp: boolean = false
  SelectedCities: any = []
  SelectedScreens: any = []
  AvailableSearchText: any
  AvailableSearchTextScreen: any
  selectedSearchText: any
  AllData: any = []
  AllDataScreen: any = []
  AllCity: any = []
  AllScreen: any=[]
  SelectedCitiesToDump: any = []
  SelectedScreensToDump: any = []
  CheckboxStatus: any
  CheckboxStatusScreen: any
  CheckboxSelectedtatus: any
  SelectedCitiesToRemove: any = []
  SelectedScreensToRemove: any = []
  AllLoadDiv: boolean = true
  regionList: any = []
  chkisSelected: boolean = false
  SelectedSearchText: boolean = false
  isRegionCheck: boolean = false
  RateType: any = []
  masterSelected: boolean = false
  ScreenmasterSelected: boolean = false
  masterCitySelected: boolean = false
  masterScreenSelected: boolean = false

  //New
  MaxCapacity: any
  MinCapacity: any

  MaxATP: any
  MinATP: any

  // PinCode
  PinCodeModel: any
  PinCodeData: any = []
  InvalidPinCode: any
  FinalInValidList = []
  PinCodepopUp: boolean = false

  IsInValidPinCode: boolean = false
  IsInValidPinCodeGet: boolean = false
  IsPinCodeSubmit: boolean = true
  PlannerMasterID: number;
  PlannerId: number;
  RateScreen: any = [];
  ExportScreenData: any = [];
  ScreenCountData: any = []
  IsCollapseScreenSelection: boolean = false
  IsScreenCountScreenOpen: boolean = false
  ScreenCount: number = 0


  ///Time Slot 
  IsMorningCheck: boolean = true
  IsEveningCheck: boolean = true
  IsAfternoonCheck: boolean = true
  IsLateEveningCheck: boolean = true

  TimeSlot: any = []
  finalTimeSlotArray: any = []

  MorningVal: number = 0
  AfternoonVal: number = 0
  EveningVal: number = 0
  lateEveningVal: number = 0

  IsConfiguration: boolean = false
  IsConfigurationDiv: boolean = false
  IsPlannerCampainPresent: boolean = false;
  ViewPlannerCamapinList: any = [];
  IsCreate: boolean;


  // /Period
  PeriodFromToInput: any = []
  PeriodFromToModel: any = []
  FromPeriodDate: any
  ToPeriodDate: any

  PeriodSelection: any = 'Last'
  PeriodInputValue: number = 3
  PeriodCalenderselection: any = 'Months'
  datas: any = "adasdsad"


  TestNetworkArray: any = []
  SlotData: any = []

  CofigArray: any = []
  ConfigList: Array<ConfigScreen> = [];
  IsScreenCountScreenOpenForConf: boolean = false
  IsMessage: boolean = false;

  IsShowScreenDiv: boolean = false;
  CityCount: number;
  selectScreenCount: number;
  






  constructor(public global: Global, private http: HttpClient, public datepipe: DatePipe, private router: Router, private formBuilder: FormBuilder,private _shared:SharedService) {
    this.PlannerMasterID = null;
    this.PlannerId = null;
    this.ExportScreenData = [];
    this.ViewPlannerCamapinList = [];
    this.IsPlannerCampainPresent = false;
    this.IsCreate = false;
    this.CityCount = 0;
    this.selectScreenCount = 0;
    ;
  }

  ngOnInit(): void {
    debugger   
    //On load Bind all DDL
    this.todayDate.setDate(this.todayDate.getDate() + 1)
    this.GetPeriodDuration('Months')

    this.RateType = [{ Region: 'Rate Rack', Status: true }, { Region: 'Suggested Rate', Status: false }]

    this.RateScreen = [
      {
        Network: 'UFO',
        Discount: 5,
        PREMIUMS: 15,
        RateType: true
      },
      {
        Network: 'PVR',
        Discount: 5,
        PREMIUMS: 10,
        RateType: false
      }
    ]


    this.SlotData = [
      { "Slot": "Morning" },
      { "Slot": "Afternoon"},
      { "Slot": "Evening"},
      { "Slot": "LateEvening"}

    ]


    this.selectedCityListItems = [{ id: 0, City: "0 cities selected" }]
    this.selectedPinCodeListItems = [{ id: 0, PinCode: "0 PinCode selected" }]
    this.selectedScreenListItems = [{ id: 0, ScreenName: "0 screens selected" }]

    this.PinCodeModel = ""

    //Basic Form Validation
    this.CampaignBasicForm = this.formBuilder.group({
      ClientName: ['', [Validators.required]],
      Brands: ['', [Validators.required]],
      Duration: ['', [Validators.required]],
      CampaignObject: ['', [Validators.required]],

      CampaignStartDate: ['', [Validators.required]],
      CampaignEndDate: ['', [Validators.required]],
      PeriodInputValue: ['']


    });
    // Screen Form Validators
    this.ScreenForm = this.formBuilder.group({
      selectStateList: ['', [Validators.required]],
      selectTiersList: ['', [Validators.required]],
      selectCityList: ['', [Validators.required]],
      selectNetworkList: ['', [Validators.required]],
      Tags: [''],
      selectPinCodeList: [''],

      MinCapacity: [''],
      MaxCapacity: [''],
      MinATP: [''],
      MaxATP: [''],
      selectScreenList:['']



    });

    this.dropdownStateSettings = {
      singleSelection: false,
      idField: 'State',
      textField: 'State',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };


    this.dropdownTiersSettings = {
      singleSelection: false,
      idField: 'Tier',
      textField: 'Tier',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownCitySettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'City',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };


    this.dropdownScreenSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'ScreenName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };


    this.dropdownNetworkSettings = {
      singleSelection: false,
      idField: 'Networks',
      textField: 'Networks',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };


    this.dropdownPinCodeSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'PinCode',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownShowTimeSettings = {
      singleSelection: false,
      idField: 'Slot',
      textField: 'Slot',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.global.IsLoggedIn = sessionStorage.getItem('IsLoggedIn');
    if (this.global.IsLoggedIn == null) {
      this.global.IsLoggedIn = 'false';
    }
    this.GetPlannerCampainList();

  }
  //Get Screen Data API Call
  GetScreensDetails(StateList: any, TierList: any, CityList: any, NetworksList: any, RegionList: any, ScreenTypeList: any, ScreenList: any, IsCityOpen: any,IsScreenOpen:any) {
    this.IsShowScreenDiv = false;
    debugger
    if (StateList == null) {
      StateList = []
    }
    if (TierList == null) {
      TierList = []
    }
    if (CityList == null) {
      CityList = []
    }
    if (NetworksList == null) {
      NetworksList = []
    }
    if (RegionList == null) {
      RegionList = []
    }
    if (ScreenTypeList == null) {
      ScreenTypeList = []
    }
    if (ScreenList==null) {
      ScreenList=[]
    }

    if (this.ScreenListAllData.length == 0 || this.ScreenListAllData==null) {
      ScreenList = [];
    }

    this.isShowLoader = true;
    const headers = { 'content-type': 'application/json' }
    const ScreenData: any = {
      "PlannerId": null,
      "StateList": StateList,
      "TierList": TierList,
      "CityList": CityList,
      "NetworksList": NetworksList,
      "RegionList": RegionList,
      "ScreenTypeList": ScreenTypeList,
      "ScreenList": ScreenList
    };

    const body = JSON.stringify(ScreenData)
    var url = this.global.baseUrl + 'api/Planner/GetPlannerFilter'

    this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      debugger
      if (response != null) {


        this.StateList = [];
        this.TiersList = [];
        this.CityList = [];
        this.NetworkList = [];
        this.CityListAllData = [];
        this.regionList = [];
        this.ScreenTypeList = []

        this.selectedStateListItems = [];
        this.selectedTiersListItems = [];
        this.SelectedCities = [];
        this.selectedNetworkListItems = []
        this.CityListAllData = []

        this.SelectedScreens = []
        this.ScreenListAllData =[]        
        this.AllDataScreen = []
        this.CityCount = 0;
        this.selectScreenCount=0
        //this.selectedScreenListItems=[]


        this.StateList = response.Table;
        this.selectedStateListItems = response.Table1
        this.TiersList = response.Table2;
        this.selectedTiersListItems = response.Table3

        this.CityListAllData = response.Table4
        this.SelectedCities = response.Table5
        this.AllData = response.Table4.slice()

        this.SelectedCities = response.Table5 //City selected
        this.NetworkList = response.Table6;
        this.selectedNetworkListItems = response.Table7;

        this.CityCount = response.Table5.length;
        this.selectScreenCount = response.Table11.length;

        for (var i = 0; i < response.Table8.length; i++) {
          var boolValue = getBoolean(response.Table8[i].Status); //returns true
          response.Table8[i].Status = boolValue;

        }

        for (var i = 0; i < response.Table9.length; i++) {
          var boolValue = getBoolean(response.Table9[i].Status); //returns true
          response.Table9[i].Status = boolValue;

        }

        function getBoolean(value) {
          switch (value) {
            case true:
            case "true":

              return true;
            default:
              return false;
          }
        }
        this.regionList = response.Table8;
        // Table9
        this.ScreenTypeList = response.Table9;
        // Table11

        this.ScreenListAllData = response.Table10
        this.SelectedScreens = response.Table11
        this.AllDataScreen = response.Table11.slice()

        if (IsCityOpen == true) {
          
          this.AllLoadDiv = false
          this.CitypopUp = true
          this.ScreenpopUp = false;
          this.IsShowScreenDiv = true;
        }

        if (IsScreenOpen==true) {
          this.ScreenpopUp = true;          
          this.AllLoadDiv = false
          this.CitypopUp = false
          
        }
        this.selectedCityListItems[0].City = this.CityCount + " " + "  cities selected"
        this.selectedScreenListItems[0].ScreenName = this.selectScreenCount + " " + "  screens selected"

        this.isShowLoader = false;
      }
      else {
        this.StateList = [];
        this.TiersList = [];
        this.CityList = [];
        this.ScreenList=[]
        this.NetworkList = [];
        this.CityListAllData = [];
        this.ScreenListAllData = [];

        this.selectedStateListItems = [];
        this.selectedTiersListItems = [];
        this.SelectedCities = [];
        this.selectedNetworkListItems = []
        this.CityListAllData = []
        this.ScreenListAllData = [];
        this.selectedCityListItems = [{ id: 0, City: "0 cities selected" }]
        this.selectedPinCodeListItems = [{ id: 0, PinCode: "0 PinCode selected" }]
        this.selectedScreenListItems = [{ id: 0, ScreenName: "0 screens selected" }]
        //this.StateList = [];
        this.isShowLoader = false;
      }
    }, (error) => {
        this.isShowLoader = false;
      alert("Error Occured")
    });
  }
  // All OnClick DDL Events



  // State Event
  onItemStateSelect(item: any) {
    debugger
    this.IsShowScreenDiv = false;

    this.selectedStateListItems.push(item);
    const index = this.selectedStateListItems.findIndex(element => element.State == item.State);
    if (index > -1) {
      this.selectedStateListItems.splice(index, 1);
    }
    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList,this.SelectedScreens, false,false)

  }

  onItemStateDeSelect(item: any) {
    const index = this.selectedStateListItems.findIndex(element => element.State == item.State);
    if (index > -1) {
      this.selectedStateListItems.splice(index, 1);
    }

    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false,false)
  }

  onStateSelectAll(items: any) {
    debugger
    this.selectedStateListItems = []
    this.selectedStateListItems = items;
    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false,false)
  }

  onUnStateSelectAll() {
    debugger
    this.selectedStateListItems = []

    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false, false)
  }



  // Tier  Event
  onItemTiersSelect(item: any) {

    this.selectedTiersListItems.push(item);
    const index = this.selectedTiersListItems.findIndex(element => element.Tier == item.Tier);
    if (index > -1) {
      this.selectedTiersListItems.splice(index, 1);
    }
    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false, false)
  }

  onItemTiersDeSelect(item: any) {
    const index = this.selectedTiersListItems.findIndex(element => element.Tier == item.Tier);
    if (index > -1) {
      this.selectedTiersListItems.splice(index, 1);
    }
    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false, false)

  }

  onTiersSelectAll(items: any) {
    debugger
    this.selectedTiersListItems = []
    this.selectedTiersListItems = items;
    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false, false)
  }

  onUnTiersSelectAll() {
    debugger
    this.selectedTiersListItems = []
    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false, false)
  }

  // City  Event
  onItemCitySelect(item: any) { }

  onItemCityDeSelect(item: any) { }

  onCitySelectAll(items: any) { }

  onUnCitySelectAll() { }


  // Screen  Event
  onItemScreenSelect(item: any) { }

  onItemScreenDeSelect(item: any) { }

  onScreenSelectAll(items: any) { }

  onUnScreenSelectAll() { }


  // Tier  Event
  onItemNetworkSelect(item: any) {

    this.selectedNetworkListItems.push(item);
    const index = this.selectedNetworkListItems.findIndex(element => element.Networks == item.Networks);
    if (index > -1) {
      this.selectedNetworkListItems.splice(index, 1);
    }
    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false, false)
  }

  onItemNetworkDeSelect(item: any) {
    const index = this.selectedNetworkListItems.findIndex(element => element.Networks == item.Networks);
    if (index > -1) {
      this.selectedNetworkListItems.splice(index, 1);
    }
    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false, false)

  }

  onNetworkSelectAll(items: any) {
    debugger
    this.selectedNetworkListItems = []
    this.selectedNetworkListItems = items;
    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false, false)
  }

  onUnNetworkSelectAll() {
    debugger
    this.selectedNetworkListItems = []

    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false, false)

  }



  onItemPinCodeSelect(item: any) { }

  onItemPinCodeDeSelect(item: any) { }

  onPinCodeSelectAll(items: any) { }

  onUnPinCodeSelectAll() { }





  // Region On Change Event
  checkValue(ev: any, zone: any) {
    debugger
    if (ev.target.checked) {

      // zone.Status = zone.Status.toLocaleLowerCase() == zone.Status
      // zone.Status = !zone.Status;



      let removeIndex = this.regionList.findIndex(itm => itm.Region === zone.Region);
      this.regionList[removeIndex].Status = true;
      this.isRegionCheck = false;

    } else {
      let removeIndex = this.regionList.findIndex(itm => itm.Region === zone.Region);

      if (removeIndex !== -1)
        this.regionList[removeIndex].Status = false;
    }

    for (let i = 0; i < this.regionList.length; i++) {
      if (this.regionList[i].Status == true) {
        var flag = true;
        this.isRegionCheck = false;
        break
      }
      else { var flag = false; }
      this.isRegionCheck = true;
    }

    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false, false)



  } 
  BackPlanner() {
    this.IsScreenCountScreenOpen = false
    this.IsCreate = false;
    this.GetPlannerCampainList();
  }

  // GOBack Call
  goBack() {
    this.IsScreenCountScreenOpen = false

    this.IsScreenStep = false
    this.IsRatesStep = false

    this.IsCampaignBasicDiv = true
    this.IsScreenStepDiv = false
    this.IsRatesStepDiv = false
    this.IsScreenCountScreenOpen = false;
    this.ViewBasicDetails();


  }

  BackToList() {

    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false, false)
    this.CitypopUp = false
    this.ScreenpopUp=false
    this.AllLoadDiv = true
    this.IsShowScreenDiv=false
    this.selectedCityListItems[0].City = this.SelectedCities.length + " " + "  cities selected"
    this.selectedScreenListItems[0].ScreenName = this.SelectedScreens.length + " " + "  screens selected"
    //this.PlannerSelectedScreenCount();
  }

  BackToScreenList() {
    debugger;
    //this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false, false)
    this.CitypopUp = false
    this.ScreenpopUp = false
    this.AllLoadDiv = true
    this.IsShowScreenDiv = true
    this.PlannerSelectedScreenCount();
    this.selectedScreenListItems = [{ id: 0, ScreenName: "0 screens selected" }]
    this.selectedScreenListItems[0].ScreenName = this.SelectedScreens.length + " " + "  screens selected"
  }

  goBackRate() {
    this.IsScreenCountScreenOpen = false
    this.IsScreenStep = true
    this.IsRatesStep = false

    this.IsCampaignBasicDiv = false
    this.IsScreenStepDiv = true
    this.IsRatesStepDiv = false

    this.ViewScreenDetails();
  }


  //Pin Code
  goBackPinCode() {
    debugger



    if (this.FinalInValidList.length > 0) {
      for (var i = 0; i < this.FinalInValidList.length; i++) {
        const index = this.PinCodeData.findIndex(element => element.Pincode == this.FinalInValidList[i].InvalidPincode);
        if (index > -1) {
          this.PinCodeData.splice(index, 1);
        }
      }
    }

    this.PinCodepopUp = false
    this.AllLoadDiv = true
    this.selectedPinCodeListItems[0].PinCode = this.PinCodeData.length + " " + "  pincode selected"






  }


  // Get ATP Value
  rangeChange(event) {
    debugger
    this.Atp = null
    this.Atp = event.target.value;
  }

  DarftBasicInfo(IsPublish, Type?: number) {
    debugger;    
    this.submitted = true;
    if (this.CampaignBasicForm.invalid) {
      return;
    }
    else {
      this.isShowLoader = true;
      const headers = { 'content-type': 'application/json' }
      const BasicInfo: any = {
        "ClientName": this.ClientName,
        "Brands": this.Brands,
        "Duration": this.Duration,
        "StartDate": this.datepipe.transform(this.CampaignStartDate, 'dd/MMM/yyyy'),
        "EndDate": this.datepipe.transform(this.CampaignEndDate, 'dd/MMM/yyyy'),
        "Campanign_Details": this.CampaignObject,
        "IsPublish": IsPublish,
        "Created_Userid": sessionStorage.getItem('IsUserId'),
        "Active": 1,
        "PlannerMasterId": this.PlannerMasterID
      };

      const body = JSON.stringify(BasicInfo)
      var url = this.global.baseUrl + 'api/Planner/Insert_PlannerBasicInfo'
      ///var url = this.global.baseUrl + 'api/Dashboard/GetPostEvalDetails/?StartDate=' + '01/01/2023' + '&EndData=' + '10/01/2023' + '&Duration=' + 30
      this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
        debugger
        console.log(response);
        if (response.PlannerMasterId > 0) {
          this.PlannerMasterID = response.PlannerMasterId;
          if (Type != 1) {
            alert(response.ErrorMessage)
          }
          this.ViewScreenDetails();
        }
        else {
          alert("some error occurred..!");
        }

        // this.IsSubmitPostEval = true
        this.isShowLoader = false;
        // this.FinalPostEvalData = response.Table;


      }, (error) => {
        // this.isShowLoader = false;
        alert("Error Occured")
      });
    }
  }


  DraftScreenInfo(type?: number) {

    debugger;
    this.Screensubmitted = true;
    if (this.ScreenForm.invalid) {
      return;
    }
    else {

      if (this.ScreenListAllData==0) {
        this.SelectedScreens = [];
      }

      this.isShowLoader = true;
      const headers = { 'content-type': 'application/json' }
      const ScreenBasicInfo: any = {
        "PlannerMasterId": this.PlannerMasterID,
        "StateList": this.selectedStateListItems,
        "TierList": this.selectedTiersListItems,
        "CityList": this.SelectedCities,
        "NetworksList": this.selectedNetworkListItems,
        "PincodeList": this.PinCodeData,
        "RegionList": this.regionList,
        "ScreenTypeList": this.ScreenTypeList,
        "SeatingCapcityMax": this.MaxCapacity,
        "SeatingCapcityMin": this.MinCapacity,
        "ATPMax": this.MaxATP,
        "ATPMin": this.MinATP,
        "Tags": this.Tags,
        "ScreenList": this.SelectedScreens
      }
      const body = JSON.stringify(ScreenBasicInfo)
      var url = this.global.baseUrl + 'api/Planner/Insert_PlannerFilterInfo'
      this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
        debugger
        console.log(response);
        if (response) {
          if (type != 1) {
            alert(response)
          }
          this.ViewRateScreenDetails(1);
        }
        this.isShowLoader = false;


      }, (error) => {
        this.isShowLoader = false;
        alert("Error Occured")
      });
    }
  }

  DraftConfigInfo() {
    debugger;
    const headers = { 'content-type': 'application/json' }
    console.log(this.finalTimeSlotArray)
    var varMorningSlot
    var varEveningSlot
    var varAfternoonSlot
    var varLateEveningSlot
    if (this.finalTimeSlotArray[0].MorningSlot != undefined) {
      varMorningSlot = this.finalTimeSlotArray[0].MorningSlot
    }
    else {
      varMorningSlot = 0;
    }

    if (this.finalTimeSlotArray[1].EveningSlot != undefined) {
      varEveningSlot = this.finalTimeSlotArray[1].EveningSlot
    }
    else {
      varEveningSlot = 0;
    }

    if (this.finalTimeSlotArray[0].AfternoonSlot != undefined) {
      varAfternoonSlot = this.finalTimeSlotArray[0].AfternoonSlot
    }
    else {
      varAfternoonSlot = 0
    }
    if (this.finalTimeSlotArray[0].LateEveningSlot != undefined) {
      varLateEveningSlot = this.finalTimeSlotArray[0].LateEveningSlot
    }
    else {
      varLateEveningSlot = 0;
    }
    const ConfigInfo: any = {
      "PlannerMasterId": this.PlannerMasterID,
      "MorningSlot": varMorningSlot,
      "EveningSlot": varEveningSlot,
      "AfternoonSlot": varAfternoonSlot,
      "LateEveningSlot": varLateEveningSlot

    };

    const body = JSON.stringify(ConfigInfo)
    var url = this.global.baseUrl + 'api/Planner/Insert_PlannerConfigInfo'
    ///var url = this.global.baseUrl + 'api/Dashboard/GetPostEvalDetails/?StartDate=' + '01/01/2023' + '&EndData=' + '10/01/2023' + '&Duration=' + 30
    this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      debugger
      console.log(response);
      if (response) {
        alert(response.ErrorMessage);
      }

      this.isShowLoader = false;

    }, (error) => {
      // this.isShowLoader = false;
      alert("Error Occured")
    });
  }

  // City  Select All Check event for  
  SelectAllCityList(event) {
    debugger
    if (event.target.checked) {
      this.chkisSelected = true;
      this.SelectedCitiesToDump = []

      for (var i = 0; i < this.CityListAllData.length; i++) {
        this.CityListAllData[i].IsSelected = false;
      }

      for (var i = 0; i < this.CityListAllData.length; i++) {
        this.SelectedCitiesToDump.push(this.CityListAllData[i])
      }

      // this.masterSelected = !this.masterSelected
    }
    else {
      for (var i = 0; i < this.CityListAllData.length; i++) {
        this.CityListAllData[i].IsSelected = true;
      }

      this.chkisSelected = false;
      this.SelectedCitiesToDump = []
    }


  }


  // Screen  Select All Check event for  
  SelectAllScreenList(event) {
    debugger
    if (event.target.checked) {
      this.chkisSelected = true;
      this.SelectedScreensToDump = []

      for (var i = 0; i < this.ScreenListAllData.length; i++) {
        this.ScreenListAllData[i].IsSelected = false;
      }

      for (var i = 0; i < this.ScreenListAllData.length; i++) {
        this.SelectedScreensToDump.push(this.ScreenListAllData[i])
      }

      // this.masterSelected = !this.masterSelected
    }
    else {
      for (var i = 0; i < this.ScreenListAllData.length; i++) {
        this.ScreenListAllData[i].IsSelected = true;
      }

      this.chkisSelected = false;
      this.SelectedScreensToDump = []
    }


  }

  SelectedAllCityList(event) {
    debugger
    if (event.target.checked) {
      this.chkisSelected = true;
      this.SelectedCitiesToRemove = []

      for (var i = 0; i < this.SelectedCities.length; i++) {
        this.SelectedCities[i].IsSelected = false;
      }

      for (var i = 0; i < this.SelectedCities.length; i++) {
        this.SelectedCitiesToRemove.push(this.SelectedCities[i])
      }

      // this.masterCitySelected = !this.masterCitySelected
    }
    else {
      for (var i = 0; i < this.SelectedCities.length; i++) {
        this.SelectedCities[i].IsSelected = true;
      }

      this.chkisSelected = false;
      this.SelectedCitiesToRemove = []
    }


  }


  SelectedAllScreenList(event) {
    debugger
    if (event.target.checked) {
      this.chkisSelected = true;
      this.SelectedScreensToRemove = []

      for (var i = 0; i < this.SelectedScreens.length; i++) {
        this.SelectedScreens[i].IsSelected = false;
      }

      for (var i = 0; i < this.SelectedScreens.length; i++) {
        this.SelectedScreensToRemove.push(this.SelectedScreens[i])
      }

      // this.masterCitySelected = !this.masterCitySelected
    }
    else {
      for (var i = 0; i < this.SelectedScreens.length; i++) {
        this.SelectedScreens[i].IsSelected = true;
      }

      this.chkisSelected = false;
      this.SelectedScreensToRemove = []
    }


  }



  // City Search Event
  SearchAvailableCities(event) {
    debugger
    this.isShowLoader = true;
    if (event.target.value.length > 2) {
      this.CityListAllData = [];
      this.CityListAllData = this.AllData;
      var valAva = event.target.value;
      this.CityListAllData = this.CityListAllData.filter((val) =>
        val.CityWithState.toLowerCase().includes(valAva.toLowerCase()));
      this.isShowLoader = false
    }
    else {
      this.CityListAllData = []
      this.CityListAllData = this.AllData;
      this.isShowLoader = false
    }
  }

  // Screen Search Event
  SearchAvailableScreens(event) {
    debugger
    this.isShowLoader = true;
    if (event.target.value.length > 2) {
      this.ScreenListAllData = [];
      this.ScreenListAllData = this.AllScreen;
      var valAva = event.target.value;
      this.ScreenListAllData = this.ScreenListAllData.filter((val) =>
        val.ScreenNameWithProperty.toLowerCase().includes(valAva.toLowerCase()));
      this.isShowLoader = false
    }
    else {
      this.ScreenListAllData = []
      this.ScreenListAllData = this.AllScreen;
      this.isShowLoader = false
    }
  }


  SearchSelectedCities(event) {
    debugger
    this.isShowLoader = true;
    if (event.target.value.length > 2) {
      this.SelectedCities = [];
      this.SelectedCities = this.AllCity;



      var valSelected = event.target.value;
      this.SelectedCities = this.SelectedCities.filter((val) =>
        val.CityWithState.toLowerCase().includes(valSelected.toLowerCase()));
      this.isShowLoader = false;
    }
    else {
      this.SelectedCities = []
      this.SelectedCities = this.AllCity;
      this.isShowLoader = false;
    }
  }


  SearchSelectedScreens(event) {
    debugger
    this.isShowLoader = true;
    if (event.target.value.length > 2) {
      this.SelectedScreens = [];
      this.SelectedScreens = this.AllScreen;



      var valSelected = event.target.value;
      this.SelectedScreens = this.SelectedScreens.filter((val) =>
        val.ScreenNameWithProperty.toLowerCase().includes(valSelected.toLowerCase()));
      this.isShowLoader = false;
    }
    else {
      this.SelectedScreens = []
      this.SelectedScreens = this.AllScreen;
      this.isShowLoader = false;
    }
  }

  // To Open Ctiy popup
  getCities() {
    debugger
    if (this.selectedStateListItems.length == 0) {

      alert("Please select state")
      return

    }
    else {

      this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList,this.SelectedScreens, true,false)

      // debugger
      // this.isShowLoader = true;
      // const headers = { 'content-type': 'application/json' }
      // const ScreenData: any = {
      //   "PlannerId": null,
      //   "StateList": this.selectedStateListItems,
      //   "TierList":this.selectedTiersListItems,
      //   "CityList": this.SelectedCities,
      //   "NetworksList": this.selectedNetworkListItems,
      //   "RegionList": this.regionList,
      //   "ScreenTypeList": this.ScreenTypeList
      // };

      // const body = JSON.stringify(ScreenData)
      // var url = this.global.baseUrl + 'api/Planner/GetPlannerFilter'

      // this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      //   debugger

      //   this.SelectedCities = [];
      //   this.CityListAllData = response.Table4
      //   this.SelectedCities = response.Table5  
      //   this.AllData = response.Table4.slice()












      //   this.isShowLoader = false;



      // }, (error) => {

      //   alert("Error Occured")
      // });







    }


  }


  // To Open Ctiy popup
  getScreens() {
    debugger
    if (this.selectedStateListItems.length == 0) {

      alert("Please select state")
      return

    }
    else {

      this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false,true)
      

    }


  }

  //To Open PinCode Popup
  getPinCodes() {
    if (this.selectedStateListItems.length > 0) {
      this.PinCodepopUp = true
      this.AllLoadDiv = false
    }
    else {
      alert("Please select state")
      return
    }
  }
  getTiers() {
    debugger
    if (this.selectedStateListItems.length == 0) {

      alert("Please select state")
      return

    }
  }

  getNetworks() {
    debugger
    if (this.selectedStateListItems.length == 0) {

      alert("Please select state")
      return

    }
  }


  // CIty Avalibale Separate Click Call
  GetSelectedCitiesList(event, cities) {
    debugger
    if (event.target.checked) {

      this.CheckboxStatus = event.target.checked
      this.SelectedCitiesToDump.push(cities)
      // this.AllCity = this.SelectedCitiesToDump;
    }
    else {
      this.CheckboxStatus = false
      const index = this.SelectedCitiesToDump.findIndex(element => element.CityID == cities.CityID);
      if (index > -1) {
        this.SelectedCitiesToDump.splice(index, 1);
      }
    }

  }


  // Screen Avalibale Separate Click Call
  GetSelectedScreensList(event, screens) {
    debugger
    if (event.target.checked) {

      this.CheckboxStatusScreen = event.target.checked
      this.SelectedScreensToDump.push(screens)
      // this.AllCity = this.SelectedScreensToDump;
    }
    else {
      this.CheckboxStatusScreen = false
      const index = this.SelectedScreensToDump.findIndex(element => element.ScreenID == screens.ScreenID);
      if (index > -1) {
        this.SelectedScreensToDump.splice(index, 1);
      }
    }

  }


  AddDataTosSelectedList() {
    debugger
    this.isShowLoader = true;


    if (this.masterSelected == true) {
      setTimeout(() => {
        for (var i = 0; i < this.SelectedCitiesToDump.length; i++) {
          this.SelectedCities.push(this.SelectedCitiesToDump[i])
          this.SelectedCities[i].IsSelected = true;
          this.AllCity = this.SelectedCities.slice();
          // this.SelectedCities[i].IsSelected =  !this.SelectedCities[i].IsSelected
          // this.AllCity.push(this.SelectedCitiesToDump[i])
        }

        for (var i = 0; i < this.SelectedCitiesToDump.length; i++) {
          const index = this.SelectedCities.findIndex(element => element.CityID == this.SelectedCitiesToDump[i].CityID);
          if (index > -1) {
            this.SelectedCities[index].IsSelected = 'true'

          }
        }





        // this.AllCity = this.SelectedCities.slice();



        for (var i = 0; i < this.SelectedCities.length; i++) {
          const index = this.CityListAllData.findIndex(element => element.CityID == this.SelectedCities[i].CityID);
          if (index > -1) {
            this.CityListAllData.splice(index, 1);
          }
        }

        for (var i = 0; i < this.SelectedCities.length; i++) {
          const index = this.AllData.findIndex(element => element.CityID == this.SelectedCities[i].CityID);
          if (index > -1) {
            this.AllData.splice(index, 1);
          }
        }



        this.AvailableSearchText = "";
        this.CityListAllData = this.AllData;
        if (this.masterSelected != false) {
          this.masterSelected = !this.masterSelected
        }
        this.SelectedCitiesToDump = []
        // this.AllCity = []
        this.isShowLoader = false;

      }, 3000);

      // this.isShowLoader = false;
    }
    else {
      for (var i = 0; i < this.SelectedCitiesToDump.length; i++) {
        this.SelectedCities.push(this.SelectedCitiesToDump[i])
        this.SelectedCities[i].IsSelected = true;
        this.AllCity = this.SelectedCities.slice();

      }

      for (var i = 0; i < this.SelectedCitiesToDump.length; i++) {
        const index = this.SelectedCities.findIndex(element => element.CityID == this.SelectedCitiesToDump[i].CityID);
        if (index > -1) {
          this.SelectedCities[index].IsSelected = 'true'

        }
      }





      // this.AllCity = this.SelectedCities.slice();



      for (var i = 0; i < this.SelectedCities.length; i++) {
        const index = this.CityListAllData.findIndex(element => element.CityID == this.SelectedCities[i].CityID);
        if (index > -1) {
          this.CityListAllData.splice(index, 1);
        }
      }

      for (var i = 0; i < this.SelectedCities.length; i++) {
        const index = this.AllData.findIndex(element => element.CityID == this.SelectedCities[i].CityID);
        if (index > -1) {
          this.AllData.splice(index, 1);
        }
      }

      this.AvailableSearchText = "";
      this.CityListAllData = this.AllData;
      if (this.masterSelected != false) {
        this.masterSelected = !this.masterSelected
      }
      this.SelectedCitiesToDump = []
      // this.AllCity = []
      this.isShowLoader = false;
    }



  }



  AddDataTosSelectedScreenList() {
    debugger
    this.isShowLoader = true;


    if (this.ScreenmasterSelected == true) {
      setTimeout(() => {
        for (var i = 0; i < this.SelectedScreensToDump.length; i++) {
          this.SelectedScreens.push(this.SelectedScreensToDump[i])
          this.SelectedScreens[i].IsSelected = true;
          this.AllScreen = this.SelectedScreens.slice();
          // this.SelectedScreens[i].IsSelected =  !this.SelectedScreens[i].IsSelected
          // this.AllScreen.push(this.SelectedScreensToDump[i])
        }

        for (var i = 0; i < this.SelectedScreensToDump.length; i++) {
          const index = this.SelectedScreens.findIndex(element => element.ScreenID == this.SelectedScreensToDump[i].ScreenID);
          if (index > -1) {
            this.SelectedScreens[index].IsSelected = 'true'

          }
        }





        // this.AllScreen = this.SelectedScreens.slice();



        for (var i = 0; i < this.SelectedScreens.length; i++) {
          const index = this.ScreenListAllData.findIndex(element => element.ScreenID == this.SelectedScreens[i].ScreenID);
          if (index > -1) {
            this.ScreenListAllData.splice(index, 1);
          }
        }

        for (var i = 0; i < this.SelectedScreens.length; i++) {
          const index = this.AllDataScreen.findIndex(element => element.ScreenID == this.SelectedScreens[i].ScreenID);
          if (index > -1) {
            this.AllDataScreen.splice(index, 1);
          }
        }



        this.AvailableSearchTextScreen = "";
        //this.ScreenListAllData = this.AllDataScreen;
        if (this.ScreenmasterSelected != false) {
          this.ScreenmasterSelected = !this.ScreenmasterSelected
        }
        this.SelectedScreensToDump = []
        // this.AllScreen = []
        this.isShowLoader = false;

      }, 3000);

      // this.isShowLoader = false;
    }
    else {
      for (var i = 0; i < this.SelectedScreensToDump.length; i++) {
        this.SelectedScreens.push(this.SelectedScreensToDump[i])
        this.SelectedScreens[i].IsSelected = true;
        this.AllScreen = this.SelectedScreens.slice();

      }

      for (var i = 0; i < this.SelectedScreensToDump.length; i++) {
        const index = this.SelectedScreens.findIndex(element => element.ScreenID == this.SelectedScreensToDump[i].ScreenID);
        if (index > -1) {
          this.SelectedScreens[index].IsSelected = 'true'

        }
      }





      // this.AllScreen = this.SelectedScreens.slice();



      for (var i = 0; i < this.SelectedScreens.length; i++) {
        const index = this.ScreenListAllData.findIndex(element => element.ScreenID == this.SelectedScreens[i].ScreenID);
        if (index > -1) {
          this.ScreenListAllData.splice(index, 1);
        }
      }

      for (var i = 0; i < this.SelectedScreens.length; i++) {
        const index = this.AllDataScreen.findIndex(element => element.ScreenID == this.SelectedScreens[i].ScreenID);
        if (index > -1) {
          this.AllDataScreen.splice(index, 1);
        }
      }

      this.AvailableSearchTextScreen = "";
      //this.ScreenListAllData = this.AllDataScreen;
      if (this.ScreenmasterSelected != false) {
        this.ScreenmasterSelected = !this.ScreenmasterSelected
      }
      this.SelectedScreensToDump = []
      // this.AllScreen = []
      this.isShowLoader = false;
    }



  }



  // CIty Selected Separate Click Call
  DeleteCitiesList(event, cities) {
    debugger
    if (event.target.checked) {
      this.CheckboxSelectedtatus = event.target.checked
      this.SelectedCitiesToRemove.push(cities);




    }
    else {
      this.CheckboxSelectedtatus = false

      const index = this.SelectedCitiesToRemove.findIndex(element => element.CityID == cities.CityID);
      if (index > -1) {
        this.SelectedCitiesToRemove.splice(index, 1);
      }
    }


  }


  // Screen Selected Separate Click Call
  DeleteScreensList(event, screens) {
    debugger
    if (event.target.checked) {
      this.CheckboxSelectedtatus = event.target.checked
      this.SelectedScreensToRemove.push(screens);




    }
    else {
      this.CheckboxSelectedtatus = false

      const index = this.SelectedScreensToRemove.findIndex(element => element.ScreenID == screens.ScreenID);
      if (index > -1) {
        this.SelectedScreensToRemove.splice(index, 1);
      }
    }


  }

  RemoveDataTosSelectedList() {
    debugger

    this.isShowLoader = true;


    if (this.masterCitySelected == true) {
      setTimeout(() => {
        // this.SelectedCities = this.SelectedCitiesToRemove.slice()
        for (var i = 0; i < this.SelectedCitiesToRemove.length; i++) {
          this.CityListAllData.push(this.SelectedCitiesToRemove[i])

        }

        for (var i = 0; i < this.SelectedCitiesToRemove.length; i++) {
          const index = this.CityListAllData.findIndex(element => element.CityID == this.SelectedCitiesToRemove[i].CityID);
          if (index > -1) {
            this.CityListAllData[index].IsSelected = 'true'

          }
        }


        for (var i = 0; i < this.SelectedCitiesToRemove.length; i++) {
          const index = this.SelectedCities.findIndex(element => element.CityID == this.SelectedCitiesToRemove[i].CityID);
          if (index > -1) {
            this.SelectedCities.splice(index, 1);
          }
        }

        for (var i = 0; i < this.SelectedCitiesToRemove.length; i++) {
          const index = this.AllCity.findIndex(element => element.CityID == this.SelectedCitiesToRemove[i].CityID);
          if (index > -1) {
            this.AllCity.splice(index, 1);
          }
        }

        // const index1 = this.SelectedCitiesToRemove.findIndex(element => element.CityID == this.SelectedCities[i].CityID);
        // if (index1 > -1) {
        //   this.SelectedCitiesToRemove.splice(index, 1);
        // }

        this.selectedSearchText = "";
        this.SelectedCities = this.AllCity;
        if (this.masterCitySelected != false) {
          this.masterCitySelected = !this.masterCitySelected;
        }
        this.SelectedCitiesToRemove = []
        this.isShowLoader = false;

      }, 3000);
    }
    else {
      // this.SelectedCities = this.SelectedCitiesToRemove.slice()
      for (var i = 0; i < this.SelectedCitiesToRemove.length; i++) {
        this.CityListAllData.push(this.SelectedCitiesToRemove[i])

      }

      for (var i = 0; i < this.SelectedCitiesToRemove.length; i++) {
        const index = this.CityListAllData.findIndex(element => element.CityID == this.SelectedCitiesToRemove[i].CityID);
        if (index > -1) {
          this.CityListAllData[index].IsSelected = 'true'

        }
      }


      for (var i = 0; i < this.SelectedCitiesToRemove.length; i++) {
        const index = this.SelectedCities.findIndex(element => element.CityID == this.SelectedCitiesToRemove[i].CityID);
        if (index > -1) {
          this.SelectedCities.splice(index, 1);
        }
      }

      for (var i = 0; i < this.SelectedCitiesToRemove.length; i++) {
        const index = this.AllCity.findIndex(element => element.CityID == this.SelectedCitiesToRemove[i].CityID);
        if (index > -1) {
          this.AllCity.splice(index, 1);
        }
      }

      // const index1 = this.SelectedCitiesToRemove.findIndex(element => element.CityID == this.SelectedCities[i].CityID);
      // if (index1 > -1) {
      //   this.SelectedCitiesToRemove.splice(index, 1);
      // }

      this.selectedSearchText = "";
      this.SelectedCities = this.AllCity;
      if (this.masterCitySelected != false) {
        this.masterCitySelected = !this.masterCitySelected;
      }
      this.SelectedCitiesToRemove = []
      this.isShowLoader = false;
    }









  }


  RemoveDataTosSelectedScreenList() {
    debugger

    this.isShowLoader = true;
    
    if (this.masterScreenSelected == true) {
      setTimeout(() => {
        // this.SelectedScreens = this.SelectedScreensToRemove.slice()
        for (var i = 0; i < this.SelectedScreensToRemove.length; i++) {
          this.ScreenListAllData.push(this.SelectedScreensToRemove[i])

        }

        for (var i = 0; i < this.SelectedScreensToRemove.length; i++) {
          const index = this.ScreenListAllData.findIndex(element => element.ScreenID == this.SelectedScreensToRemove[i].ScreenID);
          if (index > -1) {
            this.ScreenListAllData[index].IsSelected = 'true'

          }
        }


        for (var i = 0; i < this.SelectedScreensToRemove.length; i++) {
          const index = this.SelectedScreens.findIndex(element => element.ScreenID == this.SelectedScreensToRemove[i].ScreenID);
          if (index > -1) {
            this.SelectedScreens.splice(index, 1);
          }
        }

        for (var i = 0; i < this.SelectedScreensToRemove.length; i++) {
          const index = this.AllScreen.findIndex(element => element.ScreenID == this.SelectedScreensToRemove[i].ScreenID);
          if (index > -1) {
            this.AllScreen.splice(index, 1);
          }
        }

        // const index1 = this.SelectedScreensToRemove.findIndex(element => element.ScreenID == this.SelectedScreens[i].ScreenID);
        // if (index1 > -1) {
        //   this.SelectedScreensToRemove.splice(index, 1);
        // }

        this.selectedSearchText = "";
        //this.SelectedScreens = this.AllScreen;
        if (this.masterScreenSelected != false) {
          this.masterScreenSelected = !this.masterScreenSelected;
        }
        this.SelectedScreensToRemove = []
        this.isShowLoader = false;

      }, 3000);
    }
    else {
      // this.SelectedScreens = this.SelectedScreensToRemove.slice()
      for (var i = 0; i < this.SelectedScreensToRemove.length; i++) {
        this.ScreenListAllData.push(this.SelectedScreensToRemove[i])

      }

      for (var i = 0; i < this.SelectedScreensToRemove.length; i++) {
        const index = this.ScreenListAllData.findIndex(element => element.ScreenID == this.SelectedScreensToRemove[i].ScreenID);
        if (index > -1) {
          this.ScreenListAllData[index].IsSelected = 'true'

        }
      }


      for (var i = 0; i < this.SelectedScreensToRemove.length; i++) {
        const index = this.SelectedScreens.findIndex(element => element.ScreenID == this.SelectedScreensToRemove[i].ScreenID);
        if (index > -1) {
          this.SelectedScreens.splice(index, 1);
        }
      }

      for (var i = 0; i < this.SelectedScreensToRemove.length; i++) {
        const index = this.AllScreen.findIndex(element => element.ScreenID == this.SelectedScreensToRemove[i].ScreenID);
        if (index > -1) {
          this.AllScreen.splice(index, 1);
        }
      }

      // const index1 = this.SelectedScreensToRemove.findIndex(element => element.ScreenID == this.SelectedScreens[i].ScreenID);
      // if (index1 > -1) {
      //   this.SelectedScreensToRemove.splice(index, 1);
      // }

      this.selectedSearchText = "";
      //this.SelectedScreens = this.AllScreen;
      if (this.masterScreenSelected != false) {
        this.masterScreenSelected = !this.masterScreenSelected;
      }
      this.SelectedScreensToRemove = []
      this.isShowLoader = false;
    }









  }


  //PinCode
  PinCodeValidation(pindata: any) {

    debugger
    this.isShowLoader = true;
    const headers = { 'content-type': 'application/json' }
    const PinCodeData: any = {
      "StateList": this.selectedStateListItems,
      // "StateList": [{ "State": "Rajasthan" }, { "State": "Maharashtra" }],
      "CityList": this.SelectedCities,
      "PincodeList": pindata,


    };

    const body = JSON.stringify(PinCodeData)
    var url = this.global.baseUrl + 'api/Planner/PlannerPincode'

    this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      debugger
      if (response != null) {
        this.InvalidPinCode = []
        this.FinalInValidList = []
        this.FinalInValidList = response.Table1;
        this.InvalidPinCode = response.Table1;

        if (this.InvalidPinCode.length > 0) {
          let InValidPincodeList = []
          for (let i = 0; i < this.InvalidPinCode.length; i++) {
            InValidPincodeList[i] = this.InvalidPinCode[i].InvalidPincode;
          }
          this.InvalidPinCode = []
          this.InvalidPinCode = InValidPincodeList.toString();


        }
        else {
          this.PinCodeData = response.Table;
          this.InvalidPinCode = "No Invalid Pin Code found";

        }
      }
      else {
        this.PinCodeData = []
        this.InvalidPinCode = this.PinCodeModel.slice();
        // this.InvalidPinCode = "No Invalid Pin Code found";
      }
      this.isShowLoader = false;

    }, (error) => {
      this.isShowLoader = false;
      // this.isShowLoader = false;
      alert("Error Occured")
    });

  }

  ///Pin Code
  SubmitPinCodes() {
    debugger


    this.IsInValidPinCode = true
    this.IsPinCodeSubmit = false
    this.IsInValidPinCodeGet = true

    var item = '';
    var result = {}
    this.PinCodeData = []
    let PinCodeModelAssigned = this.PinCodeModel.slice();

    if (PinCodeModelAssigned !== null) {
      PinCodeModelAssigned = PinCodeModelAssigned.split(',');

      for (var i = 0; i < PinCodeModelAssigned.length; i++) {
        item = PinCodeModelAssigned[i].trim();

        result[item] = item;
        this.PinCodeData.push({ "Pincode": result[item] })
      }
      this.PinCodeValidation(this.PinCodeData);


    }
  }

  GetTextAreaPinCodeValue() {
    debugger
    this.IsPinCodeSubmit = true
    this.IsInValidPinCodeGet = false
  }

  checkScreenValue(ev: any, zone: any) {
    debugger
    if (ev.target.checked) {

      let removeIndex = this.ScreenTypeList.findIndex(itm => itm.ScreenType === zone.ScreenType);
      this.ScreenTypeList[removeIndex].Status = true;
      this.isRegionCheck = false;

    } else {
      let removeIndex = this.ScreenTypeList.findIndex(itm => itm.ScreenType === zone.ScreenType);

      if (removeIndex !== -1)
        this.ScreenTypeList[removeIndex].Status = false;
    }

    for (let i = 0; i < this.ScreenTypeList.length; i++) {
      if (this.ScreenTypeList[i].Status == true) {
        var flag = true;
        this.isRegionCheck = false;
        break
      }
      else { var flag = false; }
      this.isRegionCheck = true;
    }

    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false, false)





  }
  //Planner selected screen count
  PlannerSelectedScreenCount() {
    debugger;
    this.ExportScreenData = [];
    this.isShowLoader = true;
    this.IsCollapseScreenSelection = true
    this.IsScreenCountScreenOpen = true


    const headers = { 'content-type': 'application/json' }

    if (this.ScreenListAllData.length==0) {
      this.SelectedScreens=[]
    }


    const ScreenCountData: any = {
      "PlannerId": this.PlannerId,
      "FromDate": this.datepipe.transform(this.CampaignStartDate, 'dd/MMM/yyyy'),
      "ToDate": this.datepipe.transform(this.CampaignEndDate, 'dd/MMM/yyyy'),
      "StateList": this.selectedStateListItems,
      "TierList": this.selectedTiersListItems,
      "CityList": this.SelectedCities,
      "NetworksList": this.selectedNetworkListItems,
      "RegionList": this.regionList,
      "PincodeList": this.PinCodeData,
      "ScreenTypeList": this.ScreenTypeList,
      "MinSeatingCapacity": this.MinCapacity,
      "MaxSeatingCapacity": this.MaxCapacity,
      "AdvtDuration": this.Duration,
      "CampinFromDate": this.ToPeriodDate,
      "CampinToDate": this.FromPeriodDate,
      "RateScreenList": this.selectedNetworkListItems,
      "ScreenList": this.SelectedScreens
    }


    const body = JSON.stringify(ScreenCountData)
    var url = this.global.baseUrl + 'api/Planner/PlannerSelectedScreen'
    this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      debugger
      console.log(response);
      this.ScreenCountData = []
      this.ScreenCountData = response.Table;
      //this.ExportScreenData = response.Table1
      this.selectedScreenListItems = [{ id: 0, ScreenName: "0 screens selected" }]
      this.selectedScreenListItems[0].ScreenName = this.selectScreenCount + " " + "  screens selected"

      this.isShowLoader = false;


    }, (error) => {
      this.isShowLoader = false;
      alert("Error Occured")
    });
  }
  GetScreenSelection() {
    debugger
    this.IsCollapseScreenSelection = false
    this.IsScreenCountScreenOpen = false
  }

  ClearBasicInfo() {
    this.ClientName = "";
    this.Brands = "";
    this.Duration = "";
    this.CampaignObject = "";
    this.CampaignStartDate = "";
    this.CampaignEndDate = "";
  }

  ClearScreenSelection() {
    debugger
    this.StateList = [];
    this.TiersList = [];
    this.CityList = [];
    this.NetworkList = [];
    this.CityListAllData = [];
    this.regionList = [];
    this.ScreenTypeList = []

    this.selectedStateListItems = [];
    this.selectedTiersListItems = [];
    this.SelectedCities = [];
    this.selectedNetworkListItems = []
    this.CityListAllData = []
    this.selectedCityListItems = [{ id: 0, City: "0 cities selected" }]
    this.selectedPinCodeListItems = [{ id: 0, PinCode: "0 PinCode selected" }]
    this.selectedScreenListItems = [{ id: 0, ScreenName: "0 screens selected" }]

    this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false, false)
  }

  MorningChange(slot) {
    debugger

    if (this.IsMorningCheck == true) {
      this.MorningVal = slot

      this.TimeSlot = [

        {
          "MorningSlot": this.MorningVal,
        }
      ]

      for (let i = 0; i < this.finalTimeSlotArray.length; i++) {
        const index = this.finalTimeSlotArray.findIndex(element => element.MorningSlot == 0);
        if (index > -1) {

          this.finalTimeSlotArray.splice(index, 1)
        }
      }

      for (let i = 0; i < this.finalTimeSlotArray.length; i++) {
        const index = this.finalTimeSlotArray.findIndex(element => element.MorningSlot == 1);
        if (index > -1) {

          this.finalTimeSlotArray.splice(index, 1)
        }
      }


      for (let i = 0; i < this.TimeSlot.length; i++) {
        this.finalTimeSlotArray.push(this.TimeSlot[i])
      }


    }
    else {
      this.MorningVal = 0


      for (let i = 0; i < this.finalTimeSlotArray.length; i++) {
        const index = this.finalTimeSlotArray.findIndex(element => element.MorningSlot == 1);
        if (index > -1) {

          this.finalTimeSlotArray[index].MorningSlot = 0
        }
      }

    }

    this.IsMorningCheck = !this.IsMorningCheck



  }

  EveningChange(slot) {
    debugger
    if (this.IsEveningCheck == true) {
      this.EveningVal = slot

      this.TimeSlot = [
        {
          "EveningSlot": this.EveningVal,
        }
      ]

      for (let i = 0; i < this.finalTimeSlotArray.length; i++) {
        const index = this.finalTimeSlotArray.findIndex(element => element.EveningSlot == 0);
        if (index > -1) {

          this.finalTimeSlotArray.splice(index, 1)
        }
      }

      for (let i = 0; i < this.finalTimeSlotArray.length; i++) {
        const index = this.finalTimeSlotArray.findIndex(element => element.EveningSlot == 1);
        if (index > -1) {

          this.finalTimeSlotArray.splice(index, 1)
        }
      }

      for (let i = 0; i < this.TimeSlot.length; i++) {
        this.finalTimeSlotArray.push(this.TimeSlot[i])
      }

    }
    else {
      this.EveningVal = 0

      for (let i = 0; i < this.finalTimeSlotArray.length; i++) {
        const index = this.finalTimeSlotArray.findIndex(element => element.EveningSlot == 1);
        if (index > -1) {

          this.finalTimeSlotArray[index].EveningSlot = 0
        }
      }

    }

    this.IsEveningCheck = !this.IsEveningCheck


  }

  AfternoonChange(slot) {
    debugger
    if (this.IsAfternoonCheck == true) {
      this.AfternoonVal = slot

      this.TimeSlot = [

        {
          "AfternoonSlot": this.AfternoonVal,
        }
      ]

      for (let i = 0; i < this.finalTimeSlotArray.length; i++) {
        const index = this.finalTimeSlotArray.findIndex(element => element.AfternoonSlot == 0);
        if (index > -1) {

          this.finalTimeSlotArray.splice(index, 1)
        }
      }

      for (let i = 0; i < this.finalTimeSlotArray.length; i++) {
        const index = this.finalTimeSlotArray.findIndex(element => element.AfternoonSlot == 1);
        if (index > -1) {

          this.finalTimeSlotArray.splice(index, 1)
        }
      }


      for (let i = 0; i < this.TimeSlot.length; i++) {
        this.finalTimeSlotArray.push(this.TimeSlot[i])
      }

    }
    else {
      this.AfternoonVal = 0


      for (let i = 0; i < this.finalTimeSlotArray.length; i++) {
        const index = this.finalTimeSlotArray.findIndex(element => element.AfternoonSlot == 1);
        if (index > -1) {

          this.finalTimeSlotArray[index].AfternoonSlot = 0
        }
      }

    }

    this.IsAfternoonCheck = !this.IsAfternoonCheck



  }

  LateEveningChange(slot) {
    debugger
    if (this.IsLateEveningCheck == true) {
      this.lateEveningVal = slot

      this.TimeSlot = [

        {
          "LateEveningSlot": this.lateEveningVal
        }
      ]

      for (let i = 0; i < this.finalTimeSlotArray.length; i++) {
        const index = this.finalTimeSlotArray.findIndex(element => element.LateEveningSlot == 0);
        if (index > -1) {

          this.finalTimeSlotArray.splice(index, 1)
        }
      }

      for (let i = 0; i < this.finalTimeSlotArray.length; i++) {
        const index = this.finalTimeSlotArray.findIndex(element => element.LateEveningSlot == 1);
        if (index > -1) {

          this.finalTimeSlotArray.splice(index, 1)
        }
      }


      for (let i = 0; i < this.TimeSlot.length; i++) {
        this.finalTimeSlotArray.push(this.TimeSlot[i])
      }

    }
    else {
      this.lateEveningVal = 0



      for (let i = 0; i < this.finalTimeSlotArray.length; i++) {
        const index = this.finalTimeSlotArray.findIndex(element => element.LateEveningSlot == 1);
        if (index > -1) {

          this.finalTimeSlotArray[index].LateEveningSlot = 0
        }
      }


    }

    this.IsLateEveningCheck = !this.IsLateEveningCheck

  }

  goBackfromConfiguration() {
    this.IsScreenCountScreenOpen = false;
    this.IsScreenStep = true
    this.IsRatesStep = true
    this.IsConfiguration = false


    this.IsCampaignBasicDiv = false
    this.IsScreenStepDiv = false
    this.IsRatesStepDiv = true

    this.IsConfigurationDiv = false
    this.ViewRateScreenDetails();

  }

  DraftRateInfo(type?: number) {
    debugger;
    console.log(this.selectedNetworkListItems);
    this.isShowLoader = true;
    const headers = { 'content-type': 'application/json' }
    const RateScreenInfo: any = {
      "PlannerMasterId": this.PlannerMasterID,
      "rateScreenDetails": this.selectedNetworkListItems,
    }
    const body = JSON.stringify(RateScreenInfo)
    var url = this.global.baseUrl + 'api/Planner/Insert_PlannerRateScreenInfo'
    this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      debugger
      console.log(response);
      if (response) {
        this.DraftScreenDetailsInfo();
        if (type != 1) {
          alert(response)
        }
        this.ViewConfigScreenDetails();
      }
      this.isShowLoader = false;


    }, (error) => {
      this.isShowLoader = false;
      alert("Error Occured")
    });
  }


  DraftScreenDetailsInfo(type?: number) {
    debugger;
    console.log(this.selectedNetworkListItems);
    this.isShowLoader = true;
    const headers = { 'content-type': 'application/json' }
    const RateScreenInfo: any = {
      "PlannerMasterId": this.PlannerMasterID,
      "ScreenList": this.SelectedScreens
    }
    const body = JSON.stringify(RateScreenInfo)
    var url = this.global.baseUrl + 'api/Planner/Insert_PlannerScreenDetailsInfo'
    this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      debugger
      console.log(response);
      if (response) {
        if (type != 1) {
          //alert(response)
        }
        this.ViewConfigScreenDetails();
      }
      this.isShowLoader = false;


    }, (error) => {
      this.isShowLoader = false;
      alert("Error Occured")
    });
  }

  exportData() {
    console.log(this.ExportScreenData)
    debugger
    this.ExportScreenData = [];
    this.isShowLoader = true;
    this.IsCollapseScreenSelection = true
    this.IsScreenCountScreenOpen = true


    const headers = { 'content-type': 'application/json' }



    const ScreenCountData: any = {
      "PlannerId": this.PlannerId,
      "FromDate": this.datepipe.transform(this.CampaignStartDate, 'dd/MMM/yyyy'),
      "ToDate": this.datepipe.transform(this.CampaignEndDate, 'dd/MMM/yyyy'),
      "StateList": this.selectedStateListItems,
      "TierList": this.selectedTiersListItems,
      "CityList": this.SelectedCities,
      "NetworksList": this.selectedNetworkListItems,
      "RegionList": this.regionList,
      "PincodeList": this.PinCodeData,
      "ScreenTypeList": this.ScreenTypeList,
      "MinSeatingCapacity": this.MinCapacity,
      "MaxSeatingCapacity": this.MaxCapacity,
      "AdvtDuration": this.Duration,
      "CampinFromDate": this.ToPeriodDate,
      "CampinToDate": this.FromPeriodDate,
      "ScreenList": this.SelectedScreens

    }


    const body = JSON.stringify(ScreenCountData)
    var url = this.global.baseUrl + 'api/Planner/ExportPlannerSelectedScreen'
    this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      debugger
      console.log(response);
      if (response) {
        this.ExportScreenData = response.Table
        this.export()
      }
      

      this.isShowLoader = false;


    }, (error) => {
      this.isShowLoader = false;
      alert("Error Occured")
    });
 
  }

  export() {
    let DataToExport = [];
    if (this.ExportScreenData.length > 0) {
      for (let i = 0; i < this.ExportScreenData.length; i++) {
        let d =
        {
          "Sr.No.": i + 1,
          "Screen ID": this.ExportScreenData[i].ScreenID,
          "Property ID": this.ExportScreenData[i].PropertyID,
          "Property Name": this.ExportScreenData[i].PropertyName,
          "Region": this.ExportScreenData[i].Region,
          "State": this.ExportScreenData[i].State,
          "Tier": this.ExportScreenData[i].Tier,
          "Screen Type": this.ExportScreenData[i].ScreenType,
          "City": this.ExportScreenData[i].City,
          "Pincode": this.ExportScreenData[i].Pincode,
          "Networks": this.ExportScreenData[i].Networks,
          "Seating Capacity": this.ExportScreenData[i].SeatingCapacity
        };
        DataToExport.push(d);
      }

      const workBook = XLSX.utils.book_new();
      const workSheet = XLSX.utils.json_to_sheet(DataToExport);
      XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
      var datePipe = new DatePipe('en-US');
      let currentDate = new Date();
      XLSX.writeFile(workBook, 'Screen Data-' + datePipe.transform(new Date(currentDate.toDateString()), 'dd-MMM-yyyy') + '.xlsx');
    }
    else {
      alert("No record found...!");
    }
  }

  GetPlannerCampainList() {
    debugger
    this.ClearBasicInfo();
    this.ViewPlannerCamapinList = [];
    var user_id = sessionStorage.getItem('IsUserId');
    var url = this.global.baseUrl + 'api/Planner/View_PlannerBasic_Details/?PlannerMasterId=' + 0 + '&userID=' + user_id;
    this.http.get<any>(url).subscribe(data => {
      if (data.Table.length > 0) {
        this.IsPlannerCampainPresent = true;
        this.ViewPlannerCamapinList = data.Table
      }
      else {
        this.IsPlannerCampainPresent = false;
      }
    })

  }

  GoToPlannerForm() {
    debugger;
    this.IsCreate = true;
    this.GetPlannerCampainList()
  }


  btnView(PlannerMasterId) {
    debugger;
    this.IsCreate = true;
    this.PlannerMasterID = PlannerMasterId;
    this.ViewBasicDetails();
  }

  Delete() {

    const headers = { 'content-type': 'application/json' }
    var url = this.global.baseUrl + 'api/Planner/DeletePlannerCampine/?PlannerMasterId=' + this.PlannerMasterID;
    this.http.post<any>(url, { 'headers': headers }).subscribe((response) => {
      debugger

      this.isShowLoader = false;
      //alert(response.Message)
      this.GetPlannerCampainList();
      this.IsMessage = false;
      //this.IsMessage = false;
      //this.GolblePostEvalId = "";
    }, (error) => {
      this.isShowLoader = false;
      alert("Error Occured")

    });

  }

  //********************View Code on back button*************************************
  //View Basic Info
  ViewBasicDetails() {
    debugger
    this.ClearBasicInfo();
    var user_id = 0;
    var url = this.global.baseUrl + 'api/Planner/View_PlannerBasic_Details/?PlannerMasterId=' + this.PlannerMasterID + '&userID=' + user_id;
    this.http.get<any>(url).subscribe(data => {
      if (data.Table) {
        console.log(data);
        console.log(data.Table[0].ClientName)
        this.ClientName = data.Table[0].ClientName;
        this.Brands = data.Table[0].Brands;
        this.Duration = data.Table[0].Duration;
        this.CampaignObject = data.Table[0].Campanign_Details;
        this.CampaignStartDate = new Date(data.Table[0].StartDate)
        this.CampaignEndDate = new Date(data.Table[0].EndDate)
        this.submitted = false;
      }
      else {
        alert("Some error occurred...!");
      }
    })
  }

  ViewScreenDetails() {
    debugger;
    this.IsShowScreenDiv =false
    this.StateList = [];
    this.TiersList = [];
    this.CityList = [];
    this.NetworkList = [];
    this.CityListAllData = [];
    this.regionList = [];
    this.ScreenTypeList = []

    this.selectedStateListItems = [];
    this.selectedTiersListItems = [];
    this.SelectedCities = [];
    this.selectedNetworkListItems = []
    this.CityListAllData = []

    this.SelectedScreens = []
    this.ScreenListAllData = []  
    this.AllDataScreen = []

    //this.selectedScreenListItems=[]
    //this.selectedScreenListItems[0].ScreenName=''

    this.ScreenTypeList = "";
    this.MinCapacity = "";
    this.MaxCapacity = "";
    this.MinATP = "";
    this.MaxATP = "";
    this.Tags = "";

    this.CityCount = 0;
    this.selectScreenCount = 0

    this.isShowLoader = true;

    var url = this.global.baseUrl + 'api/Planner/View_PlannerScreen_Details/?PlannerMasterId=' + this.PlannerMasterID;
    this.http.get<any>(url).subscribe(response => {
      if (response != null) {

        this.CityCount = response.Table5.length;
        this.selectScreenCount = response.Table12.length;

        console.log(response);
        this.StateList = response.Table;
        this.selectedStateListItems = response.Table1
        this.TiersList = response.Table2;
        this.selectedTiersListItems = response.Table3

        this.CityListAllData = response.Table4
        this.SelectedCities = response.Table5
        this.selectedCityListItems = [{ id: 0, City: "0 cities selected" }]
        this.selectedCityListItems[0].City = response.Table5.length + " " + "  cities selected"
        this.AllData = response.Table4.slice()

        //this.SelectedCities = response.Table5 //City selected
        this.NetworkList = response.Table6;
        this.selectedNetworkListItems = response.Table7;
        
        this.regionList = response.Table8;
        // Table9
        this.ScreenTypeList = response.Table9;
        this.MinCapacity = response.Table10[0].SeatingCapcityMin;
        this.MaxCapacity = response.Table10[0].SeatingCapacityMax;
        this.MinATP = response.Table10[0].ATPMin;
        this.MaxATP = response.Table10[0].ATPMax;
        this.Tags = response.Table10[0].Tags;

        this.ScreenListAllData = response.Table11
        this.SelectedScreens = response.Table12
        this.selectedScreenListItems[0].ScreenName = this.SelectedScreens.length + " " + "  screens selected"
        this.AllDataScreen = response.Table11.slice()

        this.selectedCityListItems[0].City = this.CityCount + " " + "  cities selected"
        this.selectedScreenListItems[0].ScreenName = this.selectScreenCount + " " + "  screens selected"
        
        

        //for (var i = 0; i < response.Table8.length; i++) {
        //  var boolValue = getBoolean(response.Table8[i].Status); //returns true
        //  response.Table8[i].Status = boolValue;

        //}

        //for (var i = 0; i < response.Table9.length; i++) {
        //  var boolValue = getBoolean(response.Table9[i].Status); //returns true
        //  response.Table9[i].Status = boolValue;

        //}

        //function getBoolean(value) {
        //  switch (value) {
        //    case true:
        //    case "true":

        //      return true;
        //    default:
        //      return false;
        //  }
        //}

        this.isShowLoader = false;

      }
      else {
        this.StateList = [];
        this.TiersList = [];
        this.CityList = [];
        this.NetworkList = [];
        this.CityListAllData = [];

        this.selectedStateListItems = [];
        this.selectedTiersListItems = [];
        this.SelectedCities = [];
        this.selectedNetworkListItems = []
        this.CityListAllData = []
        //this.selectedCityListItems = [{ id: 0, City: "0 cities selected" }]
        //this.selectedPinCodeListItems = [{ id: 0, PinCode: "0 PinCode selected" }]
        this.selectedCityListItems[0].City = this.SelectedCities.length + " " + "  cities selected"
        this.selectedScreenListItems[0].ScreenName = this.SelectedScreens.length + " " + "  screens selected"
        this.isShowLoader = false;
        alert("Some error occurred...!");
        
      }
    }) 
  }

  ViewRateScreenDetails(type?: number) {
    debugger;
    this.IsShowScreenDiv=false
    this.selectedNetworkListItems = [];
    var url = this.global.baseUrl + 'api/Planner/View_PlannerRateScreen_Details/?PlannerMasterId=' + this.PlannerMasterID;
    this.http.get<any>(url).subscribe(data => {
      if (data) {
        console.log(data.rateScreenDetails);
        this.selectedScreenListItems = [{ id: 0, ScreenName: "0 screens selected" }]
        this.selectedScreenListItems[0].ScreenName = this.SelectedScreens.length + " " + "  screens selected"
        this.selectedNetworkListItems = data.rateScreenDetails
      }
      else {
        if (type != 1) {
          alert("Some error occurred...!");
        }

      }
    })
  }

  ViewConfigScreenDetails() {
    debugger
    this.IsShowScreenDiv=false
    var url = this.global.baseUrl + 'api/Planner/View_PlannerConfig_Details/?PlannerMasterId=' + this.PlannerMasterID;
    this.http.get<any>(url).subscribe(data => {
      if (data) {
        console.log(data.ConfigList);        
        this.selectedScreenListItems[0].ScreenName = this.SelectedScreens.length + " " + "  screens selected"
        if (data.ConfigList.length > 0) {
          this.ConfigList = data.ConfigList;
        }
        else {
          this.CreateConfArray();
        }

      }
      else {
        alert("Some error occurred...!");

      }
    })
  }

  CreateConfArray() {
    debugger
    this.ConfigList = []
    for (let i = 0; i < this.selectedNetworkListItems.length; i++) {
      let conf = new ConfigScreen();
      conf.Networks = this.selectedNetworkListItems[i].Networks
      conf.ConfShows = "",
        //conf.SlotData = [
        //  { "Slot": "Morning Slot" },
        //  { "Slot": "LateEvening Slot" },
        //  { "Slot": "Evening Slot" },
        //  { "Slot": "Afternoon Slot" }

        //],
        conf.selectedItems = []

      this.ConfigList.push(conf)
    }
  }


  // Period

  GetPreviousDateData() {
    debugger
    // Previous Caln

    if (this.PeriodInputValue == 0) {
      this.PeriodInputValue = 1
    }


    if (this.PeriodCalenderselection == 'Day') {
      ///Previous Days
      let prevDay = new Date();
      prevDay.setDate(prevDay.getDate() - Number(this.PeriodInputValue));

      let fromD = new Date()
      fromD.setDate(fromD.getDate() - 1)

      this.FromPeriodDate = this.datepipe.transform(fromD, 'dd MMM yyyy');
      let FinalDt = new Date(this.datepipe.transform(prevDay, 'dd MMM yyyy'));
      this.ToPeriodDate = this.datepipe.transform(FinalDt.setDate(FinalDt.getDate()), 'dd MMM yyyy')
    }


    if (this.PeriodCalenderselection == 'Months') {
      ///Previous Month
      let prevMonth = new Date();
      prevMonth.setMonth(prevMonth.getMonth() - Number(this.PeriodInputValue));

      //let prevMonth = new Date()
      prevMonth.setDate(prevMonth.getDate() - 1)

      // this.FromPeriodDate = this.datepipe.transform(new Date(), 'dd MMM yyyy');
      // this.ToPeriodDate = this.datepipe.transform(prevMonth, 'dd MMM yyyy');

      let fromD = new Date()
      fromD.setDate(fromD.getDate() - 1)

      this.FromPeriodDate = this.datepipe.transform(fromD, 'dd MMM yyyy');
      let FinalDt = new Date(this.datepipe.transform(prevMonth, 'dd MMM yyyy'));
      this.ToPeriodDate = this.datepipe.transform(FinalDt.setDate(FinalDt.getDate() ), 'dd MMM yyyy')


    }

    if (this.PeriodCalenderselection == 'Years') {
      ///Previous Year
      let prevYear = new Date();
      prevYear.setFullYear(prevYear.getFullYear() - Number(this.PeriodInputValue));


      // this.FromPeriodDate = this.datepipe.transform(new Date(), 'dd MMM yyyy');
      // this.ToPeriodDate = this.datepipe.transform(prevYear, 'dd MMM yyyy');

      let fromD = new Date()
      fromD.setDate(fromD.getDate() - 1)

      this.FromPeriodDate = this.datepipe.transform(fromD, 'dd MMM yyyy');
      let FinalDt = new Date(this.datepipe.transform(prevYear, 'dd MMM yyyy'));
      this.ToPeriodDate = this.datepipe.transform(FinalDt.setDate(FinalDt.getDate() + 1), 'dd MMM yyyy')



    }

    if (this.PeriodCalenderselection == 'Weeks') {
      debugger
      var WeekCount = this.PeriodInputValue
      WeekCount = WeekCount * 7;

      var GetStartOfWeek = { 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6 };
      var GetEndOfWeek = { 0: 6, 1: 5, 2: 4, 3: 3, 4: 2, 5: 1, 6: 0 };

      var StartDate = new Date();
      var EndDate = new Date();
      EndDate.setDate(EndDate.getDate() - 1)

      StartDate.setDate(StartDate.getDate() - WeekCount)
      // EndDate.setDate(EndDate.getDate() - WeekCount)


      // StartDate.setDate(StartDate.getDate()  - GetStartOfWeek[StartDate.getDay()]);
      // EndDate.setDate(EndDate.getDate()  + GetEndOfWeek[EndDate.getDay()]);

      this.ToPeriodDate = this.datepipe.transform(StartDate, 'dd MMM yyyy');
      this.FromPeriodDate = this.datepipe.transform(EndDate, 'dd MMM yyyy');

    }




    ///Last Week Pending

  }



  GetNextDateData() {

    debugger

    // Next Caln
    if (this.PeriodInputValue == 0) {
      this.PeriodInputValue = 1
    }

    if (this.PeriodCalenderselection == 'Day') {
      ///Next Days
      let nextDay = new Date();
      nextDay.setDate(nextDay.getDate() + Number(this.PeriodInputValue));


      // this.FromPeriodDate = this.datepipe.transform(new Date(), 'dd MMM yyyy');
      // this.ToPeriodDate = this.datepipe.transform(nextDay, 'dd MMM yyyy');

      this.FromPeriodDate = this.datepipe.transform(new Date(), 'dd MMM yyyy');
      let FinalDt = new Date(this.datepipe.transform(nextDay, 'dd MMM yyyy'));
      this.ToPeriodDate = this.datepipe.transform(FinalDt.setDate(FinalDt.getDate() - 1), 'dd MMM yyyy')

      // this.ValidateDatePriority( this.FromPeriodDate,this.ToPeriodDate)




    }

    if (this.PeriodCalenderselection == 'Months') {
      ///Next Month
      let nextMonth = new Date();
      nextMonth.setMonth(nextMonth.getMonth() + Number(this.PeriodInputValue));

      // this.FromPeriodDate = this.datepipe.transform(new Date(), 'dd MMM yyyy');
      // this.ToPeriodDate = this.datepipe.transform(nextMonth, 'dd MMM yyyy');

      this.FromPeriodDate = this.datepipe.transform(new Date(), 'dd MMM yyyy');
      let FinalDt = new Date(this.datepipe.transform(nextMonth, 'dd MMM yyyy'));
      this.ToPeriodDate = this.datepipe.transform(FinalDt.setDate(FinalDt.getDate() - 1), 'dd MMM yyyy')

      // this.ValidateDatePriority( this.FromPeriodDate,this.ToPeriodDate)

    }

    if (this.PeriodCalenderselection == 'Years') {
      ///Next Year
      let nextYear = new Date();
      nextYear.setFullYear(nextYear.getFullYear() + Number(this.PeriodInputValue));


      // this.FromPeriodDate = this.datepipe.transform(new Date(), 'dd MMM yyyy');
      // this.ToPeriodDate = this.datepipe.transform(nextYear, 'dd MMM yyyy');

      this.FromPeriodDate = this.datepipe.transform(new Date(), 'dd MMM yyyy');
      let FinalDt = new Date(this.datepipe.transform(nextYear, 'dd MMM yyyy'));
      this.ToPeriodDate = this.datepipe.transform(FinalDt.setDate(FinalDt.getDate() - 1), 'dd MMM yyyy')

      // this.ValidateDatePriority( this.FromPeriodDate,this.ToPeriodDate)

    }

    if (this.PeriodCalenderselection == 'Weeks') {
      debugger
      var WeekCount = this.PeriodInputValue
      WeekCount = WeekCount * 6;

      var GetStartOfWeek = { 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6 };
      var GetEndOfWeek = { 0: 6, 1: 5, 2: 4, 3: 3, 4: 2, 5: 1, 6: 0 };

      var StartDate = new Date();
      var EndDate = new Date();
      // StartDate.setDate(StartDate.getDate() + WeekCount)
      EndDate.setDate(EndDate.getDate() + WeekCount)

      // StartDate.setDate(StartDate.getDate()  - GetStartOfWeek[StartDate.getDay()]);
      // EndDate.setDate(EndDate.getDate()   + GetEndOfWeek[EndDate.getDay()]);

      this.FromPeriodDate = this.datepipe.transform(StartDate, 'dd MMM yyyy');
      this.ToPeriodDate = this.datepipe.transform(EndDate, 'dd MMM yyyy');


      // this.ValidateDatePriority( this.FromPeriodDate,this.ToPeriodDate)
    }

    ///Last Week Pending

  }

  GetThisDateData() {
    debugger
    this.PeriodInputValue = 0
    if (this.PeriodCalenderselection == 'Day') {

      this.FromPeriodDate = this.datepipe.transform(new Date(), 'dd MMM yyyy');

      this.ToPeriodDate = this.datepipe.transform(new Date(), 'dd MMM yyyy');

    }

    if (this.PeriodCalenderselection == 'Months') {
      // First Last Day
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

      ///Last Day
      var currentDate = new Date();
      var lastDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59, 999);

      this.FromPeriodDate = this.datepipe.transform(firstDay, 'dd MMM yyyy');

      this.ToPeriodDate = this.datepipe.transform(lastDate, 'dd MMM yyyy');
    }

    if (this.PeriodCalenderselection == 'Years') {
      debugger
      var date = new Date();
      var firstDay = new Date(new Date().getFullYear(), 0, 1);

      ///Last Day
      var currentDate = new Date();
      var lastDate = new Date(new Date().getFullYear(), 0, 365)

      this.FromPeriodDate = this.datepipe.transform(firstDay, 'dd MMM yyyy');

      this.ToPeriodDate = this.datepipe.transform(lastDate, 'dd MMM yyyy');
    }

    if (this.PeriodCalenderselection == 'Weeks') {
      debugger

      var GetStartOfWeek = { 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6 };
      var GetEndOfWeek = { 0: 6, 1: 5, 2: 4, 3: 3, 4: 2, 5: 1, 6: 0 };

      var StartDate = new Date();
      var EndDate = new Date();


      StartDate.setDate(StartDate.getDate() - GetStartOfWeek[StartDate.getDay()]);
      EndDate.setDate(EndDate.getDate() + GetEndOfWeek[EndDate.getDay()]);

      this.FromPeriodDate = this.datepipe.transform(StartDate, 'dd MMM yyyy');
      this.ToPeriodDate = this.datepipe.transform(EndDate, 'dd MMM yyyy');
    }

  }



  GetPeriodInputVal(event) {
    debugger
    if (event.target.value == "0") {
      this.PeriodInputValue = 1
      event.target.value =1
    }
    else {
      this.PeriodInputValue = event.target.value
    }

    this.GetPeriodDuration(this.PeriodSelection)
    // this.GetPeriodDuration(this.PeriodCalenderselection)

  }


  GetPeriodDuration(data) {
    debugger

    if (data == 'Last') {
      this.PeriodSelection = 'Last'
      this.GetPreviousDateData();


    }
    if (data == 'Next') {
      this.PeriodSelection = 'Next'
      this.GetNextDateData();

    }


    if (data == 'This') {
      this.PeriodSelection = 'This'
      this.GetThisDateData();

    }


    if (data == 'Day') {
      this.PeriodCalenderselection = 'Day'
      if (this.PeriodSelection == 'Last') {
        this.GetPreviousDateData();
      }
      if (this.PeriodSelection == 'Next') {
        this.GetNextDateData();
      }

      if (this.PeriodSelection == 'This') {
        this.GetThisDateData();
      }




    }
    if (data == 'Weeks') {
      this.PeriodCalenderselection = 'Weeks'
      if (this.PeriodSelection == 'Last') {
        this.GetPreviousDateData();
      }
      if (this.PeriodSelection == 'Next') {
        this.GetNextDateData();
      }
      if (this.PeriodSelection == 'This') {
        this.GetThisDateData();
      }


    }
    if (data == 'Months') {
      this.PeriodCalenderselection = 'Months'
      if (this.PeriodSelection == 'Last') {
        this.GetPreviousDateData();
      }
      if (this.PeriodSelection == 'Next') {
        this.GetNextDateData();
      }
      if (this.PeriodSelection == 'This') {
        this.GetThisDateData();
      }

    }

    if (data == 'Years') {
      this.PeriodCalenderselection = 'Years'
      if (this.PeriodSelection == 'Last') {
        this.GetPreviousDateData();
      }
      if (this.PeriodSelection == 'Next') {
        this.GetNextDateData();
      }
      if (this.PeriodSelection == 'This') {
        this.GetThisDateData();
      }

    }
  }

  IncrementValue(event) {
    debugger
    var Val = event.Discount;
    if (event.Discount >=0) {
      if (event.Discount >= 0 && event.Discount >= 100) {
        event.Discount = 100
      }
      else {
        event.Discount = event.Discount + 5;
      }
    }
    
    
  //this.DiscountData = val + 5
  }

  DecrementValue(event) {
    debugger
    //this.DiscountData = val - 5

    var Val = event.Discount;
    if (event.Discount >0) {
      if (event.Discount > 0 && event.Discount >= 100) {
        event.Discount = 100
      }
      else {
        event.Discount = event.Discount - 5;
      }

    }
   

    
  }


  handleChange(event,item) {
    debugger

    var Val = event.target.value;
    if (event.target.value != "") {
      if (event.target.value > 0 && event.target.value > 100) {
        event.target.value = 100
        item.Discount = 100
      }

    }
    // if (input.value < 0) input.value = 0;
    // if (input.value > 100) input.value = 100;
  }


  IncrementPrimiumValue(event) {
    debugger
    var Val = event.Premiums;
    if (event.Premiums >= 0) {
      if (event.Premiums >= 0 && event.Premiums >= 100) {
        event.Premiums = 100
      }
      else {
        event.Premiums = event.Premiums + 5;
      }
    }


    //this.DiscountData = val + 5
  }

  DecrementPrimiumValue(event) {
    debugger
    //this.DiscountData = val - 5

    var Val = event.Premiums;
    if (event.Premiums > 0) {
      if (event.Premiums > 0 && event.Premiums >= 100) {
        event.Premiums = 100
      }
      else {
        event.Premiums = event.Premiums - 5;
      }

    }



  }


  handlePrimiumChange(event, item) {
    debugger

    var Val = event.target.value;
    if (event.target.value != "") {
      if (event.target.value > 0 && event.target.value > 100) {
        event.target.value = 100
        item.Premiums = 100
      }

    }
    // if (input.value < 0) input.value = 0;
    // if (input.value > 100) input.value = 100;
  }

  btnDelete(PlannerMasterId) {
    this.PlannerMasterID = 0;
    this.PlannerMasterID = PlannerMasterId;
    this.IsMessage = true;
  }

  DeleteNo() {
    debugger;
    this.IsMessage = false;
    this.PlannerMasterID = 0;
  }

  // Submit Form Event 
  onCampaignBasicSubmit() {
    debugger
    this.IsScreenCountScreenOpen = false
    this.submitted = true;
    if (this.CampaignBasicForm.invalid) {
      return;
    }
    else {
      //this.GetScreensDetails(this.selectedStateListItems, this.selectedTiersListItems, this.SelectedCities, this.selectedNetworkListItems, this.regionList, this.ScreenTypeList, this.SelectedScreens, false, false)

      // this.IsCampaignBasicStep = !this.IsCampaignBasicStep
      this.IsScreenStep = true
      this.IsRatesStep = false

      this.IsCampaignBasicDiv = false
      this.IsScreenStepDiv = true
      this.IsRatesStepDiv = false
      this.DarftBasicInfo(0, 1);



    }

  }


  onScreenSubmit() {
    debugger
    this.IsScreenCountScreenOpen = false

    this.Screensubmitted = true;
    if (this.ScreenForm.invalid) {
      return;
    }
    else {


      this.IsScreenStep = true
      this.IsRatesStep = true

      this.IsCampaignBasicDiv = false
      this.IsScreenStepDiv = false
      this.IsRatesStepDiv = true
      this.DraftScreenInfo(1);


      //localStorage.setItem("ScreenFiltersDetails", JSON.stringify(ScreenSubmitData))
      //window.location.href = "./ScreenReport"




    }
  }

  OnSubmitRateScreen() {
    debugger
    debugger
    this.IsScreenCountScreenOpen= false
    this.IsScreenStep = true
    this.IsRatesStep = true
    this.IsConfiguration = true


    this.IsCampaignBasicDiv = false
    this.IsScreenStepDiv = false
    this.IsRatesStepDiv = false
    this.IsRatesStepDiv = false
    this.IsConfigurationDiv = true
    this.IsScreenCountScreenOpenForConf = false
    this.DraftRateInfo(1);
  }

  OnPublishConfig(type?: number) {
    debugger
    this.IsConfigurationDiv = false
    //this.IsScreenCountScreenOpen = true;
    this.IsScreenCountScreenOpenForConf = true
    this.AllLoadDiv = false;
    let ScreenSubmitData = []
    let StateArray = []
    let CityArray = []
    let TiersArray = []
    let NetworkArray = []
    let AtpData = []
   
    //this.PlannerSelectedScreenCount();
   
    let DummyArray = [];
 
    let FinalDummyArray: Array<ConfigScreen> = [];


    for (var i = 0; i < this.ConfigList.length; i++) {
      for (var j = 0; j < this.ConfigList[i].selectedItems.length; j++) {
        let cof = new ConfigScreen()
        cof.ConfShows = this.ConfigList[i].ConfShows,
          cof.Networks = this.ConfigList[i].Networks,
          cof.selectedItems = this.ConfigList[i].selectedItems[j].Slot

        FinalDummyArray.push(cof)

      }
    }

    debugger;
    this.isShowLoader = true;
    const headers = { 'content-type': 'application/json' }
    const ConfigBasicInfo: any = {
      "PlannerMasterId": this.PlannerMasterID,
      "_SaveConfigList": FinalDummyArray,
    }
    const body = JSON.stringify(ConfigBasicInfo)
    var url = this.global.baseUrl + 'api/Planner/Insert_PlannerConfigInfo'
    this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      debugger
      console.log(response);
      if (response) {
        this.DraftScreenDetailsInfo();
        if (type==1) {
          this.PlannerSelectedScreenCount();
        }
        
        //this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
      }
      


    }, (error) => {
      this.isShowLoader = false;
      alert("Error Occured")
    });



    for (let i = 0; i < this.selectedStateListItems.length; i++) {
      StateArray[i] = this.selectedStateListItems[i].State;
    }



    // for (let i = 0; i < this.selectedCityListItems.length; i++) {
    //   CityArray[i] = this.selectedCityListItems[i].CityID;
    // }




    for (let i = 0; i < this.selectedTiersListItems.length; i++) {
      TiersArray[i] = this.selectedTiersListItems[i].Tier;
    }


    for (let i = 0; i < this.selectedNetworkListItems.length; i++) {
      NetworkArray[i] = this.selectedNetworkListItems[i].Networks;
    }

    AtpData[0] = Number(this.Atp);


    for (let i = 0; i < this.SelectedCities.length; i++) {
      CityArray[i] = this.SelectedCities[i].CityWithState.substring(0, this.SelectedCities[i].CityWithState.indexOf("(", 5)).trim()

      //this.SelectedCities[i].CityWithState;
    }

    ScreenSubmitData.push(StateArray);

    ScreenSubmitData.push(TiersArray);
    ScreenSubmitData.push(NetworkArray);
    ScreenSubmitData.push(AtpData);
    ScreenSubmitData.push(CityArray);

    //localStorage.setItem("ScreenFiltersDetails", JSON.stringify(ScreenSubmitData))
    //window.location.href = "./ScreenReport"
  }

  OnSavePublishConfig() {
    debugger
    //this.IsConfigurationDiv = false
    //this.IsScreenCountScreenOpen = true;
    //this.IsScreenCountScreenOpenForConf = true
    ///this.AllLoadDiv = false;
    let ScreenSubmitData = []
    let StateArray = []
    let CityArray = []
    let TiersArray = []
    let NetworkArray = []
    let AtpData = []

    //this.PlannerSelectedScreenCount();

    let DummyArray = [];

    let FinalDummyArray: Array<ConfigScreen> = [];


    for (var i = 0; i < this.ConfigList.length; i++) {
      for (var j = 0; j < this.ConfigList[i].selectedItems.length; j++) {
        let cof = new ConfigScreen()
        cof.ConfShows = this.ConfigList[i].ConfShows,
          cof.Networks = this.ConfigList[i].Networks,
          cof.selectedItems = this.ConfigList[i].selectedItems[j].Slot

        FinalDummyArray.push(cof)

      }
    }

    debugger;
    this.isShowLoader = true;
    const headers = { 'content-type': 'application/json' }
    const ConfigBasicInfo: any = {
      "PlannerMasterId": this.PlannerMasterID,
      "_SaveConfigList": FinalDummyArray,
    }
    const body = JSON.stringify(ConfigBasicInfo)
    var url = this.global.baseUrl + 'api/Planner/Insert_PlannerConfigInfo'
    this.http.post<any>(url, body, { 'headers': headers }).subscribe((response) => {
      debugger
      console.log(response);
      if (response) {
        this.DraftScreenDetailsInfo();
        alert(response)

        this.isShowLoader = false;
      }
      else {
        this.isShowLoader = false;
      }



    }, (error) => {
      this.isShowLoader = false;
      alert("Error Occured")
    });



    for (let i = 0; i < this.selectedStateListItems.length; i++) {
      StateArray[i] = this.selectedStateListItems[i].State;
    }



    // for (let i = 0; i < this.selectedCityListItems.length; i++) {
    //   CityArray[i] = this.selectedCityListItems[i].CityID;
    // }




    for (let i = 0; i < this.selectedTiersListItems.length; i++) {
      TiersArray[i] = this.selectedTiersListItems[i].Tier;
    }


    for (let i = 0; i < this.selectedNetworkListItems.length; i++) {
      NetworkArray[i] = this.selectedNetworkListItems[i].Networks;
    }

    AtpData[0] = Number(this.Atp);


    for (let i = 0; i < this.SelectedCities.length; i++) {
      CityArray[i] = this.SelectedCities[i].CityWithState.substring(0, this.SelectedCities[i].CityWithState.indexOf("(", 5)).trim()

      //this.SelectedCities[i].CityWithState;
    }

    ScreenSubmitData.push(StateArray);

    ScreenSubmitData.push(TiersArray);
    ScreenSubmitData.push(NetworkArray);
    ScreenSubmitData.push(AtpData);
    ScreenSubmitData.push(CityArray);

    //localStorage.setItem("ScreenFiltersDetails", JSON.stringify(ScreenSubmitData))
    //window.location.href = "./ScreenReport"
  }

  BackConfigToList() {
    debugger;

    this.IsCampaignBasicStep = true
    this.IsScreenStep = false
    this.IsRatesStep= false
    this.IsCampaignBasicDiv = true
    this.IsScreenStepDiv = false
    this.IsRatesStepDiv = false



    this.IsConfiguration = false
    this.IsConfigurationDiv = false
    this.IsPlannerCampainPresent = false;

    this.IsConfigurationDiv = false   
    this.IsScreenCountScreenOpenForConf = false
    this.IsScreenCountScreenOpen = false;
    this.AllLoadDiv = true;
    this.IsCreate = false;
    this.GetPlannerCampainList();
  }

  ShowScreenDiv() {
    debugger;

    this.PlannerSelectedScreenCount();
    this.IsShowScreenDiv = true;
  }



}
