import { Component, OnInit } from '@angular/core';
import {Global} from '../global/global'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(public global:Global) { }

  ngOnInit(): void {
    this.global.IsLoggedIn=sessionStorage.getItem('IsLoggedIn');
    console.log(this.global.IsLoggedIn)
    if(this.global.IsLoggedIn == null)
    {
      this.global.IsLoggedIn = 'false';
    }
    this.global.IsOnMenu= false
    this.global.IsOnApproachMenu= false
    this.global.IsOnPlannerMenu= false
    this.global.IsOnPostEvalMenu= false
    this.global.IsOnContactUsMenu= true
    this.global.IsOnStartedMenu= false
    this.global.IsCinemaStats = false
    
  }



}
