import { Component, OnInit } from '@angular/core';
import {Global} from '../global/global'

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.css']
})
export class PlannerComponent implements OnInit {

  constructor(public global:Global) { }

  ngOnInit(): void {
    debugger
    this.global.IsLoggedIn=sessionStorage.getItem('IsLoggedIn');
    console.log(this.global.IsLoggedIn)    
    if(this.global.IsLoggedIn == null){
      this.global.IsLoggedIn = 'false';
    }
    this.global.IsOnMenu= false
    this.global.IsOnApproachMenu= false
    this.global.IsOnPlannerMenu= true
    this.global.IsOnPostEvalMenu= false
    this.global.IsOnContactUsMenu= false
    this.global.IsOnStartedMenu= false
    this.global.IsCinemaStats = false

    if (this.global.IsLoggedIn =='true') {
      window.location.href = "./CBPlanner"
    }
  }

  GoToLoginFromPlanner()
  {
    debugger
    localStorage.setItem('IsPlannerLogin', "true");
    localStorage.setItem('IsPostEvalLogin', "false");

    window.location.href = "./Login"
  }

 
  // GotoPlanner()
  // {
  //   window.location.href = "./Planner"
  // }
  
  GoToPostEval()
  {    
    window.location.href = "./PostEval"
  }

  // UserMgt
  LogoutApp()
  {
    this.global.IsLoggedIn = 'false';
    sessionStorage.setItem('IsLoggedIn', String(this.global.IsLoggedIn));
    window.location.href = "./Login"


  }

}
