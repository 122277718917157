<style>

  p, li {
    text-align: justify;
  }
</style>
<div class="container" style="padding-top:50px">


  <div class="row" style="    margin-top: 35px;">
    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="    padding-top: 10px;">
      <img src="../../../../Scripts/assets/img/content/ad_palnner.png" />
      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 caption" style="    text-align: center;">

        <p style="text-align:center">Campaign Planner</p>
      </div>
      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 captionnew" style="text-align: center;margin-bottom: 20px;;">


        <div class="tp-caption butn primary rs-parallaxlevel-3 position-relative z-index-99 xs-wide"
             (click)="GoToLoginFromPlanner()" style="background-color: #532ABA;border-radius: 7px"><span>LOGIN</span></div>

      </div>

    </div>
  </div>
  <div class="row" style="    margin-top: 21px;">
    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

      <p>The AD Planner provides advertisers with the tools they need to make data-driven marketing decisions. Through CAMBioscope's audience measurement and analytics, advertisers can gain valuable insight. In addition to identifying target audiences, planning cinema campaigns, and assessing their effectiveness, this information can be used to measure the success of advertising campaigns.</p>
      <p></p>
      <b>Features of ADPlanner:</b>
      <p></p>
      <p><b>Target Audience:</b>Segment audiences by demographics, interests, and behaviors to target specific audiences.</p>
      <p></p>
      <p><b>Campaign Planning:</b>Planning and optimizing Cinema Campaigns using audience data can maximize the effectiveness of your campaigns.</p>
      <p></p>
      <p><b>Performance tracking: </b>Evaluate the results of your ads in near real-time and make tweaks based on data.</p>
      <p></p>
      <p><b>Analytics: </b>Create detailed reports and analytics on your ad campaigns so you can better understand your audience and improve your marketing efforts.</p>

      <!--
        Some of the features of ADPlanner include:

        <ul>

          <li> Audience segmentation: Easily identify and target specific audience segments based on demographics,
            interests, and behavior.</li>

          <li>Campaign planning: Use audience data to plan and optimize ad campaigns for maximum impact.</li>

          <li> Performance tracking: Track the performance of your ad campaigns in real-time and make data-driven
            adjustments to improve results.</li>

          <li>Reporting and Analytics: Generate detailed reports and analytics on your ad campaigns to help you better
            understand your audience and improve your marketing strategies.</li>

          <li>The ADPlanner is the perfect tool for advertisers looking to improve the effectiveness</li>
        </ul> -->
    </div>





  </div>

  <!-- <div class="row" style="padding-top:20px;    padding-bottom: 20px;">
    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="text-align: center;">
      <div class="tp-caption butn primary rs-parallaxlevel-3 position-relative z-index-99 xs-wide"
        (click)="GoToLoginFromPlanner()"><span>LOGIN</span></div>
    </div>
  </div> -->
</div>
