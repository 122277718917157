import { NgModule,Pipe,PipeTransform } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './Header/header.component';
import { FooterComponent } from './Footer/footer.component';
import { HomeComponent } from './Home/home.component';
import { LoginComponent } from './Login/login.component';
import { RegistrationComponent } from './Registration/registration.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PlannerComponent } from './Planner/planner.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {Global} from './global/global'
import {LoginPagesService} from './Login/LoginPagesService'
import {RegistrationPageService} from './Registration/RegistrationPageService';
import {PostEvalService} from './PostEva/postevalService';
import {PostevalComponent} from './PostEva/posteval.component';
import{EmbededReportComponent} from './embeded-report/embeded-report.component'
import { Component, OnInit, } from '@angular/core';
import {SearchPipePipe } from '../app/Pipe/PipesTransfrom'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {CommonModule  } from '@angular/common';
import { TeamComponent } from './Team/team.component';
import { CasestudiesComponent } from './CaseStudies/casestudies.component';
import { ContactComponent } from './Contact/contact.component';
import { PrivacypolicyComponent } from './PrivacyPolicy/privacypolicy.component';
import { CinemastatsComponent } from './CinemaStats/cinemastats.component';
import { AboutusComponent } from './About/aboutus.component'

import { DatePipe } from '@angular/common'
import { DisclaimerComponent } from './Disclaimer/disclaimer.component';
import { Plannerreport2Component } from './plannerreport2/plannerreport2.component'
import { PlannerdraftComponent} from './PlannerDraft/plannerdraft.component';
import { ScreenReportComponent } from './screen-report/screen-report.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfigScreen } from './Model/ConfigScreen';
import { PlannerDAVPComponent } from './planner-davp/planner-davp.component'
//import { NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { AppConfig } from '../app/app-config';
import { APP_INITIALIZER } from '@angular/core';
import { SharedService} from '../app/shared/shared.service'

const initializeApp = (appConfig: AppConfig) => {

  return () => {
    appConfig.load()
  };
};


 

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    RegistrationComponent,
    PlannerComponent,
    PostevalComponent,
    EmbededReportComponent,
    SearchPipePipe,
    TeamComponent,
    CasestudiesComponent,
    ContactComponent,
    PrivacypolicyComponent,
    CinemastatsComponent,
    AboutusComponent,
    DisclaimerComponent,
    Plannerreport2Component,
    PlannerdraftComponent,
    ScreenReportComponent,
    PlannerDAVPComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
    , ReactiveFormsModule,
     NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot()
    , CommonModule, NgbModule/*, NgbModule*/

  ],
  providers: [{ provide: LoginPagesService }, { provide: RegistrationPageService }, Global, DatePipe, ConfigScreen,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    },    
    SharedService],
  bootstrap: [AppComponent]
})
export class AppModule { }
